// 处理图搜公共的逻辑
// 保存本地图片
export const saveLocalSearchImg = async base64 => {
  sessionStorage.setItem('search-base64', base64)
};
// 获取本地保存图片
export const getLocalSearchImg = () => {
  const base64 = sessionStorage.getItem('search-base64')
  return base64
};

// base64转文件
export const dataURLtoBlob = dataurl => {
  // console.log('dataurl',dataurl);
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = atob(arr[1]);
  let n = bytes.length;
  // 处理异常,将ascii码小于0的转换为大于0
  let ab = new ArrayBuffer(n);
  let u8arr = new Uint8Array(ab);
  while (n--) {
    u8arr[n] = bytes.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};


// 文件获取地址
export const getBlobToUrl = (blob) => {
  return window.URL.createObjectURL(blob)
}
// 获取图片的base64
export const getImgBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      resolve(e.target.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
    fileReader.readAsDataURL(file)
  })
}

export const objToFormdata = (obj) => {
  const formData = new FormData()
  for (const key in obj) {
    formData.append(key, obj[key])
  }
  return formData
}

export function base64ToBlob (base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}