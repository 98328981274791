export default {
  lang: 'Langue',
  currency: 'Devise',
  validGoodUrlFail: "Ce n'est pas un lien de produit valide",
  product: 'Produit',
  productHolder: 'Entrez le nom/lien du produit',
  shop: 'Boutique',
  shopHolder: 'Entrez le nom/lien de la boutique',
  purchaseAgent: "Agent d'achat",
  transport: 'Transport',
  manualOrdering: 'Commande manuelle',
  guideBook: 'Guide',
  help: 'Aide',
  tools: 'Outils',
  customerService: 'Service client',
  purchaseGuide: "Guide d'achat",
  payment: 'Paiement',
  delivery: 'Livraison',
  afterSaleService: 'Service après-vente',
  insuranceCompensation: 'Assurance et compensation',
  contactInfo: 'Coordonnées',
  enterEmail: 'Saisissez votre e-mail',
  copyRight: 'Copyright©acbuy.cn',
  aboutacbuy: "À propos d'acbuy",
  contctTime: '9/7 (09:00-18:00, heure de Pékin)',
  contactEmail: "Service client: Support{'@'}acbuy.com",
  contactMarket: "Marketing: Marketing{'@'}acbuy.com",
  contactCooperation: "Coopération commerciale: Business{'@'}acbuy.com",
  tutorial: 'Tutoriel',
  serviceFee: 'Frais de service',
  orderStatus: 'Guide du novice',
  returnRefunds: 'Retours et remboursements',
  topUp: 'Recharger',
  paypal: 'PayPal',
  internationalCreditCard: 'Carte de crédit internationale',
  telegraphicTransfer: 'Virement bancaire',
  charges: 'Frais',
  mailRestrictions: "Restrictions d'envoi",
  customsTaxation: 'Douanes et taxes',
  receiptInformation: 'Informations de réception',
  helpCenter: "Centre d'aide",
  contactUs: 'Contactez-nous',
  registerSuccess: 'Inscription réussie',
  registerMsg:
    "Nous avons envoyé un e-mail de vérification à votre adresse. Veuillez compléter la vérification rapidement pour ne pas affecter l'utilisation de vos points.",
  continueShopping: 'Continuer les achats',
  continueLogin: 'Continuer la connexion',
  verifyQuestion: "Vous n'avez pas reçu l'e-mail de vérification?",
  verifyResopnseOne:
    "· L'e-mail de vérification acbuy peut être considéré comme spam, veuillez vérifier votre dossier spam",
  verifyResopnseTwo:
    "· L'envoi peut être retardé de quelques minutes, veuillez patienter",
  verifyResopnseThird:
    "· L'e-mail de vérification peut être retardé. Si vous ne le recevez pas après un long moment, vérifiez s'il a été marqué comme spam ou renvoyez l'e-mail de vérification",
  agentGuide: 'Guide pour les nouveaux acheteurs',
  guideBookbtn: 'Manuel du débutant',
  everyonePro:
    'Tout le monde peut devenir promoteur, les commissions sur les colis doubles ont été améliorées',
  joinAffiliate: "Rejoindre l'affiliation",
  joinacbuy: "Rejoignez acbuy Discord pour plus d'avantages",
  joinDiscord: 'Rejoindre Discord',
  productPpurchase: 'Achat de produits',
  shippingToWarehouse: "Expédition à l'entrepôt",
  qualityAssurance: 'Assurance qualité',
  moreCostEffective: 'Plus rentable',
  productPpurchaseContent:
    "Achat de produits - Vous pouvez facilement acheter n'importe quel produit de Chine. Il suffit de coller le lien du produit sur le site acbuy et de passer commande, acbuy achètera pour vous",
  shippingToWarehouseContent:
    "Expédition à l'entrepôt – Faites vos achats auprès de différents vendeurs via notre service, nous consoliderons efficacement vos commandes. Tous les produits seront expédiés à l'entrepôt acbuy, nous simplifierons le processus pour votre commodité.",
  qualityAssuranceContent:
    "Assurance qualité - Lorsque vos produits arrivent à l'entrepôt acbuy, nous effectuons une inspection complète de la qualité et vous envoyons les retours d'inspection pour une meilleure expérience.",
  moreCostEffectiveContent:
    "Économisez sur le transport groupé - Dans votre entrepôt, vous pouvez librement sélectionner des produits et les expédier en un seul colis. Utilisez le service d'expédition mondiale acbuy pour envoyer vos produits n'importe où, profitez d'une expédition mondiale plus simple et fiable.",
  introduction: 'Shopping comme un millionnaire',
  reviewOn: 'Commentaires dans',
  productStoragePeriod: 'Période de stockage du produit',
  inspectionRules: "Règles d'inspection",
  afterSalesPolicy: 'Politique après - vente',
  insuranceAndCompensation: 'Assurance et indemnisation',
  oneStopService: 'Un service à guichet unique',
  whatCustomersSay: 'Ce que disent nos clients',
  customerSay1:
    'acbuy est le meilleur agent commercial que j’ai jamais utilisé – c’est la première fois que j’utilise acbuy et ses produits sont moins chers que n’importe quel autre agent commercial, ce qui est fantastique!',
  customerSay2:
    "Après être devenu promoteur d'acbuy, j'ai reçu 300 $en cashback. Après cela, je reçois pas moins de 4% de cash back sur chaque commande",
  customerSay3:
    "Expérience de magasinage très agréable - J'ai utilisé acbuy pour magasiner de Chine, bonne qualité des produits, bon service à la clientèle et expédition rapide.",
  customerSay4:
    "Le meilleur agent - l'achat est fait le même jour. Il n'a fallu que 7 jours pour livrer le colis chez moi. C'est actuellement le meilleur agent",
  customerFirst: "Client d'abord, Service de qualité",
  customerServiceText:
    'Service à la clientèle en ligne 9 / 7 (09: 00 - 18: 00, heure BJ)',
  faqCategory: 'Catégories de questions fréquemment posées'
};
