import { defineStore } from 'pinia';
import { getUserInfo, setUserInfo } from '@/apis/user';
import { getRate } from '@/apis/pay';
import { ElMessage } from 'element-plus';
import { USER_STORE_KEY } from './config';

import { getLang } from '@/utils/getLanguage';
export const unitMap = {
  CNY: '￥',
  USD: '$',
  EUR: '€',
  GBP: '£',
  PLN: '',
  AUD: 'A$',
  RUB: '₽',
  CAD: 'C$'
};

const { language, currency } = getLang();
export default defineStore('userStore', {
  state: () => {
    return {
      currency, // 币种
      language, // 语言
      token: undefined,
      profile: {},
      redirectUrl: '/',
      rate: 1,
      loginTimer: undefined,
      transferFromMenu: 'pecenter'
    };
  },

  //计算总价 index.js 用store调用就行
  getters: {
    isLogin: state => state.token && state.profile.id,
    currencyUnit: state => unitMap[state.currency]
  },

  //同步异步都可以
  actions: {
    async login(res) {
      this.token = (res.tokenHead || '') + res.token;
      this.loginTimer = Date.now();
      await this.getUserInfo();
    },
    // 获取登录用户信息
    async getUserInfo() {
      try {
        const profile = await getUserInfo();
        this.profile = { ...this.profile, ...profile, id: profile.userId };
      } catch (error) {
        this.token = '';
      }
    },
    // 获取登录用户信息
    async setUserInfo(params) {
      await setUserInfo(params);
      this.getUserInfo();
    },
    // 退出登录
    logout() {
      this.token = undefined;
      this.loginTimer = undefined;
      this.profile = {};
    },
    // 选择币种和语言
    async chooseLangAndCurrency(lang, currency) {
      if (lang) {
        this.language = lang;
      }
      if (currency) {
        await this.getRate(currency);
        this.currency = currency;
      }
    },
    // 修改回跳地址
    setRedirectUrl(url) {
      this.redirectUrl = url;
    },
    // 更改所选语言和币种
    async setSelectLangAndCurrency(lang, currency) {
      try {
        await this.chooseLangAndCurrency(lang, currency);
      } catch (error) {
        ElMessage({ type: 'error', message: error });
      }
    },
    // 获取汇率
    async getRate(currency) {
      const rate = await getRate({ currency: currency || this.currency });
      this.rate = rate;
    },
    // 设置免责声明
    setDisclaimerAgree(isAgree, noShow) {
      this.profile.disclaimerAgree = isAgree;
      this.profile.againShowAgree = noShow ?? this.profile.againShowAgree;
    },
    // 同意 分销
    agreeDistribution() {
      this.profile.isAgreeDistribution = true;
    },
    // 同意 提包协议
    agreeSubmitPack(isAgree) {
      this.profile.isAgreeSubmitPack = isAgree;
    },
    // 设置银行转帐来自哪里
    setTransferFromMenu(transferFromMenu) {
      this.transferFromMenu = transferFromMenu;
    }
  },
  persist: {
    key: USER_STORE_KEY,
    storage: localStorage,
    paths: ['token', 'profile', 'language', 'currency', 'rate', 'loginTimer']
  }
});
