export default {
  type: 'modèle',
  relevanceNo: 'Numéro unique associé',
  consultContent: 'Consulter le contenu',
  submitTime: 'Heure de soumission',
  consultTypeEnum: {
    0: 'Consultation sur commande',
    1: 'Consultation des colis'
  },
  consultStatusEnum: {
    0: 'Non traité non traité',
    1: 'En cours de traitement',
    2: 'Déjà terminé avec'
  }
}