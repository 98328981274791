export default {
  allMsg: 'Toutes les actualités',
  systemMsg: 'Message du système',
  personMsg: 'Un message humain',
  unread: 'Pas lire',
  seeDetail: 'Voir les détails',
  read: 'A lire',
  replyNow: 'Répondez maintenant',
  message: 'Station dans la lettre',
  operateSuccess: 'Succès de l’opération',
  holder: {
    searchHolder: 'Numéro de colis/numéro de commande/numéro d’article'
  }
}