import { defineStore } from 'pinia';
import { getMessageStatics, batchMessageRead } from '@/apis/user';
import { getUserConsult, readConsult } from '@/apis/resource';

export default defineStore('consultStore', {
  state: () => {
    return {
      consultInfo: { // 订单咨询
        unreadCount: 0, // 未读个数
      },
      msgInfo: { // 站内信
        unreadCount: 0, // 未读个数
      }
    };
  },
  actions: {
    // 获取 站内信  未读个数
    async getMessageCount () {
      try {
        const res = await getMessageStatics({ pageSize: 100000, pageNum: 1 })
        this.msgInfo.unreadCount = res.unread;
      } catch (error) {
        this.msgInfo.unreadCount = 0
      }
    },
    // 获取 咨询 未读个数
    async getConsultCount () {
      try {
        const res = await getUserConsult({ pageSize: 100000, pageNum: 1 })
        this.consultInfo.unreadCount = res.rows.reduce((acc, item) => {
          return acc + (item.readStatus === 1 ? 0 : 1);
        }, 0);
      } catch (error) {
        this.consultInfo.unreadCount = 0
      }
    },
    // 阅读站内信
    async readMessage (ids) {
      try {
        await batchMessageRead(ids)
        setTimeout(() => {
          this.getMessageCount()
        }, 2000);
      } catch (error) {
        //
      }
    },
    // 已读 咨询
    async readConsult (params) {
      try {
        await readConsult(params)
        this.getConsultCount()
      } catch (error) {
        //
      }
    },
    // 更新信息相关的个数
    async updateAllMsgCount () {
      this.getConsultCount()
      this.getMessageCount()
    }
  },
  persist: {
    key: 'consultStore',
    storage: sessionStorage,
    paths: ['orderConsult', 'msgInfo']
  }
});
