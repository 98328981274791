export default {
  type: 'Type',
  relevanceNo: 'Associated order number',
  consultContent: 'Consultation content',
  submitTime: 'Submit Time',
  consultTypeEnum: {
    0: 'Order inquiry',
    1: 'Parcel consultation'
  },
  consultStatusEnum: {
    0: 'untreated',
    1: 'being processed',
    2: 'completed'
  }
}