export default {
  storeTo: 'Warehouse to',
  weightHaveUnit: 'Weight (g)',
  productType: 'Product type',
  long: 'Length (cm)',
  width: 'Width (cm)',
  height: 'Height (cm)',
  seeDetail: 'View details',
  queryTips: 'Please enter the corresponding parameters to check the freight!',
  carrier: 'Carrier',
  carrierCode: 'Carrier code',
  product: 'Product',
  productCode: 'Product code',
  totalfee: 'Total cost',
  billingWeight: 'Charged weight',
  feeEstimation: 'Cost estimate',
  postFeeEstimation: 'Shipping estimation',
  postFee: 'Shipping Cost',
  postTime: 'Estimated Shipping Time',
  packUp: 'Collapse',
  startWeight: 'Start weight',
  endWeight: 'End weight',
  calcExpression: 'Calculation expression',
  baseFreight: 'Base freight',
  registrationFee: 'Registration fee',
  noSend: 'Unavailable products',
  canSend: 'Available products',
  priceFrom: 'Cost composition',
  itemLimit: 'Item limit',
  disReason: 'Reason for unavailability',
  BasicFreight: 'Basic Freight',
  OperationFreight: 'Operation Fee',
  RegistrationFreight: 'Registration Fee',
  IossFee: 'IOSS Fee',
  TariffFreight: 'Tariff Fee',
  VATFregith: 'Value Added Tax (VAT) Fee',
  AserviceFee: 'Additional Service Fee',
  ExtraSizeFreight: 'Extra Size Surcharge',
  AdditionalDeliveryFeeForFemoteAreas:
    'Additional Delivery Fee for Remote Areas',
  FuelSurcharge: 'Fuel Surcharge',
  zoneCode: 'Zone',
  amount: 'Price',
  rate: 'Rate',
  conditionExpression: 'Calculation',
  feeFactor: 'Fee factor',
  continuedPrice: 'Continued Weight Price',
  continuedWeight: 'Contined Weight(g)',
  firstPrice: 'First Weight Price',
  firstWeight: 'First Weight(g)',
  weightRangeStart: 'Start Weight(g)',
  weightRangeEnd: 'End Weight(g)',
  holder: {
    toCountry: 'Choose destination',
    inputWeight: 'Please enter weight',
    selectCountry: 'Please select a country',
    selectCarrier: 'Please select a carrier/carrier line/route country',
    selectCategory: 'Choose mail restriction'
  },
  typeDelivery: 'Types of Delivery',
  shippingTime: 'Estimated Shipping Time',
  deliveryTips: 'Delivery Tips',
  estimatedShippingFee: 'Estimated Shipping Fee',
  lineServiceTips:
    'Note: All logistics services for non acbuy self operated routes are provided by third-party logistics providers, who will adjust the freight prices according to market conditions. If there are any price changes, acbuy will notify you in advance. If you encounter any problems with logistics services, acbuy can communicate with the third-party logistics provider on your behalf.',
  logisticsStatement: '<Logistics service statement>'
};
