export default {
  allMsg: 'Todas as notícias',
  systemMsg: 'A Mensagem de sistema',
  personMsg: 'Mensagens por homem',
  unread: 'Não Lido',
  seeDetail: 'Ver mais detalhes',
  read: 'li',
  replyNow: 'Responder agora responder',
  message: 'Estação dentro da carta',
  operateSuccess: 'Operação com sucesso',
  holder: {
    searchHolder: 'Número do pacote/número do pedido/número do artigo'
  }
}