export default {
  agreed: 'De acuerdo',
  searchStatement: 'Términos de Servicio de Búsqueda',
  noData: 'No hay más datos',
  starts: 'Estrellas',
  searching: 'Buscando',
  searchService: 'Términos del Servicio de Búsqueda',
  filter: {
    performanceQuality: 'Calidad de cumplimiento',
    comprehensiveExperienceScore: 'Puntuación de experiencia integral',
    fourStartDown: 'Menos de 4 estrellas',
    certifiedFactory: 'Fábrica certificada',
    dayYield: 'Tasa de recolección en 24 horas',
    doubleDayYield: 'Tasa de recolección en 48 horas',
    performanceGuarantee: 'Garantía de cumplimiento',
    dayShipments: 'Envío el mismo día',
    strictSelect: 'Selección estricta',
    disStrictSelect: 'Selección estricta de distribución',
    dayShip: 'Envío en 24 horas',
    doubleDayShip: 'Envío en 48 horas',
    sevenDayNoReason: '7 días sin motivo',
    oneIssuing: 'Soporte para envío de un artículo',
    onePostIssuing:
      'Soporte para envío gratuito (envío gratuito de un artículo)',
    sevenDayNew: 'Novedades en 7 días',
    thirtyDayNew: 'Novedades en 30 días',
    wordStrictSelect: 'Selección estricta global',
    isSelect: 'Selección de paletas cross-border',
    priceSort: 'Ordenar por precio',
    compositeSort: 'Ordenar por relevancia',
    up: 'Contraer',
    reset: 'Reiniciar',
    moreFilters: 'Más opciones de filtro',
    bestMatch: 'Mejor coincidencia',
    saleHighToLow: 'Ventas: de alta a baja',
    saleLowToHigh: 'Ventas: de baja a alta',
    priceHighToLow: 'Precio: de alto a bajo',
    priceLowToHigh: 'Precio: de bajo a alto',
    fastToSlow: 'Tiempo de envío: de rápido a lento',
    slowToFast: 'Tiempo de envío: de lento a rápido'
  }
};
