export default {
  orderStatus: {
    0: 'You have submitted the order, please wait for the purchasing agent to receive the order',
    23: 'The purchasing agent has received the order and is in contact with the merchant',
    22: 'Your goods have been ordered, transaction number: ',
    5: 'Merchant has shipped, shipping method: ',
    10: 'Your product has arrived at the acbuy warehouse',
    16: 'You have submitted the return, please wait for the purchasing agent to process',
    17: 'You have submitted a replacement, please wait for the purchasing agent to process',
    20: 'The order has been returned',
    beenReturned: ' Check Refunds >',
    30: 'Your item has been shipped, view the parcel where it is located: ',
    43: 'Your return request is cancelled',
    44: 'Your exchange request is cancelled'
  },
  expressNo: 'Tracking number: '
};
