export default {
  agreed: 'Concordou',
  searchStatement: 'Termos de Serviço de Busca',
  noData: 'Não há mais dados',
  starts: 'Estrelas',
  searching: 'Procurando',
  searchService: 'Termos de Serviço de Busca',
  filter: {
    performanceQuality: 'Qualidade de desempenho',
    comprehensiveExperienceScore: 'Pontuação de experiência geral',
    fourStartDown: 'Menos que 4 estrelas',
    certifiedFactory: 'Fábrica certificada',
    dayYield: 'Taxa de recebimento em 24h',
    doubleDayYield: 'Taxa de recebimento em 48h',
    performanceGuarantee: 'Garantia de desempenho',
    dayShipments: 'Envio no mesmo dia',
    strictSelect: 'Seleção rigorosa',
    disStrictSelect: 'Distribuição seleção rigorosa',
    dayShip: 'Envio em 24h',
    doubleDayShip: 'Envio em 48h',
    sevenDayNoReason: '7 dias sem motivo',
    oneIssuing: 'Suporte a envio de um item',
    onePostIssuing: 'Suporte a envio grátis (envio grátis de um item)',
    sevenDayNew: 'Novidades em 7 dias',
    thirtyDayNew: 'Novidades em 30 dias',
    wordStrictSelect: 'Seleção global rigorosa',
    isSelect: 'Seleção de paletes cross-border',
    priceSort: 'Ordenar por preço',
    compositeSort: 'Ordenar por relevância',
    up: 'Recolher',
    reset: 'Redefinir',
    moreFilters: 'Mais opções de filtro',
    bestMatch: 'Melhor correspondência',
    saleHighToLow: 'Vendas: do maior para o menor',
    saleLowToHigh: 'Vendas: do menor para o maior',
    priceHighToLow: 'Preço: do maior para o menor',
    priceLowToHigh: 'Preço: do menor para o maior',
    fastToSlow: 'Tempo de Envio: Rápido para Abrandar',
    slowToFast: 'Tempo de Envio: Lento para Rápido'
  }
};
