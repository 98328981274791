import { createI18n } from 'vue-i18n';
import messages from './langs';
import { getLang } from '@/utils/getLanguage';

const { language } = getLang();

const i18n = createI18n({
  locale: language, // 语言标识
  globalInjection: true, // 全局注入,可以直接使用$t
  // 处理报错: Uncaught (in promise) SyntaxError: Not available in legacy mode (at message-compiler.esm-bundler.js:54:19)
  legacy: false,
  messages,
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false
});

export default i18n;
