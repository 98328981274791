import form from './en/form'
import home from './en/home'
import search from './en/search'
import cart from './en/cart'
import product from './en/product'
import order from './en/order'
import pecenter from './en/pecenter'
import pay from './en/pay'
import common from './en/common'
import estimation from './en/estimation'
import packages from './en/package'
import orderDetail from './en/orderDetail'
import warnTips from './en/warnTips'
import status from './en/status'
import tips from './en/tips'
import storage from './en/storage'
import uniOrder from './en/uniOrder'
import bankTransfer from './en/bankTransfer'
import distribution from './en/distribution'
import site from './en/site'
import consult from './en/consult'

export default {
  form,
  home,
  search,
  cart,
  product,
  order,
  pecenter,
  pay,
  common,
  estimation,
  packages,
  orderDetail,
  warnTips,
  status,
  tips,
  storage,
  uniOrder,
  bankTransfer,
  distribution,
  site,
  consult
}