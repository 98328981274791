export default {
  allMsg: 'All messages',
  systemMsg: 'System messages',
  personMsg: 'Manual messages',
  unread: 'Unread',
  seeDetail: 'See Details',
  read: 'Read',
  replyNow: 'reply now',
  message: 'Message',
  operateSuccess: 'Operate successfully',
  holder: {
    searchHolder: 'Package No./Order No./Item No.'
  }
}