import dayjs from 'dayjs';
import TimeZone from 'moment-timezone';
const timeZone = {
  value: undefined // 时区值
};
export const formatTimeStr = (date, format = 'MM. DD, YYYY HH:mm:ss') => {
  if (!timeZone.value) {
    timeZone.value = TimeZone.tz.guess();
  }
  const currentTime = dayjs(date);
  return TimeZone.tz(currentTime.toISOString(), timeZone.value).format(format);
};

export const formatTimeStrUSA = (date, format = 'MM. DD, YYYY HH:mm:ss') => {
  if (!timeZone.value) {
    timeZone.value = TimeZone.tz.guess();
  }
  const currentTime = dayjs(date);
  const result = TimeZone.tz(currentTime.toISOString(), timeZone.value).format(
    format
  );
  const monthsArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  let resultUSA = '';
  let monthsStr = result.substring(0, 2);
  let monthsStrUSA = '';
  if (monthsStr.substring(0, 1).includes('0')) {
    monthsStrUSA = monthsArr[Number(monthsStr.substring(1)) - 1];
  } else {
    monthsStrUSA = monthsArr[Number(monthsStr) - 1];
  }
  resultUSA = result.replace(`${monthsStr}`, monthsStrUSA);
  return resultUSA;
};
