export default {
  bankTransfer: 'Virement bancaire',
  firstTitle: 'Veuillez sélectionner la devise du virement',
  secondTitle: 'Après le transfert',
  bankTips:
    "Obtenez les informations du bénéficiaire et rendez-vous au guichet bancaire correspondant pour remplir l'ordre de paiement. Veuillez vérifier attentivement votre orthographe.",
  receiverTitle: 'Informations du bénéficiaire',
  bankAttrEnum: {
    0: 'Numéro de compte',
    1: 'Numéro de routage',
    2: 'Nom du compte',
    3: 'Nom de la banque',
    4: 'Adresse de la banque',
    5: 'Code postal',
    6: 'Pays/Région',
    7: 'Type de compte',
    8: 'Remarque'
  },
  transferTips:
    "Vous pouvez nous informer de l'une des manières suivantes, et nous le vérifierons dès que possible !",
  transferName: "Nom du donneur d'ordre",
  transferBank: "Banque du donneur d'ordre",
  transferNo: 'Numéro de référence',
  submit: 'Soumettre maintenant',
  successSubmit: 'Soumission réussie',
  successSubmitTips:
    "* Si un problème survient lors de la vérification, nous contacterons votre e-mail d'inscription acbuy.",
  tradeDetail: 'Détail de la transaction',
  backOrder: 'Retour à la commande',
  holder: {
    inputNo: "Veuillez fournir le numéro de référence s'il est disponible."
  },
  transferExplain: 'Instructions de virement bancaire :',
  transferExplainEnum: {
    0: 'acbuy ne facture pas de frais de traitement pour ce canal de paiement. Les frais éventuels de la banque émettrice dépendent des politiques pertinentes de la banque correspondante.',
    1: 'Cette méthode de paiement prend en charge plusieurs devises. Les clients peuvent choisir le compte bancaire correspondant pour le virement en fonction de votre région et de vos besoins.',
    2: 'Normalement, il faut 1 à 3 jours ouvrables pour arriver ; dans des circonstances spéciales, cela peut prendre 1 à 5 jours ouvrables.',
    3: "acbuy convertira le montant en RMB au taux de change d'acbuy lorsque le virement sera reçu, puis le créditera sur votre compte.",
    4: 'Le montant final en RMB sur le compte acbuy = Le montant de votre virement * Taux de change de la plateforme acbuy.'
  }
};
