export default {
  agreed: 'Agreed',
  searchStatement: 'Search Terms of Service',
  noData: 'No more data',
  starts: 'Star',
  searching: 'Searching',
  searchService: 'Search Terms of Service',
  filter: {
    performanceQuality: 'Performance quality',
    comprehensiveExperienceScore: 'Comprehensive experience score',
    fourStartDown: 'Under four stars',
    certifiedFactory: 'Certified factory',
    dayYield: '24 hours yield rate',
    doubleDayYield: '48 hours yield rate',
    performanceGuarantee: 'Performance guarantee',
    dayShipments: 'Same Day Shipping',
    strictSelect: 'Strict selection',
    disStrictSelect: 'Strict selection of distribution',
    dayShip: '24 hours delivery',
    doubleDayShip: '48 hours delivery',
    sevenDayNoReason: 'Seven days for no reason',
    oneIssuing: 'Support one piece on behalf of the shipment',
    onePostIssuing:
      'Support free shipping (One piece on behalf of shipment are free)',
    sevenDayNew: '7 days to new products',
    thirtyDayNew: '30 days to New Products',
    wordStrictSelect: 'Global strict selection',
    isSelect: 'Cross-border select pallets',
    priceSort: 'Price ranking',
    compositeSort: 'Comprehensive ranking',
    up: 'Put away',
    reset: 'Reset',
    moreFilters: 'More filter criteria',
    bestMatch: 'Best Match',
    saleHighToLow: 'Sales: High To Low',
    saleLowToHigh: 'Sales: Low To High',
    priceHighToLow: 'Price: High To Low',
    priceLowToHigh: 'Price: Low To High',
    fastToSlow: 'Shipping Time: Fast To Slow',
    slowToFast: 'Shipping Time: Slow To Fast'
  }
};
