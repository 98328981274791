export default {
  orderStatus: {
    0: "Vous avez passé la commande, veuillez attendre que l'agent prenne la commande",
    23: "L'agent a pris en charge la commande, en communication avec le vendeur",
    22: 'Votre produit a été commandé, numéro de commande : ',
    5: "Le vendeur a expédié le produit, mode d'expédition : ",
    10: "Votre produit est arrivé à l'entrepôt acbuy",
    16: "Vous avez soumis un retour, veuillez patienter le traitement par le responsable d'achat",
    17: "Vous avez soumis un échange, veuillez patienter le traitement par le responsable d'achat",
    20: 'Ce commande a déjà été effectuée pour le retour,',
    beenReturned: ' Voir le remboursement >',
    30: 'Votre article a été expédié, consultez votre colis : ',
    43: 'Votre demande de retour a été annulée',
    44: 'Votre demande de remplacement a été annulée'
  },
  expressNo: 'Numéro de suivi : '
};
