import form from './pt/form';
import home from './pt/home';
import search from './pt/search';
import cart from './pt/cart';
import product from './pt/product';
import order from './pt/order';
import pecenter from './pt/pecenter';
import pay from './pt/pay';
import common from './pt/common';
import estimation from './pt/estimation';
import packages from './pt/package';
import orderDetail from './pt/orderDetail';
import warnTips from './pt/warnTips';
import status from './pt/status';
import tips from './pt/tips';
import storage from './pt/storage';
import uniOrder from './pt/uniOrder'
import bankTransfer from './pt/bankTransfer'
import distribution from './pt/distribution'
import site from './pt/site'
import consult from './pt/consult'

export default {
  form,
  home,
  search,
  cart,
  product,
  order,
  pecenter,
  pay,
  common,
  estimation,
  packages,
  orderDetail,
  warnTips,
  status,
  tips,
  storage,
  uniOrder,
  bankTransfer,
  distribution,
  site,
  consult
};
