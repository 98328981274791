import request from '@/utils/request';
import { marketPrefix } from './config';

// 获取等级详情配置
export const getLevelConfig = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/levelConf/page`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取提现列表
export const getWithdrawList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/withdraw/page`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取邀请记录
export const getInviteList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/inviteRecord/page`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取当前账户信息
export const getCurrentUserInfo = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${marketPrefix}/promoterInfo/get`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 修改用户等级
export const editUserLevel = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${marketPrefix}/promoterInfo/updateLevel`,
      params
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取 用户数据 明细
export const getUserStatisticsData = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${marketPrefix}/promoterInfo/balance`,
      params
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取佣金明细
export const getCommissionList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${marketPrefix}/settlePackage/page`,
      params
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取群组成员
export const getTeamUserList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${marketPrefix}/promoterInfo/myTeamPage`,
      params
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取提现方式列表
export const getWithdrawMethodList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${marketPrefix}/withdrawBank/getBank`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 添加提现方式
export const addWithdrawMethod = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/withdrawBank/add`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 删除提现方式
export const delWithdrawMethod = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/withdrawBank/del`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取提现方式
export const getWithdrawMethod = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/withdrawBank/del`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 提现审批
export const withdrawApply = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/withdraw/apply`, params);

    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 读取协议
export const readAgreement = ()=> {
   // eslint-disable-next-line no-async-promise-executor
   return new Promise(async (resolve, reject) => {
    const res = await request.post(`${marketPrefix}/promoterInfo/updateHasAgreeAgreement`, {});

    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
}
