export default {
  noData: 'No hay más datos',
  delConfirmTips: '¿Está seguro de que desea eliminar los datos seleccionados?',
  delSuccess: 'Eliminado con éxito',
  tips: 'Consejos',
  add: 'Añadir',
  edit: 'Editar',
  del: 'Eliminar',
  editSuccess: 'Modificación exitosa',
  activeAccount: 'Activar cuenta',
  mailActiveSuccess: 'Cuenta activada con éxito',
  back: 'Volver',
  goLogin: 'Ir al inicio de sesión',
  email: 'Correo electrónico',
  emailSendSuccess: 'Correo electrónico enviado con éxito',
  paySuccess: 'Pago exitoso',
  action: 'Acción',
  logoutSuccess: 'Cierre de sesión exitoso',
  plsUpload: 'Por favor, suba',
  upload: 'Subir',
  file: 'Archivo',
  uploadFail: 'Error al subir',
  uploadSuccess: 'Subida exitosa',
  pay: 'Pagar',
  day: 'Día',
  servePrice: 'Cargo por servicio',
  skillServePrice: 'Cargo por servicio técnico',
  unitMonth: 'Mes(es)',
  num: 'Cantidad',
  photo: 'Foto',
  product: 'Producto',
  sizeHaveUnit: 'Tamaño (cm)',
  weightHaveUnit: 'Peso (g)',
  fee: 'Tarifa',
  free: 'Gratis',
  submitTime: 'Hora de envío',
  weight: 'Peso',
  cancelReason: 'Motivo de cancelación',
  submitPackage: 'Enviar paquete',
  submitSuccess: 'Envío exitoso',
  price: 'Precio',
  status: 'Estado',
  noProduct: 'No hay productos',
  backEdit: 'Volver a editar',
  errorHint: 'Error en la interfaz, por favor intente de nuevo',
  reload: 'Actualizar',
  reloadPageTips:
    'Su cuenta ha cambiado. Por favor, vuelva a cargar la página para obtener información actualizada.',
  cancel: 'Cancelar',
  noMsg: 'Sin información',
  totalAmount: 'Monto total',
  see: 'Ver',
  confirm: 'Confirmar',
  send: 'Enviar',
  nowNoInfo: 'No hay información en este momento',
  genderEnum: {
    0: 'Hombre',
    1: 'Mujer'
  },
  goEmailActiveTips:
    '¡Por favor, vaya a su correo electrónico para activar su cuenta!',
  holder: {
    inputRemark: 'Por favor, ingrese comentarios',
    inputExplainCancelReason: 'Por favor, explique el motivo de la cancelación',
    inputAddAddress: 'Por favor, agregue la dirección de envío'
  },
  acceptMsg:
    'Este sitio web almacena cookies en su computadora para brindarle una mejor experiencia. Consulte su política de privacidad para obtener más información.',
  accept: 'Aceptar',
  refuse: 'Rechazar',
  notice: 'Aviso',
  noticeMsg:
    'invitar a 3 usuarios activos puede obtener una recarga de efectivo de 300 yuanes',
  english: 'Inglés',
  spanish: 'Español',
  french: 'Francés',
  chinese: 'Chino',
  copySuccess: 'Copia exitosa',
  copyError: 'Error al copiar',
  noAgainShow: 'No mostrar de nuevo',
  download: 'Descargar',
  downloadSuccess: 'Descarga exitosa',
  uploadImgTips: 'Por favor, suba el archivo jpg / PNG / webp / JPEG',
  noCheckAboutAgree: 'Aceptar el acuerdo relevante sin marcar'
};
