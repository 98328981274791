export default {
  goodsStatusEnum: {
    '-1': 'À payer',
    0: 'À acheter',
    1: 'À confirmer',
    2: 'En attente de paiement supplémentaire',
    3: 'Retour de produit',
    4: 'Échange de produit',
    5: 'Expédié',
    6: 'Expédition séparée',
    7: 'Traitement différé',
    8: 'Signé',
    9: 'Arrivé',
    10: 'En stock',
    11: 'Commande annulée',
    12: 'Produits détruits',
    13: 'Commande expirée',
    14: 'Confirmation des informations',
    15: "Confirmation d'achat",
    16: 'Retour en attente',
    17: 'Échange en attente',
    18: 'Traitement du retour',
    19: "Traitement de l'échange",
    20: 'Retour terminé',
    21: 'Échange terminé',
    22: 'Commandé',
    23: 'En cours de traitement',
    24: 'Annulation de commande',
    25: 'Commande anormale',
    26: 'Remboursement en attente de retour',
    27: "Échange en attente d'envoi par le vendeur",
    28: "Sortie d'entrepôt",
    29: 'Emballage terminé',
    30: "Envoi à l'étranger",
    31: 'Marchandise reçue',
    32: 'Paiement refusé',
    33: 'Audit de contrôle des risques',
    34: "Liste d'annulations",
    35: 'Liste des commandes gelées',
    36: 'Paiement pré-souscription',
    37: "En attente d'expédition",
    38: 'Remboursement gelé',
    39: 'Marchandise refusée',
    40: 'Refus terminé',
    41: 'Exception non stockée',
    42: 'Exception stockée',
    43: 'Retour annulé',
    44: 'Échange annulé',
    45: 'Signé et en attente',
    46: 'Panama en attente de génération',
    47: 'Panama en attente de paiement',
    48: 'Paiement effectué',
    49: 'Produit abandonné',
    50: 'Achèvement forcé',
    51: "En attente d'approbation du retour par le vendeur",
    52: "En attente d'approbation de l'échange par le vendeur",
    53: 'Colis retourné invalide',
    54: '1688 en attente de génération',
    55: '1688 en attente de paiement',
    56: 'Yino en attente de génération',
    57: 'Yino en attente de paiement',
    58: 'Produits en transfert'
  },
  appendStatusEnum: {
    0: 'En attente',
    1: 'En cours de traitement',
    2: 'Annulé',
    3: 'Terminé',
    4: 'Retourné'
  }
};
