import {
  ElButton,
  ElInput,
  ElForm,
  ElFormItem,
  ElSelect,
  ElUpload,
  ElTabs,
  ElTabPane,
  ElInputNumber,
  ElPopover,
  ElRow,
  ElCol,
  ElCheckbox,
  ElIcon,
  ElCheckboxGroup,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElSkeleton,
  ElSkeletonItem,
  ElDialog,
  ElPagination,
  ElConfigProvider,
  ElDatePicker,
  ElCascader,
  ElTable,
  ElTableColumn,
  ElRadio,
  ElRadioGroup,
  ElScrollbar,
  ElAvatar,
  ElBadge,
  ElDivider,
  ElTooltip,
  ElCard,
  ElImage,
  ElImageViewer,
  ElTag,
  ElSwitch,
  ElCollapse,
  ElCollapseItem,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElCarousel,
  ElCarouselItem
} from 'element-plus';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';

export default app => {
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  app.use(ElButton);
  app.use(ElInput);
  app.use(ElForm);
  app.use(ElFormItem);
  app.use(ElSelect);
  app.use(ElUpload);
  app.use(ElTabs);
  app.use(ElTabPane);
  app.use(ElInputNumber);
  app.use(ElPopover);
  app.use(ElRow);
  app.use(ElCol);
  app.use(ElCheckbox);
  app.use(ElIcon);
  app.use(ElCheckboxGroup);
  app.use(ElDropdown);
  app.use(ElDropdownMenu);
  app.use(ElDropdownItem);
  app.use(ElSkeleton);
  app.use(ElSkeletonItem);
  app.use(ElDialog);
  app.use(ElPagination);
  app.use(ElConfigProvider);
  app.use(ElDatePicker);
  app.use(ElCascader);
  app.use(ElTable);
  app.use(ElTableColumn);
  app.use(ElRadio);
  app.use(ElRadioGroup);
  app.use(ElScrollbar);
  app.use(ElAvatar);
  app.use(ElBadge);
  app.use(ElDivider);
  app.use(ElTooltip);
  app.use(ElCard);
  app.use(ElImage);
  app.use(ElTag);
  app.use(ElSwitch);
  app.use(ElImageViewer);
  app.use(ElCollapse);
  app.use(ElCollapseItem);
  app.use(ElMenu);
  app.use(ElSubMenu);
  app.use(ElMenuItemGroup);
  app.use(ElMenuItem);
  app.use(ElCarousel);
  app.use(ElCarouselItem);
};
