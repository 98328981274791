export default {
  allMsg: 'Todos los mensajes',
  systemMsg: 'Los mensajes del sistema',
  personMsg: 'Mensaje humano',
  unread: 'Sin leer',
  seeDetail: 'Ver los detalles',
  read: 'Ha leído',
  replyNow: 'Responder ahora responder',
  message: 'Carta interna',
  operateSuccess: 'Éxito de operación',
  holder: {
    searchHolder: 'Número de paquete/número de pedido/número de artículo'
  }
}