export default {
  fillInOrder: '填写代购订单',
  inputTips: "（<span style='color: red'>*</span>为必填项）",
  process: {
    first: '填写订单并提交',
    second: '支付商品费用和国内运费',
    three: '等待商品到达仓库'
  },
  productInfo: '商品信息',
  productInfoTips:
    'acbuy上显示的所有可代购商品均来自第三方购物平台，并非由acbuy直接销售。因此，如果上述商品涉及任何侵权问题，所有产生的责任均由第三方平台上的相应卖家承担，acbuy不承担任何相关、附带或连带责任。',
  fees: '费用',
  feeStandard: '费用标准',
  feeStandardTips:
    '对于您在本页面提交的代购订单，如果商品来自孔夫子二手书、小红书、有赞等第三方平台，可能存在无法购买的情况。商品可能存在运输风险，或可能收取额外的服务费。实际情况取决于我们工作人员的反馈。更多详情，请查看"帮助中心 - 第三方代购服务费用标准"。',
  link: '链接',
  name: '名称',
  remark: '备注',
  unitPrice: '单价（人民币）',
  qty: '数量',
  goodsAttr: '商品规格',
  chinaPostage: '运费（人民币）',
  holder: {
    inputUrl: '请粘贴商品URL',
    inputName: '请在此输入商品名称（100字以内）',
    inputAttr: '请输入商品规格',
    inputRemark:
      '如果商品有多种款式、尺寸、颜色等，请根据商品页面的分类描述在此指定您需要的。如果您购买相同商品但规格不同，请分开下单。'
  },
  other: '其他（可选）',
  productImg: '商品图片',
  fareTip: '填写从网站向所选仓库收取的运费。'
};
