export default {
  tipsOne:
    '1. Nós guardaremos para você gratuitamente por 90 dias, mais de 90 dias ainda não apresentar o conhecimento de pacote ou fazer nenhum feedback será considerado como a entrega dos bens, nós unificaremos os bens para fazer o tratamento da destruição.',
  tipsTwo:
    '2. Os produtos já foram armazenados e você pode entrar no meu armazém a qualquer momento para enviar ao seu endereço de entrega no exterior',
  orderDetail: 'Detalhes do pedido',
  orderNo: 'Número do pedido',
  orderConsult: 'Consulta de pedido',
  consult: 'Inquérito',
  orderConsultTips:
    'O comprador só pode lidar com negócios relacionados a este pedido, para outras consultas de negócios, entre em contato',
  enquiryReplyMsg:
    'Digite aqui, responderemos em até 24 horas. (Nota: Às vezes, usaremos software de tradução para responder, pode não ser possível transmitir com precisão, esperamos que você entenda)',
  stepListEnum: {
    '-1': 'Selecione o produto',
    0: 'Enviar pacote',
    6: 'Pagar frete',
    9: 'Enviar pacote',
    20: 'Confirmar recebimento'
  }
};
