export default {
  tipsOne:
    '1. Nous garderons gratuitement pour vous pendant 90 jours, plus de 90 jours ne soumettez toujours pas le border-route de colis ou ne pas faire aucun retour sera considéré comme renonciation des marchandises, nous allons unifier les marchandises pour faire le traitement de destruction.',
  tipsTwo:
    '2. Los productos ya se han puesto en el almacén y pueden entrar en mi almacén en cualquier momento para enviar a su dirección de recepción en el extranjero',
  orderDetail: 'Detalle del pedido',
  orderNo: 'Número de pedido',
  orderConsult: 'Consulta de pedido',
  consult: 'Consulta',
  orderConsultTips:
    'El agente de compras solo puede manejar negocios relacionados con este pedido. Para otras consultas de negocios, comuníquese con',
  enquiryReplyMsg:
    'Escriba aquí, le responderemos en 24 horas. (Nota: A veces usaremos software de traducción para responder, puede que no se pueda transmitir con precisión, esperamos que entienda)',
  stepListEnum: {
    '-1': 'Seleccionar productos',
    0: 'Enviar paquete',
    6: 'Pagar el costo del envío',
    9: 'Enviar paquete',
    20: 'Confirmar la recepción'
  }
};
