import { createApp } from 'vue';
import i18n from './i18n/i18n';
import stores from './stores';
import router from './router';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import ElementUI from './plugins/elementui';

import './utils/flexible.js';

import useErrorHandler from './utils/errorHandler';
import { useDirective } from './utils/directive';
import useGlobalProps from './utils/globalProps';
// 引入阿里云字体图标css
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';

// 1. 重置样式的库
import 'normalize.css';
// 2. 自己项目的重置样式和公用样式
import '@/assets/styles/common.scss';
// import "element-plus/theme-chalk/src/common/var.scss";

import App from './App.vue';

const app = createApp(App);
app.use(VueClipboard).use(i18n).use(stores).use(router).use(ElementUI);

useDirective(app);
useErrorHandler(app);
useGlobalProps(app);

app.mount('#app');
