import request from '@/utils/request'
import { userPrefix } from './config'
// 添加商品到购物车
export const addProductToCart = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/cart/add`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 获取购物车商品数量
export const getCartProductNum = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/cart/nums`, { data: params })
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 获取购物车商品列表
export const getCartProductList = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/cart/allList`, { data: params })
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 删除购物车商品
export const delCartProduct = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/cart/removeSelected`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}
// 更新购物车内商品数量
export const updateCartProductNum = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/cart/edit`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 修改购物车备注
export const editProductRemark = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.put(`${userPrefix}/cart/comment`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 拆分商品
export const splitProduct = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/cart/split`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}

// 购物车结算下单
export const cartCreateUnifyOrder = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/cart/settleList`, params)
    if (res.code === 200 || res.code === 0) {
      resolve(res.data)
    } else {
      reject(res.msg)
    }
  })
}
