export default {
  myBalance: 'Mi saldo',
  topUp: 'Recargar',
  pay: 'Recargar',
  balance: 'Saldo',
  usableBalance: 'Saldo disponible',
  inputBalance: 'Por favor, ingrese el monto',
  payBalance: 'Monto de recarga',
  pecenter: 'Centro personal',
  lang: 'Idioma',
  cart: 'Carrito',
  recipientName: 'Destinatario',
  phone: 'Teléfono',
  region: 'Región',
  address: 'Dirección detallada',
  country: 'País',
  province: 'Provincia',
  city: 'Ciudad',
  postcode: 'Código postal',
  action: 'Acción',
  mobileSettings: 'Configuración móvil',
  name: 'Nombre',
  add: 'Agregar dirección de facturación de tarjeta de crédito',
  addSuccess: 'Agregado con éxito',
  editSuccess: 'Editado con éxito',
  editBtn: 'Editar',
  edit: 'Editar dirección de facturación de tarjeta de crédito',
  del: 'Eliminar',
  setDefault: 'Establecer como predeterminado',
  defaultAddress: 'Dirección predeterminada',
  setDefaultAddress: 'Establecer como dirección de envío predeterminada',
  setSuccess: 'Configuración exitosa',
  myAddress: 'Mis direcciones',
  addAddress: 'Agregar nueva dirección',
  tradeTime: 'Hora de transacción',
  type: 'Tipo',
  incomeOrExpend: 'Ingresos/Gastos',
  accountBalance: 'Saldo de la cuenta',
  tradeNo: 'Número de transacción',
  remark: 'Comentario',
  tag: 'Etiqueta de dirección',
  addBillAddress: 'Agregar dirección de facturación',
  deleteBillAddress: 'Eliminar dirección de facturación',
  editBillAddress: 'Editar dirección de facturación',
  mergePay: 'Pago combinado',
  username: 'Nombre de usuario',
  gender: 'Género',
  birthday: 'Cumpleaños',
  avatar: 'Avatar',
  email: 'Correo electrónico',
  site: 'Ubicación',
  myOrder: 'Pedido',
  sideBar: {
    myAccount: 'Mi cuenta',
    addressManage: 'Gestión de direcciones',
    myAsset: 'Mis activos',
    billAddress: 'Dirección de facturación'
  },
  holder: {
    receiver: 'Destinatarios',
    firstName: 'Nombre',
    name: 'Nombre',
    region: 'Por favor, seleccione provincia/ciudad/distrito/calle',
    address:
      'Por favor, ingrese información detallada de la dirección, como calle, número, comunidad, edificio, unidad, etc.',
    phone: 'Debe completar un número de teléfono o móvil',
    input: 'Por favor, ingrese',
    select: 'Por favor, seleccione',
    tagHolder: 'Máximo 120 caracteres (opcional)',
    postCodeNoMatch: 'El código postal no coincide con la región'
  },
  forget: {
    accountSafe: 'Seguridad de la cuenta',
    setPwd: 'Cambiar contraseña',
    inputBindEmail: 'Por favor, ingrese su correo electrónico vinculado',
    inputVerifyCode: 'Por favor, ingrese el código de verificación',
    inputPwd: 'Por favor, ingrese la nueva contraseña'
  },
  accountInfo: {
    name: 'Cuenta',
    selfInfo: 'Información personal',
    cardBillAddress: 'Dirección de facturación de tarjeta de crédito',
    shipAddress: 'Dirección de envío',
    editUser: 'Editar información del usuario',
    inputCountry: 'Por favor, ingrese su país',
    editEmail: 'Editar correo electrónico',
    editEmailHint:
      '(Recomendado solo cuando el correo electrónico se configuró incorrectamente o tiene problemas)',
    editEmailHint1:
      "Para la seguridad de su cuenta, primero debe pasar la verificación de seguridad con 'Código de verificación de correo electrónico + contraseña de inicio de sesión'",
    editEmailHint2:
      "Para la seguridad de su cuenta, primero debe pasar la verificación de seguridad con 'Código de verificación de correo electrónico'",
    currentEmail: 'Correo electrónico actual',
    clickVerify: 'Enviar',
    reGet: 'Reenviar',
    nextStep: 'Siguiente paso',
    notReGetCode: 'Reenviar después de',
    logoutAccount: 'Cerrar sesión de la cuenta',
    emailNotEmpty: 'El correo electrónico no puede estar vacío',
    logoutReason: 'Por favor, seleccione el motivo del cierre de sesión',
    latelyLogin: 'Inicio de sesión reciente',
    moreRecord: 'Más registros',
    visitRecord: 'Registros de visita',
    visitTime: 'Tiempo de visita',
    ipAndDevice: 'Dirección IP y tipo de dispositivo',
    editLoginPwdHint:
      'Una contraseña de alta seguridad puede hacer que su cuenta sea más segura. Se recomienda cambiar su contraseña regularmente y establecer una que incluya números y letras, y tenga más de 6 dígitos de longitud.',
    editPayPwdHint:
      'La contraseña requerida al usar el saldo de acbuy para pagar, configure la contraseña de pago para garantizar la seguridad del pago.',
    editVerifyEmailHint:
      'Para proteger la seguridad de su cuenta, verifique su correo electrónico.',
    payPwd: 'Contraseña de pago',
    editPayPwd: 'Cambiar contraseña de pago',
    setPayPwd: 'Establecer la contraseña de pago',
    deleteAccount: 'Eliminar cuenta'
  },
  logoutReasonEnum: {
    0: 'Problemas de seguridad/privacidad',
    1: 'Mal servicio al cliente',
    2: 'Costos demasiado altos',
    3: 'Proceso de compra complicado',
    4: 'Otras razones'
  },
  tradeTypeEnum: {
    0: 'Ingresos',
    1: 'Gastos'
  },
  asset: {
    currentBalance: 'Saldo actual',
    canWithdrawBalance: 'Cantidad que se puede retirar',
    withdraw: 'Retirar',
    serialNo: 'Número de serie',
    amount: 'Cantidad',
    lossAmount: 'Cantidad gastada',
    withdrawAmount: 'Cantidad de retiro',
    inputWithdrawAmount: 'Por favor, ingrese la cantidad de retiro',
    withdrawSuccess: 'Solicitud de retiro enviada con éxito',
    transactionDate: 'Fecha de transacción',
    withdrawMethod: 'Método de retiro',
    availableAmount: 'Cantidad disponible',
    transactionNo: 'Número de transacción',
    withdrawMsg:
      'Nota: la retirada de saldo se devolverá a través del canal original. El saldo se registra como gasto histórico y se devuelve a la cuenta de pago para la transacción de retiro.',
    transactionRecordsText:
      'Registros de transacciones de cantidades disponibles',
    withdrawRecordText: 'Registros de retiros'
  },
  package: {
    packageInfo: 'Información del paquete',
    weightHaveUnit: 'Peso (g)',
    sizeHaveUnit: 'Tamaño (cm)',
    freight: 'Costo de envío',
    fee: 'Tarifa de envío',
    transportMode: 'Modo de entrega',
    allPackage: 'Todos los paquetes',
    myPackage: 'Paquete'
  },
  packageStatusEnum: {
    0: 'Saldo pendiente de reconstitución',
    1: 'Pendiente de pago',
    2: 'En tránsito',
    3: 'Completado',
    4: 'Cancelado'
  },
  storage: {
    name: 'Almacén',
    warehouseTime: 'Tiempo de almacenamiento',
    submitTransport: 'Enviar para entrega',
    lineCanUse: 'Piezas disponibles',
    commonImg: 'Fotos de control de calidad',
    customPhoto: 'Foto detallada',
    extendCare: 'Almacenamiento extendido',
    extendHint:
      'Sus productos se mantendrán en el almacén por un período extendido de (30*N) días y la fecha de vencimiento es',
    packMode: 'Método de empaque',
    retainPack: 'Empaque reservado',
    normalPack: 'Empaque predeterminado',
    normalPackHint: 'Los artículos se empaquetarán con su embalaje original.',
    foldPack: 'Empaque plegado',
    foldPackHint:
      'Para cajas de cartón no fijadas con pegamento, el almacén las desmontará, doblará y aplanará, luego las colocará en su paquete, lo que puede reducir el volumen del paquete en cierta medida. Pero también hay casos de aumento del volumen del paquete (cuando hay menos productos, la longitud y el ancho del empaque plegado pueden aumentar).',
    discardPack: 'Descartar empaque',
    discardPackHint:
      'Después de descartar el empaque, el vendedor puede no aceptar ningún servicio postventa y el empaque no se puede encontrar/agregar de nuevo.',
    photoTemplate: 'Plantilla de foto'
  },
  operate: 'Operar',
  editAddress: 'Editar dirección',
  underPaid: 'Pendiente de pago',
  goTopay: 'Ir a pagar',
  viewRecord: 'Ver registro',
  faq: 'Preguntas frecuentes',
  question1: 'P: ¿Se congelará mi cuenta si solicito un retiro?',
  response1:
    'R: después de solicitar un retiro, la cuenta congelará la cantidad solicitada para garantizar que el monto del reembolso sea el mismo que usted solicitó',
  question2:
    "P: ¿'Completado' en los registros de retiro significa que el retiro de fondos fue exitoso?",
  response2:
    "R: 'Completado' significa que acbuy ha organizado un reembolso, sin embargo, el tiempo que tarda en llegar a su cuenta original depende del tiempo de procesamiento de la plataforma de terceros.",
  question3: 'P: ¿El retiro se basa en el tipo de cambio en tiempo real?',
  response3:
    'R: No, el tipo de cambio de retiro se basa en el momento en que se recargó el fondo.',
  question4:
    'P: ¿Cómo se retira cuando el saldo de la cuenta es diferente del monto de retiro?',
  response4:
    'R: cuando el saldo de la cuenta es mayor que la cantidad disponible para retiro, puede aplicar en fracciones hasta que el saldo de la cuenta sea 0. Cuando el saldo de la cuenta es menor que la cantidad disponible para retiro, puede solicitar un retiro completo de una sola vez.',
  stillRecharge: 'No es suficiente para pagar el pedido, aún necesita recargar',
  amountDue: 'Monto a pagar',
  handingFee: 'Tarifa de manejo',
  coupon: 'Cupón',
  couponPlaceholder: 'Use el Código del cupón para cambiar el cupón',
  off: 'DESCUENTO',
  minFee: 'Tarifa mínima',
  freightText: 'Flete',
  limitTo: 'Límite a',
  shippingUsage: 'Uso de envío',
  availableWarehouse: 'Almacén disponible',
  shippingFeeText: 'Tarifa de envío (excluye la tarifa de despacho aduanero)',
  internationalParcel: 'Paquete internacional',
  to: 'a',
  availableCoupons: 'Cupones disponibles',
  usedCoupons: 'Cupones usados',
  expiredCoupons: 'Cupones vencidos',
  exchangeSuccess: '¡Intercambio exitoso!',
  myAffiliate: {
    name: 'Mi afiliado'
  },
  affiliateShare: 'compartir',
  copyText: '复制',
  affiliateShareMsg:
    'Los pagos de flete a través de enlaces compartidos le generarán ingresos por Comisiones',
  businessType: 'Tipo de transacción',
  payWay: 'Tipo de transacción',
  bzOrderNo: 'Número AC',
  amountDueTooltip:
    'Puede haber diferencias de alrededor de 0,01 o 0,02 debido a los cálculos de conversión del tipo de cambio de la moneda',
  balanceStatement: 'Balance corriente',
  billFlow: 'Bill'
};
