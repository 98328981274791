export default {
  useMoreAddress: 'Usar outro endereço',
  hideAddress: 'Ocultar endereço',
  productInventory: 'Lista de produtos',
  tag: 'Etiqueta',
  retainTag: 'Manter etiqueta',
  delTag: 'Remover etiqueta',
  externalPack: 'Embalagem externa',
  packDetail: 'Detalhes da embalagem',
  postLine: 'Rota de envio',
  selectLine: 'Selecionar rota',
  editLine: 'Editar rota',
  service: 'Serviço',
  cancelServe: 'Cancelar serviço',
  previewPackage: 'Visualizar pacote',
  postCountry: 'País de envio',
  packageNo: 'Número do pacote',
  deliver: 'Enviar para',
  packageDetail: 'Detalhes do pacote',
  addressInfo: 'Informações do endereço',
  voWeight: 'Peso volumétrico',
  firstWeight: 'Peso inicial',
  continueWeight: 'Peso adicional',
  actWeight: 'Peso real',
  packageStatus: 'Status do pacote',
  freightDetail: 'Informação sobre os custos',
  declareCurrency: 'Moeda de declaração',
  selfDeclare: 'Declaração própria',
  packageDeclarePrice: 'O montante total da declaração de encomenda',
  youPackageProduct: 'Os produtos em seu pacote',
  editYourDeclare: 'Por favor, edite suas informações de declaração',
  startDeclare: 'Iniciar declaração',
  declareType: 'Tipo de declaração',
  productNo: 'Número do produto',
  declareProductPrice: 'Preço unitário declarado do produto',
  declareTotalPrice: 'Preço total declarado dos produtos',
  editDeclare: 'Editar declaração',
  declareLinePriceHint:
    'Valor mínimo de declaração para esta rota: {min} Valor máximo: {max}',
  classMaxNumHint: 'Quantidade máxima por categoria: {max}',
  addDeclareProduct: 'Adicionar produto declarado',
  plsConfirmInfo: 'Por favor, confirme as informações',
  wipePackProduct: 'Produtos sem embalagem',
  foldPackProduct: 'Produtos dobráveis',
  wipeTagProduct: 'Produtos sem etiqueta',
  aboutCalcWeightExplain:
    'Como o pacote atual não foi embalado, não sabemos o peso real do pacote, portanto, podemos apenas usar alguns parâmetros existentes para estimar o peso do pacote, para cobrar um depósito, e após o pacote ser embalado e obter o peso real, realizar o reembolso ou o acréscimo.',
  expressInfo: 'Informações de logística',
  deliveryMode: 'Modo de entrega',
  targetCountry: 'País de destino',
  cancelPackage: 'Cancelar pacote',
  cancelPackageHint:
    'Tem certeza de que deseja cancelar este pacote? Após o cancelamento, não será possível recuperá-lo.',
  isDelPackage: 'Tem certeza de que deseja excluir permanentemente o pacote?',
  delPackageHint:
    'Após a exclusão permanente, você não poderá visualizar o pacote novamente, por favor, proceda com cuidado!',
  receivedTips: 'Seu pacote foi recebido, bem-vindo a usar novamente',
  packageQuestion:
    'Ocorreu um problema no processo de envio do pacote, você pode',
  afterSale: 'Solicitar assistência pós-venda',
  repair: 'Pagamento adicional',
  ucan: 'Você pode',
  clickAdd: 'Clique aqui para adicionar',
  addCategoryTips: 'O nome do produto que você deseja declarar não está acima',
  noCarrierLines: 'Não há rotas de envio disponíveis',
  confirmPackageTitle: 'Você confirmou a recepção da mercadoria?',
  confirmPackageTips:
    'Para garantir seus direitos de assistência pós-venda, por favor, confirme a recepção após verificar a mercadoria sem erros',
  receiptSuccess: 'Recebido com sucesso',
  position: 'Posição',
  time: 'Tempo',
  duty: 'Número fiscal',
  serveStatusEnum: {
    1: 'Fotografia personalizada',
    2: 'Prolongar o período de guarda'
  },
  inputCareTime: 'Por favor, selecione o tempo de guarda',
  holder: {
    noInputSearch: 'Número do pacote/número do produto',
    inputEditDeclareInfo: 'Por favor, edite suas informações de declaração',
    packageRemark:
      'Se você tiver algum outro requisito especial para o pacote, por favor, anote aqui'
  },
  calcFee: 'Taxa estimada',
  realFee: 'Taxa real',
  declare: {
    repeatDeclare: 'Tipo de declaração repetida',
    addDeclareType: 'Por favor, adicione o tipo de declaração'
  },
  feeEnum: {
    totalAmount: 'Depósito estimado',
    deposit: 'Custo estimado',
    overLongFee: 'Custo por excesso de comprimento',
    overWeightFee: 'Custo por excesso de peso',
    remoteFee: 'Custo para regiões distantes',
    feeCustoms: 'Custo de processamento do operador logístico',
    feeFuel: 'Adicional de combustível',
    feeOperation: 'Custo de operação',
    feeService: 'Custo de serviço',
    valueAddedTax: 'Imposto sobre valor acrescentado',
    insuranceService: 'Custo do seguro',
    incrementAmount: 'Custo do serviço adicional',
    lineDiscount: 'Desconto da rota',
    discount: 'Desconto VIP',
    coupon: 'Selecionar um cupão',
    passwordDiscount: 'Código de desconto',
    orderPreDiscount: 'Desconto do produto',
    insuranceDiscount: 'Desconto do seguro',
    incrementDiscount: 'Desconto do serviço adicional',
    preVolumeCal: 'Peso estimado',
    actDeposit: 'Custo após pesar',
    actDepositAmount: 'Valor final a ser pago'
  },
  ratePaymentEnum: {
    name: 'Método de pagamento de impostos',
    0: 'Pagamento pelo destinatário',
    1: 'GST/VAT da plataforma',
    2: 'GST/VAT pessoal',
    3: 'IOSS da plataforma',
    4: 'IOSS pessoal',
    5: 'GST/VAT da empresa',
    6: 'GST/VAT do operador logístico',
    7: 'IOSS do operador logístico',
    8: 'Isento de impostos'
  },
  bagStatusEnum: {
    0: 'Pacote normal',
    1: 'Retorno nacional',
    2: 'Retorno internacional',
    3: 'Pacote cancelado',
    4: 'Pacote de pagamento adicional'
  },
  packageStatusEnum: {
    0: 'Pendente',
    1: 'Em processamento',
    2: 'Saiu do depósito',
    3: 'Saiu do depósito',
    4: 'Embalado',
    5: 'Peso verificado',
    6: 'Número de remessa impresso',
    7: 'Verificado',
    8: 'Entregue',
    9: 'Enviado',
    20: 'Recebido'
  },
  declareEnum: {
    name: 'Método de declaração',
    0: 'Declaração do Sistema',
    1: 'Decalaração Pessoal'
  },
  backText: 'Voltar',
  packageInformation: 'Informação da Embalagem',
  parcelNumber: 'Número da encomenda',
  noValidRule: 'Montante declarado não aprovado regras: {rule}',
  shippingAgree: 'Accord de transport de colis acbuy',
  haveDiscountCode: 'Eu tenho um código de desconto',
  inputDiscountCode: 'Digite o código de desconto aqui',
  expressNo: 'Número de rastreamento logístico'
};
