export default {
  selectSize: 'Por favor, selecione a especificação completa',
  inventory: 'Estoque',
  num: 'Quantidade',
  nimNumTips: 'A quantidade mínima para compra é de: {num}',
  addCart: 'Adicionar ao carrinho',
  nowBuy: 'Comprar agora',
  remark: 'Observação',
  goShop: 'Ir para a loja',
  productTotalPrice: 'Preço total do produto',
  productDetail: 'Detalhes do produto',
  sizeParams: 'Especificações de tamanho',
  shopNotes: 'Notas da loja de compras',
  saleService: 'Serviço pós-venda',
  productInfo: 'Informações do produto',
  buyTips:
    'Após o pagamento, realizaremos o serviço de compras por você durante 09:00-18:00 (UTC+8)',
  buyer: 'Vendedor',
  selfWarehouse: 'Armazém acbuy',
  warehouse: 'Armazém acbuy',
  yourAddress: 'Seu endereço',
  calcCountryFreight: 'Calcular frete internacional',
  holder: {
    remarkHolder:
      'Sua mensagem para o comprador, se não tiver requisitos especiais, pode não escrever; sua mensagem, o comprador decidirá se executar com base no vendedor e outras situações.'
  },
  supportPayment: 'Pagamento suportado',
  disclaimer:
    'Todos os produtos de compras exibidos no acbuy vêm de plataformas de compras de terceiros, não vendidos diretamente pelo acbuy. Portanto, o acbuy não assume qualquer responsabilidade e responsabilidade legal por questões relacionadas a violações de direitos autorais e direitos autorais dos produtos. Ao usar o serviço de compras do acbuy, você concorda implicitamente com os riscos acima.',
  readAgree: 'Eu li e concordo com o acima',
  selectAgree: 'Ainda não concordei com o aviso legal',
  copyLink: 'Copiar link',
  copySuccess: 'Link copiado com sucesso',
  disclaimer1: 'Aviso legal',
  editPrice: 'Modificar o preço',
  editPriceTips: 'Por favor, note que houve um problema com a troca de cupons. Recomendamos que os usuários alterem o preço de venda do item para o preço original.',
  editPriceEnum: {
    0: 'Frete com desconto',
    1: 'Erro de cotação na web',
    2: 'Negociar com o vendedor',
    3: 'Por outras razões',
  }
};
