import form from './es/form';
import home from './es/home';
import search from './es/search';
import cart from './es/cart';
import product from './es/product';
import order from './es/order';
import pecenter from './es/pecenter';
import pay from './es/pay';
import common from './es/common';
import estimation from './es/estimation';
import packages from './es/package';
import orderDetail from './es/orderDetail';
import warnTips from './es/warnTips';
import status from './es/status';
import tips from './es/tips';
import storage from './es/storage';
import uniOrder from './es/uniOrder'
import bankTransfer from './es/bankTransfer'
import distribution from './es/distribution'
import site from './es/site'
import consult from './es/consult'

export default {
  form,
  home,
  search,
  cart,
  product,
  order,
  pecenter,
  pay,
  common,
  estimation,
  packages,
  orderDetail,
  warnTips,
  status,
  tips,
  storage,
  uniOrder,
  bankTransfer,
  distribution,
  site,
  consult
};
