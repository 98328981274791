export default {
  bankTransfer: 'Transferencia bancaria',
  firstTitle: 'Por favor, seleccione la moneda de remesa',
  secondTitle: 'Después de la transferencia',
  bankTips:
    'Obtenga la información del beneficiario y vaya a la estación bancaria correspondiente para completar la orden de pago. Por favor, revise cuidadosamente su ortografía.',
  receiverTitle: 'Información del beneficiario',
  bankAttrEnum: {
    0: 'Número de cuenta',
    1: 'Número de ruta',
    2: 'Nombre de la cuenta',
    3: 'Nombre del banco',
    4: 'Dirección del banco',
    5: 'Código postal',
    6: 'País/Región',
    7: 'Tipo de cuenta',
    8: 'Observación'
  },
  transferTips:
    '¡Puede informarnos de cualquiera de las siguientes maneras y lo verificaremos lo antes posible!',
  transferName: 'Nombre del remitente',
  transferBank: 'Banco remitente',
  transferNo: 'Número de referencia',
  submit: 'Enviar ahora',
  successSubmit: 'Envío exitoso',
  successSubmitTips:
    '* Si ocurre algún problema durante la verificación, nos pondremos en contacto con su correo electrónico de registro de acbuy.',
  tradeDetail: 'Detalle de la transacción',
  backOrder: 'Volver al pedido',
  holder: {
    inputNo:
      'Por favor, proporcione el número de referencia si está disponible.'
  },
  transferExplain: 'Instrucciones de transferencia bancaria:',
  transferExplainEnum: {
    0: 'acbuy no cobra tarifas de manejo por este canal de pago. Si el banco remitente cobra tarifas depende de las políticas relevantes del banco correspondiente.',
    1: 'Este método de pago admite múltiples monedas. Los clientes pueden elegir la cuenta bancaria correspondiente para la remesa según su',
    2: 'Normalmente, tarda de 1 a 3 días hábiles en llegar; en circunstancias especiales, puede tardar de 1 a 5 días hábiles.',
    3: 'acbuy convertirá el',
    4: 'El monto final en RMB en la cuenta de acbuy = El monto de la remesa * La tasa de cambio de la plataforma de acbuy.'
  }
};
