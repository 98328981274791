export default {
  addShopSuccess: 'Article ajouté avec succès',
  addShopFail: "Échec de l'ajout de l'article",
  getCartDataFail: 'Échec de la récupération des articles du panier',
  hint: 'Conseil',
  delConfirmProduct: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  productInfo: 'Détails du produit',
  unitPrice: 'Prix unitaire',
  num: 'Quantité',
  subtotal: 'Sous-total',
  action: 'Action',
  productName: 'Nom du produit',
  splitProduct: 'Diviser le produit',
  split: 'Diviser',
  allSelect: 'Tout sélectionner',
  delProduct: 'Supprimer le produit',
  selected: 'Sélectionné',
  piece: 'Article(s)',
  total: 'Total',
  productTotal: 'Hors frais de livraison internationale',
  orderPay: 'Passer la commande',
  orderTotal: 'Total de la commande',
  goCartPay: 'Voir le panier',
  moreView: 'Plus d\'articles dans le panier',
  noSelectText: "Aucun produit n'a encore été sélectionné",
  paySuccess: 'Paiement réussi !',
  returnPayment: 'Retour pour continuer le paiement',
  service: 'Service',
    goodsMinNumErrTips: 'Cet article nécessite une quantité minimum d’achat de {num}. Vous pouvez choisir différentes spécifications en fonction de vos besoins.'
};