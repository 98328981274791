export default {
  bankTransfer: '银行转账',
  firstTitle: '请选择汇款币种',
  secondTitle: '转账后',
  bankTips:
    '获取收款人信息并前往相应的银行工作站填写汇款单。请仔细检查您的拼写。',
  receiverTitle: '收款人信息',
  bankAttrEnum: {
    0: '账号',
    1: '路由号码',
    2: '账户名称',
    3: '银行名称',
    4: '银行地址',
    5: '邮政编码',
    6: '国家/地区',
    7: '账户类型',
    8: '备注'
  },
  transferTips: '您可以通过以下任何方式通知我们，我们将尽快检查！',
  transferName: '汇款人姓名',
  transferBank: '汇款银行',
  transferNo: '参考号',
  submit: '立即提交',
  successSubmit: '提交成功',
  successSubmitTips:
    '* 如果在验证过程中出现任何问题，我们将联系您的acbuy注册邮箱。',
  tradeDetail: '交易详情',
  backOrder: '返回订单',
  holder: {
    inputNo: '如有参考号，请提供。'
  },
  transferExplain: '银行转账说明：',
  transferExplainEnum: {
    0: 'acbuy不对此支付渠道收取手续费。汇款银行是否收取费用取决于相应银行的相关政策。',
    1: '此支付方式支持多种货币。客户可以根据您的地区和需求选择相应的银行账户进行汇款。',
    2: '通常情况下，到账时间为1-3个工作日；特殊情况下，到账时间为1-5个工作日。',
    3: 'acbuy在收到汇款时将按照acbuy的汇率将金额转换为人民币，然后存入您的账户。',
    4: 'acbuy账户中的最终人民币金额 = 您的汇款金额 * acbuy平台汇率。'
  }
};
