export default {
  selectSize: 'Veuillez sélectionner les spécifications complètes',
  inventory: 'Stock',
  num: 'Quantité',
  nimNumTips: 'La quantité minimale pour commande est de {num}',
  addCart: 'Ajouter au panier',
  nowBuy: 'Acheter maintenant',
  remark: 'Remarque',
  goShop: 'Entrer dans le magasin',
  productTotalPrice: "Prix total de l'article",
  productDetail: "Détails de l'article",
  sizeParams: 'Spécifications et caractéristiques',
  shopNotes: "Notes de l'agent d'achat",
  saleService: 'Service après-vente',
  productInfo: "Informations sur l'article",
  buyTips:
    'Les commandes payées entre 09h00 et 18h00 (heure de Pékin) seront traitées dans les 6 heures (sauf le dimanche).',
  buyer: 'Vendeur',
  selfWarehouse: 'Entrepôt acbuy',
  warehouse: 'Entrepôt acbuy',
  yourAddress: 'Votre adresse',
  calcCountryFreight: "Estimation des frais d'expédition internationaux",
  holder: {
    remarkHolder:
      "Si vous avez d'autres besoins, veuillez laisser un message dans les remarques, l'agent vous aidera à vérifier."
  },
  supportPayment: 'Méthodes de paiement disponibles',
  disclaimer:
    "Tous les produits disponibles pour le service d'agent d'achat affichés sur acbuy proviennent de plateformes d'achat tierces et ne sont pas directement vendus par acbuy. Ainsi, acbuy n'assume aucune responsabilité légale pour les problèmes causés par la violation des droits de propriété intellectuelle et la violation des droits d'auteur des marchandises.",
  readAgree: "J'ai lu et j'accepte ce qui précède",
  selectAgree: 'Clause de non-responsabilité non encore acceptée',
  copyLink: 'Copier le lien',
  copySuccess: 'Copie réussie',
  disclaimer1: 'Clause de non-responsabilité',
  editPrice: 'Modifier le prix',
  editPriceTips: 'Veuillez noter qu’il y a eu un problème avec l’échange des coupons. Nous recommandons à l’utilisateur de modifier le prix de vente de l’article au prix original.',
  editPriceEnum: {
    0: 'Rabais sur fret',
    1: 'Erreur de devis web',
    2: 'Consultation avec le vendeur',
    3: 'Pour d’autres raisons',
  }
};
