export default {
  tipsOne: "1、我们会免费为您保管90天，超过90天仍未提交包裹运单或做未任何反馈将视为放弃商品，我们将统一对商品做销毁处理。",
  tipsTwo: "2、货品已经放入仓库，可以随时进入我的仓库，提交到您的海外收货地址",
  orderDetail: "订单详情",
  orderNo: "订单号",
  orderConsult: "订单咨询",
  consult: "咨询",
  orderConsultTips: "采购员只能处理本订单相关业务，其他业务咨询请联系",
  enquiryReplyMsg: "在这里输入，我们会在24小时内回复您。（注：有时我们会使用翻译软件进行回复，可能无法准确传达意思，希望能得到您的理解）",
  stepListEnum: {
    '-1': "勾选商品",
    0: "提交包裹",
    6: "支付运费",
    9: "寄送包裹",
    20: "确认收货" 
  }
}
