export default {
  storeTo: 'Almacén para enviar a',
  weightHaveUnit: 'Peso (g)',
  productType: 'Tipo de mercancía',
  long: 'Largo (cm)',
  width: 'Ancho (cm)',
  height: 'Alto (cm)',
  seeDetail: 'Ver detalles',
  queryTips:
    'Por favor, introduzca los parámetros correspondientes para consultar el costo del envío.',
  carrier: 'Transportista',
  carrierCode: 'Código del transportista',
  product: 'Producto',
  productCode: 'Código del producto',
  totalfee: 'Costo total',
  billingWeight: 'Peso de facturación',
  feeEstimation: 'Estimación de tarifas',
  postFeeEstimation: 'Estimación del costo del envío',
  postFee: 'Costo de envío',
  postTime: 'Tiempo estimado de envío',
  packUp: 'Contraer',
  startWeight: 'Peso inicial',
  endWeight: 'Peso final',
  calcExpression: 'Expresión de cálculo',
  baseFreight: 'Costo de envío básico',
  registrationFee: 'Tarifa de registro',
  noSend: 'Productos no disponibles',
  canSend: 'Productos disponibles',
  priceFrom: 'Composición de los costos',
  itemLimit: 'Límite de artículos',
  disReason: 'Razón de no disponibilidad',
  BasicFreight: 'Flete básico',
  OperationFreight: 'Cargo de operación',
  RegistrationFreight: 'Cargo por registro',
  IossFee: 'Cargo de IOSS',
  TariffFreight: 'Derechos de aduana',
  VATFregith: 'Impuesto al Valor Agregado (IVA)',
  AserviceFee: 'Cargo por servicio adicional',
  ExtraSizeFreight: 'Cargo adicional por tamaño extra',
  AdditionalDeliveryFeeForFemoteAreas:
    'Cargo adicional de entrega para áreas remotas',
  FuelSurcharge: 'Recargo por combustible',
  zoneCode: 'Zona ',
  amount: 'Precio ',
  rate: 'Tarifa ',
  conditionExpression: 'Cálculo ',
  feeFactor: 'Factor de tarifa ',
  continuedPrice: 'Precio por peso continuado ',
  continuedWeight: 'Peso continuado(g)',
  firstPrice: 'Precio por primer peso ',
  firstWeight: 'Primer peso(g)',
  weightRangeStart: 'Peso inicial(g)',
  weightRangeEnd: 'Peso final(g)',
  holder: {
    toCountry: 'Elegir destino',
    inputWeight: 'Por favor, introduzca el peso',
    selectCountry: 'Por favor, seleccione un país',
    selectCarrier:
      'Por favor, seleccione un transportista/línea de transportista/país de la ruta',
    selectCategory: 'Seleccione la restricción de correo'
  },
  typeDelivery: 'Tipo de entrega',
  shippingTime: 'Tiempo estimado de envío',
  deliveryTips: 'Consejos de entrega',
  estimatedShippingFee: 'Flete estimado',
  lineServiceTips:
    'Consejo: Todos los servicios logísticos para rutas que no son propiedad de acbuy son proporcionados por un proveedor de logística externo que ajustará los precios de flete de acuerdo con las condiciones del mercado. acbuy le informará con antelación de cualquier cambio de precio. Si tiene algún problema con el servicio de logística, acbuy puede comunicarse con un proveedor de logística externo en su nombre.',
  logisticsStatement: '<Declaración de servicios logísticos>'
};
