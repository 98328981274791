export default {
  fillInOrder: 'Preencher Pedido de Agente de Compras',
  inputTips:
    "(colunas marcadas com <span style='color: red'>*</span> são obrigatórias)",
  process: {
    first: 'Preencher o pedido e enviar',
    second: 'Pagar pelo produto e taxa de entrega doméstica',
    three: 'Aguardar a chegada do seu produto no armazém'
  },
  productInfo: 'Informações do Produto',
  productInfoTips:
    'Todos os produtos disponíveis para o serviço de agente de compras exibidos na acbuy são produtos obtidos de plataformas de compras de terceiros e não são vendidos diretamente pela acbuy. Assim, em caso de qualquer problema de violação envolvendo os produtos mencionados, todas as responsabilidades incorridas serão suportadas pelos vendedores correspondentes na plataforma de terceiros, enquanto a acbuy não assumirá qualquer responsabilidade relevante, colateral ou conjunta.',
  fees: 'Taxas',
  feeStandard: 'Padrões de Taxas',
  feeStandardTips:
    "Para o pedido de agente de compras que você envia nesta página, se o produto for de plataformas de terceiros como Kongfuzi Used Books, Xiaohongshu e Youzan, etc., existe a possibilidade de que o produto não possa ser comprado. O produto está associado a riscos de envio, ou uma taxa de serviço adicional pode ser cobrada. A situação real depende do feedback de nossa equipe. Para mais detalhes, por favor, acesse 'Central de Ajuda - Padrões de Taxas de Serviço de Agente de Compras de Terceiros'.",
  link: 'Link',
  name: 'Nome',
  remark: 'Observação',
  unitPrice: 'Preço Unitário (CNY)',
  qty: 'Qtd',
  goodsAttr: 'Especificação dos produtos',
  chinaPostage: 'Taxa de envio (CNY)',
  holder: {
    inputUrl: 'Por favor, cole a URL do produto',
    inputName:
      'Por favor, insira o nome do produto aqui (Dentro de 100 caracteres)',
    inputAttr: 'Por favor, insira a especificação dos produtos',
    inputRemark:
      'Se o item tiver vários padrões, tamanhos, cores, etc., por favor, especifique o que você precisa aqui com base na descrição de classificação na página do produto. Se você comprar o mesmo produto, mas com especificações diferentes, por favor, separe o pedido.'
  },
  other: 'Outro (opcional)',
  productImg: 'Imagens do Produto',
  fareTip:
    'Preencha a taxa de envio cobrada a partir do site para selected warehouse.'
};
