export default {
  selectSize: 'Por favor, seleccione la especificación completa',
  inventory: 'Inventario',
  num: 'Cantidad',
  nimNumTips: 'La cantidad mínima para pedidos es: {num}',
  addCart: 'Añadir al carrito',
  nowBuy: 'Comprar ahora',
  remark: 'Comentario',
  goShop: 'Ir a la tienda',
  productTotalPrice: 'Precio total del producto',
  productDetail: 'Detalles del producto',
  sizeParams: 'Parámetros de tamaño',
  shopNotes: 'Notas de la tienda de compras',
  saleService: 'Servicio posventa',
  productInfo: 'Información del producto',
  buyTips:
    'Después del pago, realizaremos el servicio de compra por encargo para usted de 09:00 a 18:00 (UTC+8)',
  buyer: 'Vendedor',
  selfWarehouse: 'Almacén de acbuy',
  warehouse: 'Almacén de acbuy',
  yourAddress: 'Su dirección',
  calcCountryFreight: 'Calcular el costo del envío internacional',
  holder: {
    remarkHolder:
      'Los comentarios que deje para el agente de compras, si no tiene requisitos especiales, puede no escribirse; sus comentarios, el agente de compras los tomará en cuenta y decidirá si ejecutarlos o no en función del vendedor y otras situaciones.'
  },
  supportPayment: 'Métodos de pago soportados',
  disclaimer:
    'Todos los productos de compra por encargo mostrados en acbuy provienen de la plataforma de terceros de compras por encargo, no son vendidos directamente por acbuy. Por lo tanto, acbuy no asume ninguna responsabilidad ni responsabilidad legal por problemas causados por la infracción de derechos de propiedad intelectual y derechos de autor del producto. El uso del servicio de compras por encargo de acbuy significa que usted acepta implícitamente los riesgos mencionados.',
  readAgree: 'He leído y estoy de acuerdo con lo anterior',
  selectAgree: 'Todavía no estoy de acuerdo con el aviso legal',
  copyLink: 'Copiar enlace',
  copySuccess: 'Enlace copiado con éxito',
  disclaimer1: 'Aviso legal',
  editPrice: 'Modificar el precio',
  editPriceTips: 'Tenga en cuenta que hay un problema con el canjeo de cupones. Recomendamos a los usuarios que revisen el precio de venta del artículo al precio original.',
  editPriceEnum: {
    0: 'Tarifas de descuento',
    1: 'Error de cotización web',
    2: 'Consulta con el vendedor',
    3: 'Por otras razones',
  }
};
