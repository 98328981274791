export default {
  lang: 'Idioma',
  currency: 'Moeda',
  validGoodUrlFail: 'Este não é um link válido do produto',
  product: 'Produto',
  productHolder: 'Digite o nome/link do produto',
  shop: 'Loja',
  shopHolder: 'Digite o nome/link da loja',
  purchaseAgent: 'Agente de compras',
  transport: 'Transporte',
  manualOrdering: 'Pedido manual',
  guideBook: 'Guia',
  help: 'Ajuda',
  tools: 'Ferramentas',
  customerService: 'Atendimento ao cliente',
  purchaseGuide: 'Guia de compra',
  payment: 'Pagamento',
  delivery: 'Entrega',
  afterSaleService: 'Serviço pós-venda',
  insuranceCompensation: 'Seguro e compensação',
  contactInfo: 'Informações de contato',
  enterEmail: 'Digite seu e-mail',
  copyRight: 'Copyright©acbuy.cn',
  aboutacbuy: 'Sobre a acbuy',
  contctTime: '9/7 (09:00-18:00, horário de Pequim)',
  contactEmail: "Atendimento ao cliente: Support{'@'}acbuy.com",
  contactMarket: "Marketing: Marketing{'@'}acbuy.com",
  contactCooperation: "Cooperação comercial: Business{'@'}acbuy.com",
  tutorial: 'Tutorial',
  serviceFee: 'Taxa de serviço',
  orderStatus: 'Guia do Recém-chegado',
  returnRefunds: 'Devoluções e reembolsos',
  topUp: 'Recarregar',
  paypal: 'PayPal',
  internationalCreditCard: 'Cartão de crédito internacional',
  telegraphicTransfer: 'Transferência bancária',
  charges: 'Taxas',
  mailRestrictions: 'Restrições de envio',
  customsTaxation: 'Alfândega e impostos',
  receiptInformation: 'Informações de recebimento',
  helpCenter: 'Central de ajuda',
  contactUs: 'Contate-nos',
  registerSuccess: 'Registro bem-sucedido',
  registerMsg:
    'Enviamos um e-mail de verificação para seu endereço. Complete a verificação rapidamente para não afetar o uso de seus pontos.',
  continueShopping: 'Continuar comprando',
  continueLogin: 'Continuar login',
  verifyQuestion: 'Não recebeu o e-mail de verificação?',
  verifyResopnseOne:
    '· O e-mail de verificação acbuy pode ser considerado spam, verifique sua pasta de spam',
  verifyResopnseTwo:
    '· O envio pode atrasar alguns minutos, por favor seja paciente',
  verifyResopnseThird:
    '· O e-mail de verificação pode atrasar. Se não receber após muito tempo, verifique se foi marcado como spam ou reenvie o e-mail de verificação',
  agentGuide: 'Guia para compradores novos',
  guideBookbtn: 'Manual para iniciantes',
  everyonePro:
    'Todos podem ser promotores, as comissões por pacotes duplos foram melhoradas',
  joinAffiliate: 'Juntar-se ao programa de afiliados',
  joinacbuy: 'Junte-se ao acbuy Discord para mais benefícios',
  joinDiscord: 'Juntar-se ao Discord',
  productPpurchase: 'Compra de produtos',
  shippingToWarehouse: 'Envio para armazém',
  qualityAssurance: 'Garantia de qualidade',
  moreCostEffective: 'Mais econômico',
  productPpurchaseContent:
    'Compra de produtos - Você pode facilmente comprar qualquer produto da China. Basta colar o link do produto no site acbuy e fazer o pedido, acbuy comprará para você',
  shippingToWarehouseContent:
    'Envio para armazém – Compre de diferentes vendedores através do nosso serviço, consolidaremos eficientemente seus pedidos. Todos os produtos serão enviados para o armazém acbuy, simplificaremos o processo para sua conveniência.',
  qualityAssuranceContent:
    'Garantia de qualidade - Quando seus produtos chegam ao armazém acbuy, realizamos uma inspeção completa de qualidade e enviamos os comentários da inspeção para uma melhor experiência.',
  moreCostEffectiveContent:
    'Economize em envio consolidado - Em seu armazém, você pode selecionar livremente produtos e enviá-los em um único pacote. Use o serviço de envio mundial acbuy para enviar seus produtos para qualquer lugar, desfrute de um envio mundial mais simples e confiável.',
  introduction: 'Comprar como um milionário',
  reviewOn: 'Comentários sobre',
  productStoragePeriod: 'Período de armazenamento do produto',
  inspectionRules: 'Regras de inspecção',
  afterSalesPolicy: 'Política pós-venda',
  insuranceAndCompensation: 'Seguros e Compensações',
  oneStopService: 'Serviço único',
  whatCustomersSay: 'o que dizem os nossos clientes',
  customerSay1:
    'acbuy é o melhor agente de compras que eu já usei - é a minha primeira vez para usar acbuy e seus produtos são mais baratos do que qualquer outro agente de compras, o que é realmente incrível!',
  customerSay2:
    'Depois de ser um promotor de acbuy, recebi um cashback de 300 yuan. Depois disso, eu vou obter nada menos do que 4% cashback em cada ordem',
  customerSay3:
    'Uma experiência de compra muito agradável - usei acbuy para comprar da China com produtos de boa qualidade, excelente serviço ao cliente e transporte rápido.',
  customerSay4:
    'O melhor agente - a compra foi concluída no mesmo dia. Demorou apenas 7 dias para enviar o pacote para minha casa. Este é o melhor agente atualmente',
  customerFirst: 'Cliente Primeiro, Serviço Premium',
  customerServiceText:
    '9/7 Serviço ao Cliente Online (09:00-18:00, horário Bj)',
  faqCategory: 'CATEGORIAS DE FAQ'
};
