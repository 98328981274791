export default {
  agreeTitle:
    'Acordo sobre os serviços de marketing através do "acbuy Affiliate" no site acbuy',
  agreementText: `Bem-vindo ao registro no site acbuy e ao uso de seus serviços de marketing "acbuy Affiliate". Este Acordo é celebrado entre os parceiros do "acbuy Affiliate" (doravante denominado "Parte B") e acbuy (doravante denominado "Parte A") (incluindo, mas não se limitando a www.acbuy.com, aplicativo móvel acbuy, etc., doravante denominado "Site da Parte A") em relação às questões relacionadas aos serviços de marketing do "acbuy Affiliate". A Parte B deve ler atentamente todos os textos deste documento, especialmente as partes em negrito, antes de usar os serviços de marketing do "acbuy Affiliate" no site acbuy, e ao clicar no botão "Li e concordo", considera-se que a Parte B aceitou e concordou em cumprir as especificações deste documento. O Acordo constituirá o acordo entre a Parte B e acbuy e terá força legal. A acbuy terá o direito de alterar unilateralmente os artigos deste documento com base em suas necessidades de negócios, e a acbuy publicará tais alterações no Site da Parte A imediatamente em caso de quaisquer alterações feitas nos artigos deste documento, e a Parte B deverá parar imediatamente de usar os serviços de marketing no canal "acbuy Affiliate" caso a Parte B discorde das alterações nos textos deste documento, ou caso contrário, a Parte B será considerada como tendo concordado com as alterações nos textos deste documento.
Artigo 1 Definições 1. Consumidor: significa o membro acbuy que acessa o Site da Parte A para registro`,
  dashboard: 'Painel',
  hi: 'Olá, ',
  inviteFriends: 'Convidar amigos',
  inviteFriendsTips:
    'Convide amigos para o acbuy, ganhe mais dinheiro! Convide mais, ganhe mais, sem limite.',
  totalInvite: 'Total de usuários convidados',
  myTeam: 'Minha equipe',
  extractMoney: 'Comissão liquidada',
  noExtractMoney: 'Comissão não liquidada',
  inviteTitle: 'Convidar meus amigos',
  copyCode: 'Copiar código de convite',
  poster: 'Pôster exclusivo',
  myLevel: 'Minha prerrogativa',
  youLevel: 'Seu nível atual',
  commissionRate: 'Taxa de comissão',
  experienceValue: 'Valor de experiência ({num}/{total})',
  levelDesc: 'Descrição do nível',
  invite: 'Convidar',
  revenueDetails: 'Detalhes do convite',
  registerTime: 'Hora de registro',
  inviteType: 'Relação de convite',
  userMail: 'E-mail do usuário',
  bonus: 'Bônus',
  myBonus: 'Meu bônus',
  myBalance: 'Meu saldo',
  applyWithdraw: 'Solicitar saque',
  withdrawTips:
    'Dica: Devido à taxa de transação envolvida no saque, é recomendado sacar um valor superior a ¥500,00',
  bank: 'Banco',
  paypalAccount: 'Conta PayPal',
  bankAccount: 'Conta bancária',
  bankName: 'Nome do banco',
  withdrawAmount: 'Valor do saque ({unit})',
  bonusDetail: 'Detalhe do bônus',
  directCommission: 'Detalhes da comissão direta',
  indirectCommission: 'Detalhes da comissão indireta',
  tradeTime: 'Hora da transação',
  packNo: 'Número do pacote',
  actCommission: 'Comissão real',
  settlementTime: 'Hora de liquidação',
  levelName: 'Nível de embaixador',
  packStatus: 'Status do pacote',
  directExplain: 'Comissão sobre as taxas de envio',
  indirectExplain: 'Comissão sobre as taxas de envio de convidados secundários',
  experienceExplain: 'Pontos de experiência necessários',
  withdrawRecord: 'Registro de saques',
  withdrawTime: 'Hora do saque',
  serialNo: 'Número de série',
  withdrawStatus: 'Status do saque',
  team: 'Equipe',
  levelUp: 'Subir de nível',
  teamInfo: 'Informações da equipe',
  teamNum: 'Número de usuários da equipe',
  monthCommission: 'Comissão estimada para este mês',
  accCommission: 'Comissão acumulada liquidada',
  teamMember: 'Membro da equipe',
  promotedMember: 'Membro promovido',
  invitationTime: 'Hora do convite',
  inviter: 'Convidador',
  promotionTime: 'Hora da promoção',
  IBAN: 'IBAN',
  swiftCode: 'Código Swift',
  bankAddress: 'Endereço do banco',
  setting: 'Configurações',
  profile: 'Perfil',
  withdrawMethod: 'Método de saque',
  myPrerogative: 'Minha prerrogativa',
  verifyEmail: 'Verifique seu e-mail',
  sendCodeTips:
    'Enviamos um código de verificação para <strong>{email}</strong>. Digite o código abaixo para verificar.',
  noGetEmail: 'Não recebeu o e-mail?',
  codeErrorTips: 'Erro de código, por favor tente novamente',
  resendTime: 'Reenviar o código ({time}s)',
  or: 'ou',
  contactService: 'Contatar o serviço de atendimento online',
  addMethod: 'Novo método de saque',
  editMethod: 'Editar métodos de retirada',
  rePaypalAccount: 'Inserir novamente a conta PayPal',
  directInvite: 'Convite direto para',
  indirectInvite: 'Um convite indireto',
  noSameAccount: 'A conta e a confirmação da conta são inconsistentes',
  noAddWithdrawMethod: 'Você não adicionou um método de retirada',
  statusEnum: {
    0: 'Para ser emitido',
    1: 'Em emissão',
    2: 'Emitido com sucesso',
    3: 'Falha na distribuição',
    4: 'Revogação de retirada'
  },
    userDetail: 'Detalhes para usuários'
};
