export default {
  agreeTitle:
    'Agreement On The Marketing Services Through The “acbuy Affiliate” On acbuy Website',
  agreementText: `Welcome to register on acbuy website and use its marketing services of “acbuy Affiliate”. This Agreement is entered in by and between the partners of “acbuy Affiliate” (hereinafter referred to as “Party B”) and acbuy (hereinafter referred to as “Party A”) (including but not limited to www.acbuy.com, cell phone APP of acbuy and etc., hereinafter referred to as “Party A's Website”) regarding the issues related to the marketing services of “acbuy Affiliate”. Party B shall read carefully all the texts hereof, especially the parts in bold, before using the marketing services of “acbuy Affiliate” on acbuy website, and by clicking the “I have read it and I agree” button, Party B is deemed to have accepted and agreed to comply with the specifications hereof. The Agreement shall constitute the agreement between Party B and acbuy and have legal force. acbuy shall have the right to amend the articles hereof unilaterally based on its business needs, and acbuy shall post such amendments on the Party A's Website immediately in case of any amendments made to the articles hereof, and Party B shall immediately stop using the marketing services on the channel of “acbuy Affiliate” in case Party B disagrees to the amendments to the texts hereof, or otherwise, Party B shall be deemed to agree to the amendments to the texts hereof.
Article 1 Definitions 1. Consumer: means acbuy member who accesses to Party A's Website for registration `,
  dashboard: 'Dashboard',
  hi: 'Hi, ',
  inviteFriends: 'Invite Friends',
  inviteFriendsTips:
    'Invite friends to acbuy, earn more cash! Invite more, earn more, no limit.',
  totalInvite: 'Total Invited  Users',
  myTeam: 'My Team',
  extractMoney: 'Commission Settled',
  noExtractMoney: 'Unsettled Commission',
  inviteTitle: 'Invite My Friends',
  copyCode: 'Copy Invitation Code',
  poster: 'Exclusive Poster',
  myLevel: 'My Prerogative ',
  youLevel: 'Your Current Level',
  commissionRate: 'Commission Rate',
  experienceValue: 'Experience Value({num}/{total})',
  levelDesc: 'Level Description',
  invite: 'Invite',
  revenueDetails: 'Invitation Details',
  registerTime: 'Registration Time',
  inviteType: 'Invitation Relationship',
  userMail: 'Username Mail',
  bonus: 'Bonus',
  myBonus: 'My Bonus',
  myBalance: 'My balance',
  applyWithdraw: 'Apply For Withdrawal',
  withdrawTips:
    'Tip: Due to the transaction fee involved in withdrawal, it is recommended to withdraw an amount higher than ¥500.00 ',
  bank: 'Bank',
  paypalAccount: 'PayPal Account',
  bankAccount: 'Bank Account',
  bankName: 'Bank Name',
  withdrawAmount: 'Withdraw Amount({unit})',
  bonusDetail: 'Bonus Detail',
  directCommission: 'Direct Commission Details',
  indirectCommission: 'Indirect Commission Details',
  tradeTime: 'Transaction Time',
  packNo: 'Package Number',
  actCommission: 'Actual Commission',
  settlementTime: 'Settlement Time',
  levelName: 'Ambassador Level',
  packStatus: 'Package Status',
  directExplain: 'Commission On The Shipping Fees',
  indirectExplain: `Commission On Secondary Invitees'S Shipping Fees`,
  experienceExplain: 'Required Experience Points',
  withdrawRecord: 'Withdraw Record',
  withdrawTime: 'Withdrawal Time',
  serialNo: 'Serial No.',
  withdrawStatus: 'Withdraw Status',
  team: 'Team',
  levelUp: 'Level Up',
  teamInfo: 'Team Information',
  teamNum: 'Number of team users',
  monthCommission: 'Estimated commission for this month',
  accCommission: 'Accumulated settlement commission',
  teamMember: 'Team Member',
  promotedMember: 'Promoted Member',
  invitationTime: 'Invitation Time',
  inviter: 'Inviter',
  promotionTime: 'Promotion Time',
  IBAN: 'IBAN',
  swiftCode: 'Swift code',
  bankAddress: 'Bank address',
  setting: 'Setting',
  profile: 'Profile',
  withdrawMethod: 'Withdraw Method',
  myPrerogative: 'My Prerogative',
  verifyEmail: 'Verify Your Email',
  sendCodeTips:
    'We sent a verify code to <strong>{email}</strong>. Enter the code below to verify.',
  noGetEmail: `Didn't get your email?`,
  codeErrorTips: 'Code error, please try again',
  resendTime: 'Resend the code ({time}s)',
  or: 'or',
  contactService: 'Contact online customer service',
  addMethod: 'New Withdrawal Method',
  editMethod: 'Edit Withdrawal Method',
  rePaypalAccount: 'Re-Enter PayPal Account',
  directInvite: 'Direct Invitation',
  indirectInvite: 'Indirect Invitation',
  noSameAccount: 'The account is different from the confirmed account',
  noAddWithdrawMethod: `You haven't added a Withdraw method`,
  statusEnum: {
    0: 'To be issued',
    1: 'Under release',
    2: 'Release success',
    3: 'Provisioning failure',
    4: 'Withdrawal of withdrawal'
  },
  userDetail: 'User Detail'
};
