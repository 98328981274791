export default {
  storeTo: '仓库寄往',
  weightHaveUnit: '重量 (g)',
  productType: '商品类型',
  long: '长 (cm)',
  width: '宽 (cm)',
  height: '高 (cm)',
  seeDetail: '查看详情',
  queryTips: '请输入对应参数，查询运费！',
  carrier: '承运商',
  carrierCode: '承运商代码',
  product: '产品',
  productCode: '产品代码',
  totalfee: '总费用',
  billingWeight: '计费重量',
  feeEstimation: '费用预估',
  postFeeEstimation: '运费估算',
  postFee: '运输费用',
  postTime: '运输时效',
  packUp: '收起',
  startWeight: '开始重量',
  endWeight: '结束重量',
  calcExpression: '计算表达式',
  baseFreight: '基础运费',
  registrationFee: '挂号费',
  noSend: '不可寄送',
  canSend: '可寄送',
  priceFrom: '价格组成',
  itemLimit: '产品限制',
  disReason: '禁用原因',
  BasicFreight: '基本运费',
  OperationFreight: '操作费',
  RegistrationFreight: '挂号费',
  IossFee: 'IOSS税费',
  TariffFreight: '关税',
  VATFregith: '增值税',
  AserviceFee: '附加服务费',
  ExtraSizeFreight: '超尺寸附加费',
  AdditionalDeliveryFeeForFemoteAreas: '偏远地区派送附加费',
  FuelSurcharge: '燃油附加费',
  zoneCode: '区域 ',
  amount: '价格 ',
  rate: '费率 ',
  conditionExpression: '计算表达式 ',
  feeFactor: '费用因素',
  continuedPrice: '续重价格',
  continuedWeight: '续重重量(g)',
  firstPrice: '首重价格',
  firstWeight: '首重重量(g)',
  weightRangeStart: '起始重量(g)',
  weightRangeEnd: '结束重量(g)',
  holder: {
    toCountry: '选择目的地',
    inputWeight: '请输入重量',
    selectCountry: '请选择国家',
    selectCarrier: '请选择承运商/承运商线路/路线国家',
    selectCategory: '选择邮寄限制'
  },
  typeDelivery: '交货类型',
  shippingTime: '预计发货时间',
  deliveryTips: '交货提示',
  estimatedShippingFee: '预计运费',
  lineServiceTips:
    '提示: 非acbuy自营航线的所有物流服务均由第三方物流提供商提供，第三方物流提供商将根据市场情况调整运费价格。如有价格变动，acbuy会提前通知您。如果您在物流服务方面遇到任何问题，acbuy可以代您与第三方物流提供商沟通。',
  logisticsStatement: '<物流服务声明>'
};
