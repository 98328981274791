export default {
  tipsOne:
    '1. We will keep the goods for you free of charge for 90 days. If you not submit the package or take any action after 90 days, it will be considered as giving up the goods, and we will destroy them uniformly.',
  tipsTwo:
    '2. The goods have been put into the warehouse, you can enter my warehouse at any time, and submit to your overseas delivery address',
  orderDetail: 'Order Detail',
  orderNo: 'Order No.',
  orderConsult: 'Order Consultation',
  consult: 'Inquiry',
  orderConsultTips:
    'The purchasing agent can only handle business related to this order. For other business inquiries, please contact',
  enquiryReplyMsg:
    'Enter here, we will reply within 24 hours. (Note: Sometimes we will use translation software to reply, which may not accurately convey the meaning, we hope for your understanding)',
  stepListEnum: {
    '-1': 'Select products',
    0: 'Submit parcel',
    6: 'Pay freight',
    9: 'Send parcel',
    20: 'Confirm receipt'
  }
};
