export default {
  addShopSuccess: '添加商品成功',
  addShopFail: '添加商品失败',
  getCartDataFail: '获取购物车商品失败',
  hint: '提示',
  delConfirmProduct: '确定要删除商品？',
  confirm: '确认',
  cancel: '取消',
  productInfo: '商品详情',
  unitPrice: '单价',
  num: '数量',
  subtotal: '小计',
  action: '操作',
  productName: '商品名称',
  splitProduct: '拆分商品',
  split: '拆分',
  allSelect: '全选',
  delProduct: '删除商品',
  selected: '已选择',
  piece: '件',
  total: '总计',
  productTotal: '不包括国际配送费',
  orderPay: '下单结算',
  orderTotal: '订单总计',
  goCartPay: '前往购物车结算',
  moreView: '更多购物车',
  noSelectText: '还没选择商品',
  paySuccess: '支付成功！',
  returnPayment: '返回继续支付',
  service: '服务',
  goodsMinNumErrTips: '此商品需要最少采购数量{num}，您可以根据需要选择不同的规格。'
};
