export default {
  fillInOrder: 'Rellenar Pedido de Agente de Compras',
  inputTips:
    "(las columnas marcadas con <span style='color: red'>*</span> son obligatorias)",
  process: {
    first: 'Rellenar el pedido y enviarlo',
    second: 'Pagar el producto y la tarifa de envío nacional',
    three: 'Esperar a que su producto llegue al almacén'
  },
  productInfo: 'Información del Producto',
  productInfoTips:
    'Todos los productos disponibles para el servicio de agente de compras mostrados en acbuy son productos obtenidos de plataformas de compras de terceros y no son vendidos directamente por acbuy. Por lo tanto, en caso de cualquier problema de infracción relacionado con los productos mencionados, todas las responsabilidades incurridas serán asumidas por los vendedores correspondientes en la plataforma de terceros, mientras que acbuy no asumirá ninguna responsabilidad relevante, colateral o conjunta.',
  fees: 'Tarifas',
  feeStandard: 'Estándares de Tarifas',
  feeStandardTips:
    "Para el pedido de agente de compras que envíe en esta página, si el producto proviene de plataformas de terceros como Kongfuzi Used Books, Xiaohongshu y Youzan, etc., existe la posibilidad de que el producto no pueda ser comprado. El producto está asociado con riesgos de envío, o se podría cobrar una tarifa de servicio adicional. La situación real depende de la retroalimentación de nuestro personal. Para más detalles, por favor vaya a 'Centro de Ayuda - Estándares de Tarifas del Servicio de Agente de Compras de Terceros'.",
  link: 'Enlace',
  name: 'Nombre',
  remark: 'Observación',
  unitPrice: 'Precio Unitario (CNY)',
  qty: 'Cantidad',
  goodsAttr: 'Especificación de los productos',
  chinaPostage: 'Flete (CNY)',
  holder: {
    inputUrl: 'Por favor, pegue la URL del producto',
    inputName:
      'Por favor, ingrese el nombre del producto aquí (Dentro de 100 caracteres)',
    inputAttr: 'Por favor, ingrese la especificación de los productos',
    inputRemark:
      'Si el artículo tiene varios patrones, tamaños, colores, etc., por favor especifique lo que necesita aquí basándose en la descripción de clasificación en la página del producto. Si compra el mismo producto pero con diferentes especificaciones, por favor separe el pedido.'
  },
  other: 'Otro (opcional)',
  productImg: 'Imágenes del Producto',
  fareTip: 'Rellene el flete cobrado del sitio web al almacén seleccionado.'
};
