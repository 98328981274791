export default {
  noData: '暂无更多数据',
  delConfirmTips: '确定要删除选中数据?',
  delSuccess: '删除成功',
  tips: '提示',
  add: '添加',
  edit: '修改',
  del: '删除',
  editSuccess: '修改成功',
  activeAccount: '激活账号',
  mailActiveSuccess: '账号激活成功',
  back: '返回',
  goLogin: '前往登录',
  email: '邮箱',
  emailSendSuccess: '邮件发送成功',
  paySuccess: '支付成功',
  action: '操作',
  logoutSuccess: '注销成功',
  plsUpload: '请上传',
  upload: '上传',
  file: '文件',
  uploadFail: '上传失败',
  uploadSuccess: '上传成功',
  pay: '支付',
  day: '日',
  servePrice: '服务费',
  skillServePrice: '技术服务费',
  unitMonth: '个月',
  num: '数量',
  photo: '照片',
  product: '商品',
  sizeHaveUnit: '尺寸(cm)',
  weightHaveUnit: '重量(g)',
  fee: '收费',
  free: '免费',
  submitTime: '提交时间',
  weight: '重量',
  cancelReason: '取消原因',
  submitPackage: '提交包裹',
  submitSuccess: '提交成功',
  price: '价格',
  status: '状态',
  noProduct: '暂无商品',
  backEdit: '返回修改',
  errorHint: '接口异常,请再次请求',
  reload: '刷新',
  reloadPageTips: '您的帐户已切换。请重新加载页面以获取更新的信息。',
  cancel: '取消',
  noMsg: '没有信息',
  totalAmount: '总价',
  see: '查看',
  confirm: '确认',
  send: '发送',
  nowNoInfo: '暂时没有信息',
  genderEnum: {
    0: '男',
    1: '女'
  },
  goEmailActiveTips: '请前往邮箱激活账号!',
  holder: {
    inputRemark: '请输入备注内容',
    inputExplainCancelReason: '请说明取消原因',
    inputAddAddress: '请添加收货地址'
  },
  acceptMsg:
    '本网站在您的计算机上存储Cookie，为您提供更好的体验。有关更多信息，请参阅您的隐私政策',
  accept: '接受',
  refuse: '拒绝',
  notice: '注意',
  noticeMsg: '邀请3名活跃用户，可获300元现金充值',
  english: '英语',
  spanish: '西班牙语',
  french: '法语',
  chinese: '中文(简体)',
  copySuccess: '复制成功',
  copyError: '复制失败',
  noAgainShow: '不再显示',
  download: '下载',
  downloadSuccess: '下载成功',
  uploadImgTips: '请上传jpg/png/webp/JPEG文件',
  noCheckAboutAgree: '未勾选同意相关协议'
};
