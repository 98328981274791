export default {
  addShopSuccess: 'Item added successfully',
  addShopFail: 'Failed to add item',
  getCartDataFail: 'Failed to get shopping cart items',
  hint: 'Tip',
  delConfirmProduct: 'Are you sure you want to delete this product?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  productInfo: 'Product Details',
  unitPrice: 'Unit Price',
  num: 'Qty',
  subtotal: 'Subtotal',
  action: 'Action',
  productName: 'Product Name',
  splitProduct: 'Split Product',
  split: 'Split',
  allSelect: 'Select All',
  delProduct: 'Remove Selected',
  selected: 'Selected',
  piece: 'Item(s)',
  total: 'Total',
  productTotal: 'Excl. International Delivery Fee',
  orderPay: 'Checkout',
  orderTotal: 'Order Total',
  goCartPay: 'View Cart',
  moreView: 'More Cart Items',
  noSelectText: "Haven't selected any products yet",
  paySuccess: 'Payment Successful!',
  returnPayment: 'Return to continue payment',
  service: 'Service',
    goodsMinNumErrTips: 'This product requires a minimum purchase quantity of {num}, you can choose different specifications according to your needs.'
};
