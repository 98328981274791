export default {
  goodsStatusEnum: {
    '-1': 'Aguardando pagamento',
    0: 'Em revisão',
    1: 'Pendente de confirmação',
    2: 'Aguardando pagamento adicional',
    3: 'Devolução de produto',
    4: 'Troca de produto',
    5: 'Enviado',
    6: 'Envio separado',
    7: 'Processamento adiado',
    8: 'Recebido',
    9: 'Em estoque',
    10: 'Armazenado',
    11: 'Pedido cancelado',
    12: 'Produto destruído',
    13: 'Pedido vencido',
    14: 'Confirmação de informação',
    15: 'Confirmação de compra',
    16: 'Devolução pendente',
    17: 'Troca pendente',
    18: 'Processando devolução',
    19: 'Processando troca',
    20: 'Devolução concluída',
    21: 'Troca concluída',
    22: 'Pedido realizado',
    23: 'Em processamento',
    24: 'Cancelar pedido',
    25: 'Pedido anormal',
    26: 'Devolução aguardando reembolso',
    27: 'Troca aguardando envio do vendedor',
    28: 'Saída do armazém',
    29: 'Embalado e pronto',
    30: 'Enviado para o exterior',
    31: 'Produto recebido',
    32: 'Pagamento recusado',
    33: 'Auditoria de controle de risco',
    34: 'Lista de cancelamentos',
    35: 'Lista de pedidos congelados',
    36: 'Pagamento de pré-assinatura',
    37: 'Aguardando envio',
    38: 'Reembolso congelado',
    39: 'Produto rejeitado',
    40: 'Rejeição concluída',
    41: 'Exceção não em estoque',
    42: 'Exceção em estoque',
    43: 'Cancelar devolução',
    44: 'Cancelar troca',
    45: 'Assinado e pendente',
    46: 'Panamá aguardando geração',
    47: 'Panamá aguardando pagamento',
    48: 'Pagamento concluído',
    49: 'Produto abandonado',
    50: 'Conclusão obrigatória',
    51: 'Aguardando aprovação do vendedor para devolução',
    52: 'Aguardando aprovação do vendedor para troca',
    53: 'Pacote devolvido inválido',
    54: '1688 aguardando geração',
    55: '1688 aguardando pagamento',
    56: 'Yino aguardando geração',
    57: 'Yino aguardando pagamento',
    58: 'Produtos em transferência'
  },
  appendStatusEnum: {
    0: 'Pendente',
    1: 'Em processamento',
    2: 'Cancelado',
    3: 'Concluído',
    4: 'Devolvido'
  }
};
