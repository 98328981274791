import Decimal from 'decimal.js';

import { getLang } from './getLanguage';
import { unitMap } from '@/stores/user';
Decimal.set({
  rounding: 0
});

// 四舍五入两位小数向上取值: 3.1415 = 3.15
export const roundUp = (num, precision) => {
  precision = Math.pow(10, precision);
  let roundUpResult = Math.ceil(num * precision) / precision;
  return roundUpResult.toFixed(2);
};

// 计算汇率价格
export const flatRate = (money, rate) => {
  if (typeof money !== 'number' && typeof money !== 'string') {
    return 0;
  }
  if (rate === 1) {
    return Number(money).toFixed(2);
  }
  return roundUp(Decimal(money).div(Decimal(rate)).toNumber(), 2);
};

// 计算汇率价格(不向上取整)
export const flatRateOrigin = (money, rate) => {
  if (typeof money !== 'number' && typeof money !== 'string') {
    return 0;
  }
  if (rate === 1) {
    return Number(money).toFixed(2);
  }
  let lastResulrt = Decimal(money).div(Decimal(rate)).toNumber();
  let result = Math.floor(lastResulrt * 100) / 100;
  return result;
};

export const mulFlatRate = (money, rate) => {
  if (typeof money !== 'number' && typeof money !== 'string') {
    return 0;
  }
  if (rate === 1) {
    return money;
  }
  return roundUp(Decimal(money).mul(Decimal(rate)).toNumber(), 2);
};

export const getCurrencyUnit = () => {
  const { currency } = getLang();

  return {
    value: unitMap[currency],
    toString() {
      return this.value;
    }
  };
};

export const changeCurrencyUnit = app => {
  return currency => {
    app.config.globalProperties.$currencyUnit.value = unitMap[currency];
  };
};
