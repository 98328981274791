export default {
  confirmOrder: 'Confirmar pedido',
  createSuccess: 'Pedido criado com sucesso',
  orderPrice: 'Valor total do pedido',
  otherAction: 'Outras ações',
  orderStatus: 'Status do pedido',
  afterSales: 'Pós-venda',
  allSelect: 'Selecionar tudo',
  orderNo: 'Número do pedido',
  createTime: 'Horário de criação',
  productRemark: 'Informações de nota do produto',
  productRemarkHolder:
    'Deixe uma mensagem para o comprador, se não tiver requisitos especiais de compra, não escreva.',
  orderDetail: 'Detalhes do pedido',
  goPay: 'Ir para pagamento',
  cancelpay: 'Cancelar pagamento',
  cancelPay: 'Cancelar pagamento?',
  cancelOrder: 'Cancelar Ordem?',
  cancelorder: 'Cancelar Ordem',
  cancelOrderProduct: 'Cancelar o Produto?',
  cancelorderProduct: 'Cancelar o Produto',
  cancelPayResultHint:
    'Depois de cancelado, o pedido não pode ser recuperado, por favor selecione o motivo da cancelamento do pagamento',
  cancelOrderResultHint:
    'Depois de cancelado, o pedido não pode ser recuperado, por favor preencha o motivo da cancelamento do pedido',
  delOrder: 'Excluir pedido',
  delOrderHint:
    'Tem certeza de que deseja excluir o pedido? O produto não será devolvido ao vendedor para obter um reembolso.',
  cancelSuccess: 'Cancelamento bem-sucedido',
  delSuccess: 'Exclusão bem-sucedida',
  orderBin: 'Lixeira de pedidos',
  backOrder: 'Voltar para meus pedidos',
  restoreOrder: 'Restaurar pedido',
  remark: 'Observação',
  all: 'Todos',
  productName: 'Nome do produto',
  submitTime: 'Horário de envio',
  orderConsult: 'Consulta de pedido',
  appendService: 'Serviço adicional',
  iKnow: 'Eu entendi',
  cancelReturnBag: 'Cancelar devolução e troca',
  isCancelApply: 'Tem certeza de que deseja cancelar o pedido?',
  goPay2: 'Ir para pagamento complementar',
  backTax: 'Pagamento complementar',
  cancelBackTax: 'Cancelar pagamento complementar',
  productAttr: 'Atributos do produto',
  backTaxType: 'Tipo de pagamento complementar',
  taxPrice: 'Valor do pagamento complementar',
  productBackTax: 'Pagamento complementar do produto',
  freightBackTax: 'Pagamento complementar do frete',
  productTaxPrice: 'Valor do pagamento complementar do produto',
  freightTaxPrice: 'Valor do pagamento complementar do frete',
  totalTaxPrice: 'Valor total do pagamento complementar',
  productStatus: 'Status do produto',
  isNeedTax: 'É necessário pagamento complementar',
  yes: 'Sim',
  no: 'Não',
  iThinkAbout: 'Vou pensar sobre isso',
  revokeCheckedProduct: 'Cancelar a seleção do produto',
  taxTips1:
    'Por favor, [faça o pagamento complementar] ou [cancele] o mais rápido possível, para não afetar a compra de outros produtos no pedido.',
  cancelTaxTips1:
    'Se você não estiver disposto a fazer o pagamento complementar, não poderemos comprar o produto que requer pagamento complementar.',
  cancelTaxTips2:
    'Os produtos marcados serão cancelados após a revogação, e o valor do produto será devolvido à sua conta.',
  cancelTaxTips3:
    'Os produtos não marcados continuarão a ser mantidos e continuaremos comprando por você.',
  cancelReason: {
    0: 'Não quero mais comprá-lo',
    1: 'Informações preenchidas incorretamente',
    2: 'Vendedor sem estoque',
    3: 'Não foi possível pagar',
    4: 'Outras razões'
  },
  hint: 'Dica',
  orderStatusEnum: {
    0: 'Pendente de pagamento',
    1: 'Não processado',
    2: 'Em revisão',
    3: 'Pedido transferido',
    4: 'Pedido cancelado',
    5: 'Pagamento complementar de frete',
    6: 'Em revisão de controle de riscos',
    7: 'Pedido revogado',
    8: 'Pagamento em andamento',
    9: 'Concluído'
  },
  returnGood: {
    name: 'Devolução/Troca de mercadorias',
    selectType: 'Selecionar tipo',
    returnGood: 'Devolução de mercadoria',
    returnDayTips: 'Geralmente leva 3-7 dias',
    changeGood: 'Troca de mercadoria',
    changeDayTips: 'Geralmente leva 5-10 dias',
    returnReasonEnum: {
      0: 'Comprei errado / Não gostei',
      1: 'Vendedor enviou o produto errado?',
      2: 'Problema de qualidade do produto',
      3: 'Não enviou dentro do horário combinado',
      5: 'Outras razões'
    },
    returnReasonSelect: 'Selecione o motivo de devolução ou troca',
    returnExplain: 'Explicação de devolução',
    residueReturnNumTips:
      'Retorno de direitos de serviço gratuito, restante no mês',
    returnTips:
      'Se a devolução for causada pelo vendedor ou pela plataforma, o acbuy reembolsará o frete que você pagou.',
    changeTips:
      'Se a troca for causada pelo vendedor, você pode ainda precisar pagar parte do frete de troca.',
    returnTip4: 'Lembrete amigável',
    returnTip5:
      'O serviço de devolução e troca de mercadorias estabelecerá a quantidade de vezes que o serviço de operação de devolução e troca é gratuito. (Cada usuário pode solicitar a devolução ou troca de mercadorias sem cobrança de serviço pela primeira vez a cada mês, o frete gerado pela devolução ou troca de mercadorias é negociado com o agente e o vendedor e é cobrado pelo acbuy. O valor do serviço de devolução e troca de acbuy é: 5 yuans/pedido)',
    returnTip10:
      'Se a devolução ou troca de mercadorias for responsabilidade da compra, do vendedor ou da plataforma, o sistema reembolsará este valor de 5 yuans para o seu saldo acbuy.'
  },
  holder: {
    selectCancelReason: 'Selecione o motivo da cancelamento',
    inputOtherReason: 'Digite o motivo',
    inputSearchHint: 'Nome/número de ordem/número do produto',
    inputCancelReason: 'Digite o motivo da cancelamento do pedido'
  },
  selectProducts: 'Selecionar produtos',
  confirmStepOrder: 'Confirmar pedido',
  PayForProducts: 'Pagar pelos produtos',
  sendingPackages: 'Enviando pacotes',
  submitDelivery: 'Enviar para entrega',
  readAgreeText: 'Li e concordo com',
  disclaimer: 'O Aviso Legal',
  orderNote:
    'Nota: Após a conclusão do pagamento, você precisará enviar o pacote para entrega internacional assim que o pedido chegar e for armazenado no depósito.',
  reminder: 'lembrete',
  disclaimer1: 'Descrição dos Itens Proibidos',
  disclaimer2: 'Termos de Serviço',
  disclaimer3: 'Política de Devolução',
  customPhotosTips:
    'Uma foto apenas disponível fotografar uma mercadoria um detalhe pedido',
  backCartEdit: 'Voltar ao carrinho modificar',
  customPhotosReminderLabel: 'Lembrete gentil',
  customPhotosReminder:
    'Uma foto fornece apenas um requisito de detalhe para tirar uma foto de um produto',
  packageAgreeTip1:
    'Atrasos, danos, perdas, impostos, confisco e outras incertezas relacionadas aos serviços do provedor de logística estão além do nosso controle, e não seremos responsabilizados por quaisquer responsabilidades resultantes. No entanto, faremos todos os esforços para lembrá-lo de pensar duas vezes antes de fazer uma compra, ajudá-lo a resolver quaisquer problemas com o fornecedor de logística e compensá-lo de acordo com a política pós-venda fornecida pelo fornecedor de logística.',
  packageAgreeTip2:
    'Recomendamos que você declare com precisão o seu pacote. Se o seu pacote for submetido a inspeções aleatórias durante o desembaraço aduaneiro no país de destino, você pode precisar pagar alguns impostos. Se você receber um aviso fiscal, por favor, pague impostos de acordo, caso contrário, você pode enfrentar consequências como pagar altas taxas de retorno e destruição de pacotes.',
  applyRefund: 'O pedido de restituição',
  refundReminder:
    'Dicas quentes: após o pedido de reembolso, o comprador irá verificar o status da mercadoria, não pode cancelar o pedido de reembolso antes da verificação.',
  inputApplyReason: 'Por favor insira o motivo do seu pedido',
  orderConfirm: 'Confirmar a Ordem',
    replyMsg: 'Mensagem de resposta',
  replyMsgTips: 'A informação de observação que você envia, nós informará o comprador para verificar para você.'
};
