import form from './cn/form'
import home from './cn/home'
import search from './cn/search'
import cart from './cn/cart'
import product from './cn/product'
import order from './cn/order'
import pecenter from './cn/pecenter'
import pay from './cn/pay'
import common from './cn/common'
import estimation from './cn/estimation'
import packages from './cn/package'
import orderDetail from './cn/orderDetail'
import warnTips from './cn/warnTips'
import status from './cn/status'
import tips from './cn/tips'
import storage from './cn/storage'
import uniOrder from './cn/uniOrder'
import bankTransfer from './cn/bankTransfer'
import distribution from './cn/distribution'
import site from './cn/site'
import consult from './cn/consult'

export default {
  form,
  home,
  search,
  cart,
  product,
  order,
  pecenter,
  pay,
  common,
  estimation,
  packages,
  orderDetail,
  warnTips,
  status,
  tips,
  storage,
  distribution,
  uniOrder,
  bankTransfer,
  site,
  consult
}