export default {
  storeTo: 'Entrepôt vers',
  weightHaveUnit: 'Poids (g)',
  productType: 'Type de marchandises',
  long: 'Longueur (cm)',
  width: 'Largeur (cm)',
  height: 'Hauteur (cm)',
  seeDetail: 'Voir les détails',
  queryTips:
    "Veuillez entrer les paramètres correspondants pour vérifier les frais d'expédition",
  carrier: 'Transporteur',
  carrierCode: 'Code du transporteur',
  product: 'Produit',
  productCode: 'Code du produit',
  totalfee: 'Coût total',
  billingWeight: 'Poids tarifaire',
  feeEstimation: 'Estimation des frais',
  postFeeEstimation: "Estimation des frais d'expédition",
  postFee: "Frais d'expédition",
  postTime: "Temps d'expédition estimé",
  packUp: 'Réduire',
  startWeight: 'Poids de départ',
  endWeight: 'Poids final',
  calcExpression: 'Expression de calcul',
  baseFreight: 'Frais de base',
  registrationFee: "Frais d'inscription",
  noSend: 'Produits indisponibles',
  canSend: 'Produits disponibles',
  priceFrom: 'Composition des coûts',
  itemLimit: "Limite d'articles",
  disReason: "Raison de l'indisponibilité",
  BasicFreight: 'Frete básico',
  OperationFreight: 'Taxa de operação',
  RegistrationFreight: 'Taxa de registro',
  IossFee: 'Taxa IOSS',
  TariffFreight: 'Taxa alfandegária',
  VATFregith: 'Imposto sobre Valor Agregado (IVA)',
  AserviceFee: 'Taxa de serviço adicional',
  ExtraSizeFreight: 'Taxa adicional por tamanho extra',
  AdditionalDeliveryFeeForFemoteAreas:
    'Taxa adicional de entrega para áreas remotas',
  FuelSurcharge: 'Surtaxa de combustível',
  zoneCode: 'Zone ',
  amount: 'Prix ',
  rate: 'Taux ',
  conditionExpression: 'Calcul ',
  feeFactor: 'Facteur de frais ',
  continuedPrice: 'Prix par poids continué ',
  continuedWeight: 'Poids continué(g)',
  firstPrice: 'Prix par premier poids ',
  firstWeight: 'Premier poids(g)',
  weightRangeStart: 'Poids initial(g)',
  weightRangeEnd: 'Poids final(g)',
  holder: {
    toCountry: 'Choisir une destination',
    inputWeight: 'Veuillez entrer le poids',
    selectCountry: 'Veuillez sélectionner un pays',
    selectCarrier:
      "Veuillez sélectionner un transporteur/ligne de transporteur/pays de l'itinéraire",
    selectCategory: 'Choisir une restriction postale'
  },
  typeDelivery: 'Type de livraison',
  shippingTime: "Temps d'expédition estimé",
  deliveryTips: 'Conseils de livraison',
  estimatedShippingFee: 'Fret estimé',
  lineServiceTips:
    'Conseil: Tous les services logistiques sur les routes qui ne sont pas propres à acbuy sont fournis par un fournisseur logistique tiers qui adaptera les prix de fret aux conditions du marché. En cas de changement de prix, acbuy vous informera à l’avance. Si vous rencontrez des problèmes avec les services logistiques, acbuy peut communiquer en votre nom avec un fournisseur logistique tiers.',
  logisticsStatement: '<Déclaration des services logistiques>'
};
