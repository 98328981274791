// 用户标识颜色
export const bgColor = [
  "#FE3A2F",
  "#FF950F",
  "#FECB09",
  "#1CC759",
  "#51A9F1",
  "#0A79FE",
  "#C075DB",
  "#D8A69E"
];
// 映射语言
export const acceptLangMap = {
  en: 'en-US',
  cn: 'zh-CN',
  es: 'ca-ES',
  fr: 'fr-FR',
  pt: 'pt-PT'
}
// 二级分销确认协议
export const DISTRIBUTION_AGREE_KEY = 'DISTRIBUTION_AGREE_KEY'

export const storeSourceMap = {
  'taobao': 'TB',
  'alibaba': 'AL',
  'weidian': 'WD'
}
export const sourceStoreMap = {
  'TB': 'taobao',
  'AL': 'alibaba',
  'WD': 'weidian'
}
export  const storeDataMap = {
  TB: 'BNM',
  AL: 'ALIBABA',
  WD: 'WEIDIAN'
};
// 站内信样式
export const messageStyle = data => {
  return `<div><style></style><div style=\"width: 100%; max-height: 600px; background-color: #e4e4e4; display: flex; justify-content: center;overflow: scroll;padding-bottom: 20px;box-sizing: border-box;\">\n<div style=\"width: 50%; height: 90%; background: #FFFFFF; margin-top: 36px; border-radius: 10px; opacity: 1;\">\n<div style=\"width: 100%; height: 70%; background-color: #ffffff; border-radius: 10px 10px 0px 0px;\">\n<div style=\"height: 15%; padding-left: 25px; padding-top: 25px;\"><img style=\"height: 70px;\" src=\"https://pandabuy.oss-cn-hangzhou.aliyuncs.com/static/images/2021-10-01/75d6634b0bdb4ccda0507558ebf46b2b-16331410592415720862937998964404.png\" /></div>\n<div style=\"font-size: 14px; font-family: Microsoft YaHei; font-weight: 400; color: #4f4f4f; height: 85%; padding: 25px; line-height: 30px;\">\n${data}\n</div>\n</div>\n<div style=\"width: 100%; height: 30%; background-color: #f0f2f2; border-radius: 0px 0px 10px 10px; line-height: 30px; font-family: Microsoft YaHei; font-weight: 400; font-size: 14px; color: #9c9ea6;\">\n<div style=\"padding: 10px 15px 0px 15px;\"></div>\n<div style=\"padding: 0px 15px 15px 15px;\"></div>\n</div>\n</div>\n</div><div/>`;
};

// 1）  @gmail.com
// 2)   @icloud.com
// 3)   @yahoo.com
// 4)   @outlook.com
// 5)   @hotmail.com
// 常见邮箱后缀 测试版本不全，正式需要整理
export const emailSuffix = [
  "@gmail.com",
  "@icloud.com",
  "@yahoo.com",
  "@outlook.com",
  "@hotmail.com",
  "@yahoo.cn",
  "@yahoo.com.sg",
  "@yahoo.com.au",
  "@yahoo.com.ca",
  "@msn.com",
  "@163.com",
  "@126.com",
  "@qq.com",
  "@vip.qq.com",
  "@live.cn"
];
export const checkEmailSuffix = [
  "@gmail.com",
  "@icloud.com",
]
export const PLATEFORM = {
  taobao: 1,
  wd: 2,
  tmall: 1,
  alibaba: 3,
};

// 部分路由退出后重定向
export const redirectPathObj = {
  // 包裹详情 -> 我的包裹
  "/person/parcel/parcelDetail": "/person/parcel/list",
  // 订单详情 -> 我的订单
  "/person/order/orderDetail": "/person/order/orderList",
  // 下单页 -> 购物车
  "/confirm": "/person/shopping/cart",
  // 提包页 -> 我的包裹
  "/submitparcel": "/person/parcel/list",
  // 站内信人工消息详情 -> 站内信
  "/person/site/reply": "/person/site/message",
  // 修改密码 -> 账户安全
  "/person/info/security/password": "/person/info/security/secenter",
  // 设置支付密码 -> 账户安全
  "/person/info/security/pay": "/person/info/security/secenter",
  // 转运订单 -> 我的订单
  "/transport": "/person/order/orderList",
  // 收银台 -> 个人中心首页
  "/payment/paycenter": "/person/pecenter",
  // 定制物流
  '/customlogic': '/person/storage/list'
}

// 以图搜物 本地存储 的key
export const IMGBASE6SEARCH = 'IMGBASE6SEARCH'
export const imageBase64 = 'IMAGEBASE64'
export const imgUrl = 'IMGURL'


// 同意条款弹窗信息
export const isAgree = 'isAgree'

// 历史搜索
export const historyText = 'historyText'