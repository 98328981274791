export default {
  orderStatus: {
    0: 'Ha enviado el pedido, espere a que el agente de compras acepte el pedido',
    23: 'El agente de compras ha aceptado el pedido, estamos comunicándonos con el vendedor',
    22: 'Su producto ha sido encomendado, número de transacción: ',
    5: 'El vendedor ha enviado el producto, método de envío: ',
    10: 'Su producto ha llegado al almacén de acbuy',
    16: 'Ha solicitado una devolución, espere a que el agente de compras la procese',
    17: 'Ha solicitado un cambio de producto, espere a que el agente de compras lo procese',
    20: 'Este pedido ha completado la devolución,',
    beenReturned: ' Ver reembolso >',
    30: 'Su producto ha sido enviado, ver el paquete en el que se encuentra: ',
    43: 'Su solicitud de devolución ha sido cancelada',
    44: 'Su solicitud de cambio ha sido cancelada'
  },
  expressNo: 'Número de guía de envío: '
};
