export default {
  type: 'género artístico',
  relevanceNo: 'Número único associado',
  consultContent: 'Conteúdo da consulta',
  submitTime: 'Entrega o tempo',
  consultTypeEnum: {
    0: 'Consulta de pedidos',
    1: 'Consulta de pacotes'
  },
  consultStatusEnum: {
    0: 'SEM tratamento',
    1: 'No tratamento de',
    2: 'Concluído'
  }
}