import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import useUserStore from './user'
import useCartStore from './cart'
import useOrderStore from './order'
import useConsultStore from './consult'
import useDistributionStore from './distribution'

export {
  useUserStore,
  useCartStore,
  useOrderStore,
  useConsultStore,
  useDistributionStore
}

const stores = createPinia()
stores.use(piniaPluginPersistedstate)

export default stores