export default {
  confirmOrder: '确认订单',
  createSuccess: '订单创建成功',
  orderPrice: '订单总价',
  otherAction: '其他操作',
  orderStatus: '订单状态',
  afterSales: '售后服务',
  allSelect: '全选',
  orderNo: '订单号',
  createTime: '创建时间',
  productRemark: '商品备注信息',
  productRemarkHolder: '给采购员的留言，如无特殊购买要求请勿填写',
  orderDetail: '订单详情',
  goPay: '去支付',
  cancelpay: '取消支付',
  cancelPay: '取消支付？',
  cancelOrder: '取消订单？',
  cancelorder: '取消订单',
  cancelOrderProduct: '取消商品？',
  cancelorderProduct: '取消商品',
  cancelPayResultHint: '取消后支付无法恢复，请选择取消支付原因',
  cancelOrderResultHint: '取消后订单无法恢复，请填写取消订单原因',
  delOrder: '删除订单',
  delOrderHint: '确定要删除订单吗？商品将不会退回给卖家进行退款',
  cancelSuccess: '取消成功',
  delSuccess: '删除成功',
  orderBin: '订单回收站',
  backOrder: '返回我的订单',
  restoreOrder: '恢复订单',
  remark: '备注',
  all: '全部',
  productName: '商品名称',
  submitTime: '提交时间',
  orderConsult: '订单咨询',
  appendService: '追加服务',
  iKnow: '我知道了',
  cancelReturnBag: '取消退换货',
  isCancelApply: '确定要取消申请吗？',
  goPay2: '去补款',
  backTax: '补款',
  cancelBackTax: '取消补款',
  productAttr: '商品属性',
  backTaxType: '补款类型',
  taxPrice: '补款价格',
  productBackTax: '商品补款',
  freightBackTax: '运费补款',
  productTaxPrice: '商品补款价格',
  freightTaxPrice: '运费补款价格',
  totalTaxPrice: '补款总价',
  productStatus: '商品状态',
  isNeedTax: '是否需要补款',
  yes: '是',
  no: '否',
  iThinkAbout: '我再想想',
  revokeCheckedProduct: '撤销勾选商品',
  taxTips1: '请尽快[补款]或[取消]，以免影响订单中其他商品的购买。',
  cancelTaxTips1: '如果您不愿意补款，我们将无法为您购买需要补款的商品。',
  cancelTaxTips2: '撤销后，已标记的商品将被取消，商品费用将退回您的账户。',
  cancelTaxTips3: '未标记的商品将继续保留，我们将继续为您购买。',
  cancelReason: {
    0: '我不想买了',
    1: '信息填写错误',
    2: '卖家缺货',
    3: '无法付款',
    4: '其他原因'
  },
  hint: '提示',
  orderStatusEnum: {
    0: '待付款',
    1: '未处理',
    2: '审核中',
    3: '已转单',
    4: '已取消',
    5: '运费补款',
    6: '风控审核',
    7: '已撤单',
    8: '支付中',
    9: '已完成'
  },
  returnGood: {
    name: '退换货',
    selectType: '选择类型',
    returnGood: '退货',
    returnDayTips: '一般3-7天',
    changeGood: '换货',
    changeDayTips: '一般5-10天',
    returnReasonEnum: {
      0: '买错了/不喜欢',
      1: '卖家发错货？',
      2: '商品质量问题',
      3: '未按约定时间发货',
      5: '其他原因'
    },
    returnReasonSelect: '选择退货原因',
    returnExplain: '退货说明',
    residueReturnNumTips: '本月剩余免服务费退货权益',
    returnTips: '最终核实退货原因为卖家或平台原因，acbuy将退还您承担的运费。',
    changeTips: '对于因卖家原因造成的退换货，您可能仍需承担部分换货运费。',
    returnTip4: '友情提示',
    returnTip5:
      '退换货服务将设置免操作服务费次数。（每位用户每月首次申请退换货免操作服务费，退换货产生的运费由代理与卖家协商，由acbuy代收。acbuy退换货服务费：5元/单）',
    returnTip10:
      '如退换货责任为采购/卖家/平台，系统将退还此5元操作费至您的acbuy余额账户。'
  },
  holder: {
    selectCancelReason: '请选择退单原因',
    inputOtherReason: '请填写原因',
    inputSearchHint: '名称/主订单/商品单号',
    inputCancelReason: '请填写取消订单原因'
  },
  selectProducts: '选择商品',
  confirmStepOrder: '确认订单',
  PayForProducts: '支付商品',
  sendingPackages: '发送包裹',
  submitDelivery: '提交交付',
  readAgreeText: '我已阅读并同意',
  disclaimer: '免责声明',
  orderNote:
    '注意：付款完成后，您需要在包裹到达并存放在仓库后提交包裹进行国际递送。',
  reminder: '提示',
  disclaimer1: '违禁物品说明',
  disclaimer2: '服务条款',
  disclaimer3: '退货政策',
  customPhotosTips: '一张照片仅提供拍摄一件商品的一个细节要求',
  backCartEdit: '返回购物车修改',
  customPhotosReminderLabel: '  友情提示',
  customPhotosReminder: '一张照片只提供了拍摄一个产品照片的一个细节要求',
  packageAgreeTip1:
    '与物流提供商服务相关的延误、损坏、损失、税款、没收和其他不确定性超出了我们的控制范围，我们对由此产生的任何责任概不负责。但是，我们会尽一切努力提醒您在购买前三思而后行，帮助您解决与物流供应商的任何问题，并根据物流供应商提供的售后政策对您进行补偿。',
  packageAgreeTip2:
    '我们建议您准确申报您的包裹。如果您的包裹在目的地国家清关期间接受随机检查，您可能需要支付一些税款。如果您收到税务通知，请相应纳税，否则您可能会面临支付高额退货费和包裹销毁等后果。',
  applyRefund: '申请退款',
  refundReminder:
    '温馨提示：申请退款后，采购员会去核实商品状态，未核实完之前无法取消申请退款。',
  inputApplyReason: '请输入申请原因',
  orderConfirm: '确认订单',
  replyMsg: '回复信息',
  replyMsgTips: '您发送的备注信息，我们将会通知采购员为您核实。'
};
