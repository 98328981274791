export default {
  agreeTitle: '关于在acbuy网站使用"acbuy联盟"营销服务的协议',
  agreementText: `欢迎您注册acbuy网站并使用其"acbuy联盟"营销服务。本协议由"acbuy联盟"合作伙伴（以下简称"乙方"）与acbuy（以下简称"甲方"）（包括但不限于www.acbuy.com、acbuy手机APP等，以下简称"甲方网站"）就"acbuy联盟"营销服务相关事宜达成。在使用acbuy网站的"acbuy联盟"营销服务之前，乙方应仔细阅读本协议的所有内容，特别是加粗部分，点击"我已阅读并同意"按钮即表示乙方已接受并同意遵守本协议的规定。本协议构成乙方与acbuy之间的协议，具有法律效力。acbuy有权根据业务需要单方面修改本协议条款，如有修改，acbuy将立即在甲方网站上公布修改内容。如果乙方不同意对本协议文本的修改，应立即停止使用"acbuy联盟"渠道的营销服务，否则视为乙方同意对本协议文本的修改。
第1条 定义 1. 消费者：指为注册而访问甲方网站的acbuy会员`,
  dashboard: '仪表板',
  hi: '您好，',
  inviteFriends: '邀请好友',
  inviteFriendsTips:
    '邀请好友加入acbuy，赚取更多现金！邀请越多，赚得越多，无上限。',
  totalInvite: '已邀请用户总数',
  myTeam: '我的团队',
  extractMoney: '已结算佣金',
  noExtractMoney: '未结算佣金',
  inviteTitle: '邀请我的朋友',
  copyCode: '复制邀请码',
  poster: '专属海报',
  myLevel: '我的特权',
  youLevel: '您当前的等级',
  commissionRate: '佣金比例',
  experienceValue: '经验值({num}/{total})',
  levelDesc: '等级说明',
  invite: '邀请',
  revenueDetails: '邀请明细',
  registerTime: '注册时间',
  inviteType: '邀请关系',
  userMail: '用户邮箱',
  bonus: '奖金',
  myBonus: '我的奖金',
  myBalance: '我的余额',
  applyWithdraw: '申请提现',
  withdrawTips: '提示：由于提现涉及交易费用，建议提现金额高于¥500.00',
  bank: '银行',
  paypalAccount: 'PayPal账户',
  bankAccount: '银行账户',
  bankName: '银行名称',
  withdrawAmount: '提现金额({unit})',
  bonusDetail: '奖金明细',
  directCommission: '直接佣金明细',
  indirectCommission: '间接佣金明细',
  tradeTime: '交易时间',
  packNo: '包裹编号',
  actCommission: '实际佣金',
  settlementTime: '结算时间',
  levelName: '大使等级',
  packStatus: '包裹状态',
  directExplain: '运费佣金',
  indirectExplain: '二级邀请人运费佣金',
  experienceExplain: '所需经验值',
  withdrawRecord: '提现记录',
  withdrawTime: '提现时间',
  serialNo: '序列号',
  withdrawStatus: '提现状态',
  team: '团队',
  levelUp: '升级',
  teamInfo: '团队信息',
  teamNum: '团队用户数',
  monthCommission: '本月预计佣金',
  accCommission: '累计结算佣金',
  teamMember: '团队成员',
  promotedMember: '推广成员',
  invitationTime: '邀请时间',
  inviter: '邀请人',
  promotionTime: '推广时间',
  IBAN: 'IBAN',
  swiftCode: 'Swift代码',
  bankAddress: '银行地址',
  setting: '设置',
  profile: '个人资料',
  withdrawMethod: '提现方式',
  myPrerogative: '我的特权',
  verifyEmail: '验证您的邮箱',
  sendCodeTips:
    '我们已向<strong>{email}</strong>发送了验证码。请在下方输入验证码进行验证。',
  noGetEmail: '没有收到邮件？',
  codeErrorTips: '验证码错误，请重试',
  resendTime: '重新发送验证码 ({time}秒)',
  or: '或',
  contactService: '联系在线客服',
  addMethod: '新增提现方式',
  editMethod: '编辑提现方式',
  rePaypalAccount: '重新输入PayPal账户',
  directInvite: '直接邀请',
  indirectInvite: '间接邀请',
  noSameAccount: '账号和确认账号不一致',
  noAddWithdrawMethod: '你没有添加提现方法',
  statusEnum: {
    0: '待发放',
    1: '发放中',
    2: '发放成功',
    3: '发放失败',
    4: '提现撤销'
  },
  userDetail: '用户明细'
};
