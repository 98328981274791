export default {
  agreeTitle:
    'Accord sur les services de marketing via "acbuy Affiliate" sur le site web acbuy',
  agreementText: `Bienvenue pour vous inscrire sur le site web acbuy et utiliser ses services de marketing "acbuy Affiliate". Cet accord est conclu entre les partenaires de "acbuy Affiliate" (ci-après dénommé "Partie B") et acbuy (ci-après dénommé "Partie A") (y compris, mais sans s'y limiter, www.acbuy.com, l'application mobile acbuy, etc., ci-après dénommé "Site web de la Partie A") concernant les questions liées aux services de marketing de "acbuy Affiliate". La Partie B doit lire attentivement tous les textes des présentes, en particulier les parties en gras, avant d'utiliser les services de marketing de "acbuy Affiliate" sur le site web acbuy, et en cliquant sur le bouton "J'ai lu et j'accepte", la Partie B est réputée avoir accepté et accepté de se conformer aux spécifications des présentes. L'accord constituera l'accord entre la Partie B et acbuy et aura force légale. acbuy aura le droit de modifier unilatéralement les articles des présentes en fonction de ses besoins commerciaux, et acbuy publiera immédiatement ces modifications sur le site web de la Partie A en cas de modifications apportées aux articles des présentes, et la Partie B devra immédiatement cesser d'utiliser les services de marketing sur le canal "acbuy Affiliate" au cas où la Partie B n'accepterait pas les modifications apportées aux textes des présentes, ou sinon, la Partie B sera réputée accepter les modifications apportées aux textes des présentes.
  Article 1 Définitions 1. Consommateur : désigne le membre acbuy qui accède au site web de la Partie A pour s'inscrire`,
  dashboard: 'Tableau de bord',
  hi: 'Bonjour, ',
  inviteFriends: 'Inviter des amis',
  inviteFriendsTips:
    "Invitez des amis à acbuy, gagnez plus d'argent ! Invitez plus, gagnez plus, sans limite.",
  totalInvite: 'Total des utilisateurs invités',
  myTeam: 'Mon équipe',
  extractMoney: 'Commission réglée',
  noExtractMoney: 'Commission non réglée',
  inviteTitle: 'Inviter mes amis',
  copyCode: "Copier le code d'invitation",
  poster: 'Affiche exclusive',
  myLevel: 'Ma prérogative',
  youLevel: 'Votre niveau actuel',
  commissionRate: 'Taux de commission',
  experienceValue: "Valeur d'expérience ({num}/{total})",
  levelDesc: 'Description du niveau',
  invite: 'Inviter',
  revenueDetails: 'Détails de l’invitation',
  registerTime: "Heure d'inscription",
  inviteType: "Relation d'invitation",
  userMail: "E-mail de l'utilisateur",
  bonus: 'Bonus',
  myBonus: 'Mon bonus',
  myBalance: 'Mon solde',
  applyWithdraw: 'Demander un retrait',
  withdrawTips:
    'Conseil : En raison des frais de transaction impliqués dans le retrait, il est recommandé de retirer un montant supérieur à 500,00 ¥',
  bank: 'Banque',
  paypalAccount: 'Compte PayPal',
  bankAccount: 'Compte bancaire',
  bankName: 'Nom de la banque',
  withdrawAmount: 'Montant du retrait ({unit})',
  bonusDetail: 'Détail du bonus',
  directCommission: 'Détails de la commission directe',
  indirectCommission: 'Détails de la commission indirecte',
  tradeTime: 'Heure de la transaction',
  packNo: 'Numéro de colis',
  actCommission: 'Commission réelle',
  settlementTime: 'Heure de règlement',
  levelName: "Niveau d'ambassadeur",
  packStatus: 'Statut du colis',
  directExplain: "Commission sur les frais d'expédition",
  indirectExplain:
    "Commission sur les frais d'expédition des invités secondaires",
  experienceExplain: "Points d'expérience requis",
  withdrawRecord: 'Historique des retraits',
  withdrawTime: 'Heure du retrait',
  serialNo: 'Numéro de série',
  withdrawStatus: 'Statut du retrait',
  team: 'Équipe',
  levelUp: 'Monter de niveau',
  teamInfo: "Informations sur l'équipe",
  teamNum: "Nombre d'utilisateurs de l'équipe",
  monthCommission: 'Commission estimée pour ce mois',
  accCommission: 'Commission cumulée réglée',
  teamMember: "Membre de l'équipe",
  promotedMember: 'Membre promu',
  invitationTime: "Heure d'invitation",
  inviter: 'Inviteur',
  promotionTime: 'Heure de promotion',
  IBAN: 'IBAN',
  swiftCode: 'Code Swift',
  bankAddress: 'Adresse de la banque',
  setting: 'Paramètres',
  profile: 'Profil',
  withdrawMethod: 'Méthode de retrait',
  myPrerogative: 'Ma prérogative',
  verifyEmail: 'Vérifiez votre e-mail',
  sendCodeTips:
    'Nous avons envoyé un code de vérification à <strong>{email}</strong>. Entrez le code ci-dessous pour vérifier.',
  noGetEmail: "Vous n'avez pas reçu l'e-mail ?",
  codeErrorTips: 'Erreur de code, veuillez réessayer',
  resendTime: 'Renvoyer le code ({time}s)',
  or: 'ou',
  contactService: 'Contacter le service client en ligne',
  addMethod: 'Nouvelle méthode de retrait',
  editMethod: 'Modifier les méthodes de retrait',
  rePaypalAccount: 'Saisir à nouveau le compte PayPal',
  directInvite: 'Une invitation directe',
  indirectInvite: 'Une invitation indirecte',
  noSameAccount: 'Le compte et le compte de confirmation ne correspondent pas',
  noAddWithdrawMethod: 'Vous n’avez pas ajouté de méthode de retrait',
  statusEnum: {
    0: 'À délivrer',
    1: 'En cours de délivrance',
    2: 'Succès de distribution',
    3: 'Échec de distribution',
    4: 'Retrait de retrait'
  },
    userDetail: 'Détails de l’utilisateur'
};
