export default {
  useMoreAddress: 'Use another address',
  hideAddress: 'Fold Address',
  productInventory: 'Commodity List',
  tag: 'Label',
  retainTag: 'Reserved label',
  delTag: 'Remove label',
  externalPack: 'Packing exterior',
  packDetail: 'Packing details',
  postLine: 'Delivery Route',
  selectLine: 'Select route',
  editLine: 'Modify route',
  service: 'Service',
  cancelServe: 'Cancel Service',
  previewPackage: 'Preview Parcel',
  postCountry: 'Sending country',
  packageNo: 'Parcel No.',
  deliver: 'Deliver to',
  packageDetail: 'Parcel details',
  addressInfo: 'Address Information',
  voWeight: 'Volume weight',
  firstWeight: 'First weight',
  continueWeight: 'Renewed weight',
  actWeight: 'Actual weight',
  packageStatus: 'Parcel status',
  freightDetail: 'Cost Information',
  declareCurrency: 'Declaration Currency',
  selfDeclare: 'Independent Declaration',
  packageDeclarePrice: 'The Total Amount Of Parcel Declaration',
  youPackageProduct: 'The Goods In Your Parcel',
  editYourDeclare: 'Please Edit Your Declaration Information',
  startDeclare: 'Start Filing',
  declareType: 'Type of declaration',
  productNo: 'Product ID',
  declareProductPrice: 'Declare unit price',
  declareTotalPrice: 'Total amount of goods declared',
  editDeclare: 'Modify declaration',
  declareLinePriceHint:
    'The lowest total amount declared for the current line: {min}, the highest: {max}',
  classMaxNumHint:
    'The number of single categories has reached the upper limit {max}',
  addDeclareProduct: 'Add declared goods',
  plsConfirmInfo: 'Please confirm the information',
  wipePackProduct: 'Unpackaged goods',
  foldPackProduct: 'Foldable goods',
  wipeTagProduct: 'Unlabeled goods',
  aboutCalcWeightExplain:
    'As the parcel is not packed at present, we do not know the true weight of the parcel at this stage, so we can only rely on several existing parameters to estimate the weight of the parcel, so as to collect the deposit. After the parcel is packed and the true weight is obtained, more and less will be returned.',
  expressInfo: 'Logistics Information',
  deliveryMode: 'Mode of distribution',
  targetCountry: 'Target country',
  cancelPackage: 'Cancel parcel',
  cancelPackageHint:
    'Confirmed to cancel this parcel? You will not be able to recover after canceling.',
  isDelPackage: 'Confirmed to permanently delete the parcel?',
  delPackageHint:
    'After permanent deletion, you will no longer be able to view the parcel, please exercise caution!',
  receivedTips: 'Your parcel has been received, welcome to use it again',
  packageQuestion:
    'There is a problem with the delivery of the parcel, you can',
  afterSale: 'Apply for after-sales service',
  repair: 'Repayment',
  ucan: 'You can',
  clickAdd: 'Click Add',
  addCategoryTips: 'There is no product name you want to declare',
  noCarrierLines: 'There are no shipping routes available',
  confirmPackageTitle: 'Have you confirmed receipt of the goods?',
  confirmPackageTips:
    'To protect your after-sales rights, please confirm receipt after verifying that the package is correct',
  receiptSuccess: 'Receipt successful',
  position: 'Position',
  time: 'Time',
  duty: 'Tax Number',
  serveStatusEnum: {
    1: 'Custom photography',
    2: 'Extend the custodial period'
  },
  inputCareTime: 'Please select storage time',
  holder: {
    noInputSearch: 'Parcel No./Product ID',
    inputEditDeclareInfo: 'Please edit your declaration information',
    packageRemark:
      'If you have any other special requirements for the package, please note here'
  },
  calcFee: 'Estimated fee',
  realFee: 'Actual fee',
  declare: {
    repeatDeclare: 'Type of duplicate declaration',
    addDeclareType: 'Please add declaration type'
  },
  feeEnum: {
    totalAmount: 'Estimated deposit',
    deposit: 'Estimated cost',
    overLongFee: 'Overlength fee',
    overWeightFee: 'Excess weight fee',
    remoteFee: 'Remote fees',
    feeCustoms: 'Logistics handling fee',
    feeFuel: 'Fuel surcharge',
    feeOperation: 'Handling fee',
    feeService: 'Service fee',
    valueAddedTax: 'Value-added tax',
    insuranceService: 'Premium',
    incrementAmount: 'Value-added service fee',
    lineDiscount: 'Line discount',
    discount: 'VIP discounts',
    coupon: 'Select a coupon',
    passwordDiscount: 'Preferential password',
    orderPreDiscount: 'Merchandise discount',
    insuranceDiscount: 'Insurance concessionary',
    incrementDiscount: 'Value-added service offer',
    preVolumeCal: 'Estimated weighing',
    actDeposit: 'Freight after weighing',
    actDepositAmount: 'Final payment'
  },
  ratePaymentEnum: {
    name: 'Tax Payment Method',
    0: 'Paid by the recipient',
    1: 'Platform GST/VAT',
    2: 'Personal GST/VAT',
    3: 'Platform IOSS',
    4: 'Personal IOSS',
    5: 'Company GST/VAT',
    6: 'Logistics providers GST/VAT',
    7: 'Logistics providers IOSS',
    8: 'Tax-free'
  },
  bagStatusEnum: {
    0: 'Normal parcel',
    1: 'Domestic return',
    2: 'Foreign return',
    3: 'Cancel package',
    4: 'Repayment parcel'
  },
  packageStatusEnum: {
    0: 'Awaiting processing',
    1: 'Processing',
    2: 'Outbound',
    3: 'Out of storage',
    4: 'Packaged',
    5: 'Weighed',
    6: 'The tracking number has been printed',
    7: 'Accredited',
    8: 'Handover',
    9: 'Shipment has been dispatched',
    20: 'Parcel Received'
  },
  declareEnum: {
    name: 'Declaration Method',
    0: 'System Declaration',
    1: 'Personal Decalaration'
  },
  backText: 'Back',
  packageInformation: 'Parcel Information',
  parcelNumber: 'Parcel No.',
  noValidRule: 'The declared amount does not pass the rules: {rule}',
  shippingAgree: 'acbuy Package Shipping Agreement',
  haveDiscountCode: 'I have discount code',
  inputDiscountCode: 'Input your discount code here',
  expressNo: 'Tracking number'
};
