export default {
  noSamePass: 'A senha e a confirmação de senha não coincidem',
  loginSuccess: 'Login bem-sucedido',
  registerSuccess: 'Registro bem-sucedido',
  isHaveAccount: 'Já possui uma conta?',
  login: 'Entrar',
  logout: 'Sair',
  forgetPass: 'Esqueceu a senha?',
  register: 'Registrar-se',
  registered: 'Já registrado,',
  emailTips: 'E-mail',
  password: 'Senha',
  confirmPass: 'Confirmar senha',
  countryTips: 'Por favor, selecione o seu país',
  inviteCodeTips: 'Digite o código de convite (opcional)',
  verifyEmpty: 'Os campos de entrada não podem estar vazios',
  verifyEmail: 'Informação de entrada incorreta',
  verifyPass:
    'A senha deve ter entre 6 e 20 caracteres e deve conter letras e números',
  confirmText: 'Eu li e concordo',
  agreementName: '《Contrato de Registro de Usuário PandaBuy》',
  verifyCode: 'Código de verificação',
  loginPwd: 'Senha de login',
  editPwd: 'Editar senha',
  changePwd: 'Alterar senha',
  setPwd: 'Definir senha',
  and: 'e',
  privacyStatement: '《Declaração de Privacidade》',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  editSuccess: 'Edição bem-sucedida',
  email: 'E-mail',
  emailCode: 'Código de verificação de e-mail',
  newPwd: 'Nova senha',
  button: {
    search: 'Pesquisar',
    query: 'VERIFICAR AGORA',
    reset: 'Redefinir',
    submit: 'Enviar',
    submitOrder: 'Enviar pedido',
    save: 'Salvar'
  },
  holder: {
    select: 'Por favor, selecione',
    startTime: 'Hora de início',
    endTime: 'Hora de término',
    max: 'Máximo',
    min: 'Mínimo',
    inputContent: 'Por favor, insira o conteúdo',
    inputEmailAccount: 'Por favor, insira um endereço de e-mail válido'
  },
  back: 'Bem-vindo de volta!',
  continueGoogle: 'Continuar com o Google',
  notAccount: 'Não tem uma conta?',
  signNow: 'Registre-se agora',
  termService: 'Termos de Serviço',
  privacyPolicy: 'Política de Privacidade',
  helpCenter: 'Central de Ajuda',
  readPrivacy: 'Eu li e aceito',
  verifyAccept: 'Ainda não aceitou o contrato',
  accountSecurity: 'Segurança da conta',
  loginNow: 'Faça login agora',
  resetPsd: 'Redefinir senha',
  verifiedEmail: 'Verificar o E- mail',
  unverified: 'Não verificado',
  emailVerification: 'Verificação de e-mail',
  verifyMsg:
    'Seu e-mail não foi verificado, então não podemos completar a operação de compra temporariamente. Por favor, verifique seu e-mail no centro pessoal.',
  sendVerificationEmail: 'Enviar e-mail de verificação',
  linkHasExpired: 'O link expirou!',
  hasSendEmail: 'E-mail de verificação enviado!',
  shippingFeeEstimation: 'Estimativa de taxa de envio',
  homeText: 'Início',
  loginBgMsg: 'Junte-se ao acbuy Discord para obter mais benefícios',
  registerTitleMsg: 'COMECE SUA JORNADA',
  registerBgMsg: 'Novos usuários recebem cupons de envio de 340$',
  announcement: 'Anúncio',
  formAnnouncementMsg:
    'Um novo modelo para programa de afiliados, modelo de comissão de pacote duplo, use-o para ganhar dinheiro e economizar dinheiro',
  incorrectAmount: 'O valor do saque deve ser maior que 0',
  uniOrderAgreeText:
    'O link que você enviou nesta página pertence a links de produtos de terceiros (como Xianyu, Xiaohongshu, Bilibili, etc.), e acbuy só pode ajudá-lo a encontrar o comerciante deste link para consulta de produtos, compra e organização de serviços de entrega logística de terceiros. A ACBUY não pode distinguir a qualidade e autenticidade do produto, e você precisa suportar os riscos relevantes antes de fazer um pedido. Concordo em aumentá-lo.',
  excludeFees:
    'Excluindo as taxas de envio internacional, as taxas de envio internacional precisam ser calculadas separadamente'
};
