export default {
  allMsg: '所有消息',
  systemMsg: '系统消息',
  personMsg: '人工消息',
  unread: '未读',
  seeDetail: '查看详情',
  read: '已读',
  replyNow: '现在回复',
  message: '站内信',
  operateSuccess: '操作成功',
  holder: {
    searchHolder: '包裹号/订单号/商品编号'
  }
}