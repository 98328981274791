export default {
  type: 'género',
  relevanceNo: 'Número único asociado',
  consultContent: 'Contenido de consulta',
  submitTime: 'El plazo de presentación de',
  consultTypeEnum: {
    0: 'Consulta de pedidos',
    1: 'Consulta de paquetes'
  },
  consultStatusEnum: {
    0: 'Sin tratamiento',
    1: 'En el tratamiento de',
    2: 'Se ha completado'
  }
}