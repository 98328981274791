import { defineStore } from 'pinia'
import { addProductToCart, getCartProductList, getCartProductNum, delCartProduct, updateCartProductNum } from '@/apis/cart'


// 购物车模块
export default defineStore('cartStore', {
  namespaced: true,
  state () {
    return {
      // 购物车商品列表
      list: [],
      productNum: 0,
      loading: false
    }
  },
  getters: {

  },
  actions: {
    // 添加商品到购物车
    async addProduct (params) {
      await addProductToCart(params)
      this.getCartProductNum()
    },
    // 更新购物车
    async updateCart (params = {}) {
      const res = await getCartProductList(params)
      this.list = res || []
    },
    // 获取购物车商品数量
    async getCartProductNum () {
      if (this.loading) return
      this.loading = true
      const res = await getCartProductNum()
      this.productNum = res
      this.loading = false
    },

    // 删除购物车数据
    async removeCartData (params) {
      await delCartProduct(params)
      this.updateCart()
      this.getCartProductNum()
    },
    // 更新商品个数
    async updateCartProductNum (product, params) {

      await updateCartProductNum(params)
      this.list.forEach(shopItem => {
        shopItem.goods.forEach(item => {
          if (product.id === item.id) {
            item.goodsNum = params.goodsNum
          }
        })
      })
    },

  }
})
