const Cart = () =>
  import(/* webpackChunkName: "cart" */ '@/pages/member/cart/index'); // 购物车
const Pecenter = () =>
  import(/* webpackChunkName: "pecenter" */ '@/pages/member/pecenter/index'); // 个人中心
const Recipient = () =>
  import(/* webpackChunkName: "recipient" */ '@/pages/member/recipient'); // 收件人地址
const Asset = () =>
  import(/* webpackChunkName: "asset" */ '@/pages/member/asset'); // 我的资产
const BillAddress = () =>
  import(/* webpackChunkName: "bill-address" */ '@/pages/member/billAddress'); // 账单地址
const Withdraw = () =>
  import(/* webpackChunkName: "withdraw" */ '@/pages/member/withdraw'); // 提现余额
const AccountInfo = () =>
  import(
    /* webpackChunkName: "account-info" */ '@/pages/member/accountInfo/index.vue'
  ); // 账户信息
const SelfInfo = () =>
  import(
    /* webpackChunkName: "self-info" */ '@/pages/member/accountInfo/SelfInfo'
  ); // 个人信息
const AccountSafe = () =>
  import(
    /* webpackChunkName: "account-safe" */ '@/pages/member/accountInfo/AccountSafe'
  ); // 账户安全
const EditPassword = () =>
  import(
    /* webpackChunkName: "edit-password" */ '@/pages/member/accountInfo/EditPwd'
  ); // 设置密码
const Parcel = () =>
  import(/* webpackChunkName: "may-package" */ '@/pages/member/myPackage'); // 我的包裹
const MyStorage = () =>
  import(/* webpackChunkName: "my-storage" */ '@/pages/member/storage'); // 我的仓库

const PackageDetail = () =>
  import(
    /* webpackChunkName: "package-detail" */ '@/pages/member/packageDetail'
  ); // 包裹详情
const MemberLayout = () =>
  import(/* webpackChunkName: "member-layout" */ '@/pages/member/Layout');
const MemberOrder = () =>
  import(/* webpackChunkName: "member-order" */ '@/pages/member/order');
const OrderDetail = () =>
  import(/* webpackChunkName: "order-detail" */ '@/pages/member/orderDetail');

const BankTransfer = () =>
  import(/* webpackChunkName: "bank-transfer" */ '@/pages/member/bankTransfer'); // 银汇转账
const MyAffiliate = () =>
  import(
    /* webpackChunkName: "my-affiliate" */ '@/pages/member/myAffiliate/index'
  ); // 我的下属
const Message = () =>
  import(/* webpackChunkName: "message" */ '@/pages/member/site/msg/index'); // 站内信
const Reply = () =>
  import(/* webpackChunkName: "reply" */ '@/pages/member/site/reply/index'); // 站内信回复
const Consult = () =>
  import(/* webpackChunkName: "consult" */ '@/pages/member/consult/index'); // 咨询

export default [
  {
    path: '/member',
    component: MemberLayout,
    redirect: '/member/pecenter',
    children: [
      { path: '/member/pecenter', name: 'pecenter', component: Pecenter },
      { path: '/member/cart', name: 'cart', component: Cart },
      { path: '/member/site/message', name: 'message', component: Message },
      { path: '/member/site/reply', name: 'reply', component: Reply },
      {
        path: '/member/bankTransfer',
        name: 'bank-transfer',
        component: BankTransfer
      },
      { path: '/member/order', name: 'order-list', component: MemberOrder },
      {
        path: '/member/order/order-detail',
        name: 'order-detail',
        component: OrderDetail
      },
      { path: '/member/wallet', name: 'asset', component: Asset },
      {
        path: '/member/pecenter/withdraw',
        name: 'withdraw',
        component: Withdraw
      },
      {
        path: '/member/my-package',
        name: 'my-package',
        component: Parcel
      },
      {
        path: '/member/my-package/package-detail',
        name: 'package-detail',
        component: PackageDetail
      },
      { path: '/member/storage', name: 'storage', component: MyStorage },
      {
        path: '/member/account-info',
        name: 'account-info',
        component: AccountInfo,
        redirect: '/member/account-info/self',
        children: [
          {
            path: '/member/account-info/self',
            name: 'self-info',
            component: SelfInfo
          },
          {
            path: '/member/account-info/recipient',
            name: 'recipient',
            component: Recipient
          },
          {
            path: '/member/account-info/safe',
            name: 'account-safe',
            component: AccountSafe
          },
          {
            path: '/member/account-info/bill-address',
            name: 'bill-address',
            component: BillAddress
          },
          {
            path: '/member/account-info/edit-pwd',
            name: 'edit-pwd',
            component: EditPassword
          }
        ]
      },
      {
        path: '/member/myAffiliate',
        name: 'my-affiliate',
        component: MyAffiliate
      },
      {
        path: '/member/consult',
        name: 'consult',
        component: Consult
      }
    ]
  }
];
