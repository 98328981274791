export default {
  useMoreAddress: 'Utiliser une autre adresse',
  hideAddress: "Réduire l'adresse",
  productInventory: 'Liste des produits',
  tag: 'Étiquettes',
  retainTag: "Maintenir l'étiquette",
  delTag: "Supprimer l'étiquette",
  externalPack: 'Emballage extérieur',
  packDetail: "Détails de l'emballage",
  postLine: "Itinéraire d'expédition",
  selectLine: "Sélectionner l'itinéraire",
  editLine: "Modifier l'itinéraire",
  service: 'Service',
  cancelServe: 'Annuler le service',
  previewPackage: "Démonstration de l'emballage",
  postCountry: 'Pays destination',
  packageNo: 'Numéro de colis',
  deliver: 'Envoyer',
  packageDetail: 'Détails du colis',
  addressInfo: "Informations d'adresse",
  voWeight: 'Poids volumique',
  firstWeight: 'Poids initial',
  continueWeight: 'Poids supplémentaire',
  actWeight: 'Poids réel',
  packageStatus: "Status de l'envoi",
  freightDetail: 'Information sur les coûts',
  declareCurrency: 'Monnaie de la déclaration',
  selfDeclare: 'Déclaration de soi-même',
  packageDeclarePrice: 'Montant total de la Déclaration de colis',
  youPackageProduct: 'Les articles dans votre colis',
  editYourDeclare: 'Veuillez modifier les informations de votre déclaration',
  startDeclare: 'Commencer la déclaration',
  declareType: 'Type de déclaration',
  productNo: "Numéro d'article",
  declareProductPrice: "Prix unitaire déclaré de l'article",
  declareTotalPrice: 'Prix total déclaré des articles',
  editDeclare: 'Modifier la déclaration',
  declareLinePriceHint:
    'Le montant total déclaré pour la ligne actuelle est faible : {min} et le maximum est {max}',
  classMaxNumHint: "Nombre maximum d'articles atteint {max}",
  addDeclareProduct: 'Ajouter les marchandises déclarées',
  plsConfirmInfo: "Confirmer l'information",
  wipePackProduct: 'Produits sans emballage',
  foldPackProduct: 'Produits avec emballages pliés',
  wipeTagProduct: 'Produits sans étiquettes',
  aboutCalcWeightExplain:
    "Étant donné que l'envoi n'est pas encore emballé, nous ne savons pas encore sa masse réelle à ce stade. Nous estimons donc son poids à l'aide de quelques paramètres existants et facturons un acompte en conséquence. Une rectification sera effectuée une fois l'envoi réellement emballé et sa masse connue.",
  expressInfo: 'Informations logistiques',
  deliveryMode: 'Mode de livraison',
  targetCountry: 'Pays destination',
  cancelPackage: 'Annuler le colis',
  cancelPackageHint:
    'Confirmez-vous le retrait de ce colis ? Il ne pourra pas être restauré par la suite.',
  isDelPackage: 'Confirmez-vous le retrait définitif de ce colis ?',
  delPackageHint:
    'Une fois définitivement supprimé, vous ne pourrez plus y accéder. Veuillez agir avec précaution !',
  receivedTips:
    "Votre colis a été accepté, nous vous attendons pour d'autres utilisations",
  packageQuestion:
    'Il y a eu un problème avec le processus de livraison des colis, vous pouvez',
  afterSale: 'Demander un service après-vente',
  repair: 'Supplément',
  ucan: 'Vous pouvez',
  clickAdd: 'Cliquez ici pour ajouter',
  addCategoryTips: "Il n'y a pas de nom commercial que vous souhaitez déclarer",
  noCarrierLines: "Aucune voie d'expédition disponible",
  confirmPackageTitle: 'Avez-vous confirmé la réception des marchandises ?',
  confirmPackageTips:
    'Pour protéger vos droits après-vente, veuillez confirmer la réception après avoir vérifié que le colis est correct',
  receiptSuccess: 'Réception réussie',
  position: 'Positions',
  time: 'Le temps',
  duty: "Numéro d'impôt",
  serveStatusEnum: {
    1: 'Photographie personnalisée',
    2: 'Prolonger la période de garde'
  },
  inputCareTime: 'Veuillez sélectionner une période de stockage',
  holder: {
    noInputSearch: "Numéro d'envoi / Numéro d'article",
    inputEditDeclareInfo:
      'Veuillez modifier les informations de votre déclaration',
    packageRemark:
      "Si vous avez d'autres exigences particulières pour le colis, veuillez les noter ici"
  },
  calcFee: 'Frais estimés',
  realFee: 'Frais réels',
  declare: {
    repeatDeclare: 'Type de déclaration répétée',
    addDeclareType: 'Veuillez ajouter un type de déclaration'
  },
  feeEnum: {
    totalAmount: 'Dépôt estimé',
    deposit: 'Coût estimé',
    overLongFee: 'Frais sur le longueur',
    overWeightFee: 'Frais de surpoids',
    remoteFee: 'Frais de loin distance',
    feeCustoms: 'Frais de traitement du transporteur',
    feeFuel: "Frais d'addition de carburant",
    feeOperation: "Frais d'exploitation",
    feeService: 'Frais de service',
    valueAddedTax: 'Taxe à la valeur ajoutée',
    insuranceService: "Frais d'assurance",
    incrementAmount: 'Frais de service supplémentaires',
    lineDiscount: 'Discount de ligne',
    discount: 'Discount VIP',
    coupon: 'Choisir un coupon',
    passwordDiscount: 'Mot de passe du discount',
    orderPreDiscount: "Remise sur l'article",
    insuranceDiscount: "Remise sur l'assurance",
    incrementDiscount: 'Remise sur les services supplémentaires',
    preVolumeCal: 'Poids prévisionnel',
    actDeposit: 'Frais de livraison après pesage',
    actDepositAmount: 'Montant final à payer'
  },
  ratePaymentEnum: {
    name: 'Mode de paiement des droits',
    0: 'Payé par destinataire',
    1: 'GST/TVA plateforme',
    2: 'GST/TVA personnelle',
    3: 'IOSS plateforme',
    4: 'IOSS personnelle',
    5: 'GST/TVA entreprise',
    6: 'GST/TVA transporteur',
    7: 'IOSS transporteur',
    8: "Exempt d'impôt"
  },
  bagStatusEnum: {
    0: 'Colis normal',
    1: "Retour en provenance du pays d'origine",
    2: "Retour à l'étranger",
    3: 'Annuler le colis',
    4: 'Colis avec complément de paiement'
  },
  packageStatusEnum: {
    0: 'En attente de traitement',
    1: 'En cours de traitement',
    2: "En cours d'expédition",
    3: "Quitter de l'entrepôt",
    4: 'Emballé',
    5: 'Poids enregistré',
    6: 'Numéro de suivi imprimé',
    7: 'Vérifié',
    8: 'Transmis',
    9: 'Expédié',
    20: 'Réception confirmée'
  },
  declareEnum: {
    name: 'Mode de la déclaration',
    0: 'Déclaration du système',
    1: 'Décadence personnelle'
  },
  backText: 'Retour',
  packageInformation: "Informations sur l'emballage",
  parcelNumber: 'Numéro du colis',
  noValidRule: 'Montant déclaré non conforme aux règles: {rule}',
  shippingAgree: 'Accord de transport de colis acbuy',
  haveDiscountCode: 'J’ai un code de réduction',
  inputDiscountCode: 'Entrez votre code de réduction ici',
  expressNo: 'Numéro de suivi logistique'
};
