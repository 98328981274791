export default {
  confirmOrder: 'Confirm Order',
  createSuccess: 'Order created successfully',
  orderPrice: 'Total Order Price',
  otherAction: 'Other Actions',
  orderStatus: 'Order Status',
  afterSales: 'After-sales',
  allSelect: 'Select All',
  orderNo: 'Order No.',
  createTime: 'Creation Time',
  productRemark: 'Product Note Information',
  productRemarkHolder:
    'Message for the purchasing agent, if no special purchase requirements, do not fill in.',
  orderDetail: 'Order Details',
  goPay: 'Go to Pay',
  cancelpay: 'Cancel Payment',
  cancelPay: 'Cancel Payment?',
  cancelOrder: 'Cancel Order?',
  cancelorder: 'Cancel Order',
  cancelOrderProduct: 'Cancel Product?',
  cancelorderProduct: 'Cancel Product',
  cancelPayResultHint:
    'After cancellation, the payment cannot be restored, please select the reason for canceling the payment',
  cancelOrderResultHint:
    'After cancellation, the order cannot be restored, please fill in the reason for canceling the order',
  delOrder: 'Delete Order',
  delOrderHint:
    'Are you sure you want to delete the order? The item will not be returned to the seller for a refund',
  cancelSuccess: 'Cancellation Successful',
  delSuccess: 'Deletion Successful',
  orderBin: 'Order Bin',
  backOrder: 'Back to My Order',
  restoreOrder: 'Restore Order',
  remark: 'Remarks',
  all: 'All',
  productName: 'Product Name',
  submitTime: 'Submission Time',
  orderConsult: 'Order Inquiry',
  appendService: 'Additional Service',
  iKnow: 'I Understand',
  cancelReturnBag: 'Cancel Return and Exchange',
  isCancelApply: 'Are you sure you want to cancel the application?',
  cancelBackTax: 'Cancel Make Up Payment',
  productAttr: 'Product Attributes',
  backTaxType: 'Make Up Payment Type',
  taxPrice: 'Make Up Payment Price',
  productBackTax: 'Product Make Up Payment',
  freightBackTax: 'Freight Make Up Payment',
  productTaxPrice: 'Product Make Up Payment Price',
  freightTaxPrice: 'Freight Make Up Payment Price',
  totalTaxPrice: 'Total Make Up Payment Price',
  productStatus: 'Product Status',
  isNeedTax: 'Need Make Up Payment',
  yes: 'Yes',
  no: 'No',
  iThinkAbout: "I'll Think About It",
  revokeCheckedProduct: 'Revoke Checked Product',
  taxTips1:
    'Please [Make Up Payment] or [Cancel] as soon as possible so as not to affect the purchase of other products in the order.',
  cancelTaxTips1:
    'If you are unwilling to make up the payment, we will not be able to purchase the product that requires a make-up payment.',
  cancelTaxTips2:
    'The marked products will be canceled after revocation, and the product cost will be refunded to your account.',
  cancelTaxTips3:
    'The unmarked products will continue to be kept and we will continue to purchase for you.',
  cancelReason: {
    0: "I don't want to buy it",
    1: 'Information filled in incorrectly',
    2: 'Seller out of stock',
    3: 'Unable to pay',
    4: 'Other reasons'
  },
  hint: 'Hint',
  orderStatusEnum: {
    0: 'Pending Payment',
    1: 'Unprocessed',
    2: 'Under Review',
    3: 'Order Transferred',
    4: 'Order Canceled',
    5: 'Freight Make Up Payment',
    6: 'Risk Control Review',
    7: 'Order Revoked',
    8: 'Payment in Progress',
    9: 'Completed'
  },
  returnGood: {
    name: 'Return/ Exchange',
    selectType: 'Select Type',
    returnGood: 'Return',
    returnDayTips: 'Usually takes 3-7 days',
    changeGood: 'Exchange',
    changeDayTips: 'Usually takes 5-10 days',
    returnReasonEnum: {
      0: "Bought Wrong/Don't Like",
      1: 'Seller Sent Wrong Product?',
      2: 'Product Quality Issue',
      3: 'Not Shipped Within Agreed Time',
      5: 'Other Reasons'
    },
    returnReasonSelect: 'Select Return Reason',
    returnExplain: 'Return Instructions',
    residueReturnNumTips: 'Free service return rights remaining this month',
    returnTips:
      'Final verification of return due to seller or platform, acbuy will refund the shipping fee you bear.',
    changeTips:
      'For returns or exchanges due to seller reasons, you may still need to bear part of the exchange shipping fee.',
    returnTip4: 'Friendly Reminder',
    returnTip5:
      'The return and exchange service will set a number of free operation service times. (Each user can apply for return or exchange of goods without operation service charge for the first time each month, while the shipping fee generated by return or exchange of goods is negotiated between the agent and the seller and collected by acbuy.) acbuy return and exchange service fee: 5 Yuan/order',
    returnTip10:
      'If the return or exchange is due to the responsibility of the purchasing agent, seller, or platform, the system will refund this 5 Yuan operation fee to your acbuy balance account.'
  },
  holder: {
    selectCancelReason: 'Please select the reason for withdrawal',
    inputOtherReason: 'Please enter the reason',
    inputSearchHint: 'Name/Order No./Product No.',
    inputCancelReason: 'Please enter the reason for canceling the order'
  },
  selectProducts: 'Select Products',
  confirmStepOrder: 'Confirm Order',
  PayForProducts: 'Pay for Products',
  sendingPackages: 'Sending Packages',
  submitDelivery: 'Submit for Delivery',
  readAgreeText: 'I have read and agreed',
  disclaimer: 'Disclaimer',
  orderNote:
    'Note: After payment is completed, you would need to submit the parcel for international delivery once the order arrives and gets stored in the warehouse.',
  goPay2: 'Go make up the payment',
  backTax: 'Make up payment',
  reminder: 'Reminder',
  disclaimer1: 'Prohibited Items Description',
  disclaimer2: 'Terms of Service',
  disclaimer3: 'Return Policy',
  customPhotosTips:
    'A photograph provides only one detail requirement for photographing an item',
  backCartEdit: 'Back to cart modification',
  customPhotosReminderLabel: 'Kind reminder',
  customPhotosReminder:
    'A photo only provides one detail requirement for taking a picture of one product',
  packageAgreeTip1:
    '1. Delays, damages, losses, taxes, confiscation, and other uncertainties related to logistics provider services are beyond our control, and we will not be held responsible for any resulting liabilities. However, we will make every effort to remind you to think twice before making a purchase, assist you in resolving any issues with the logistics provider, and compensate you according to the after-sales policy provided by the logistics provider.',
  packageAgreeTip2:
    '2. We recommend that you accurately declare your package. If your package undergoes random inspections during customs clearance in the destination country, you may need to pay some taxes. If you receive a tax notice, please pay taxes accordingly, otherwise you may face consequences such as paying high return fees and package destruction.',
  applyRefund: 'Apply For Refund',
  refundReminder:
    'Tips: After applying for a refund, the buyer will verify the status of the product, and cannot cancel the application for a refund before verifying it.',
  inputApplyReason: 'Please enter the reason for application',
  orderConfirm: 'Confirm Order',
    replyMsg: 'Reply Message',
  replyMsgTips: 'Note information you send, we will notify the buyer for you to verify.'
};
