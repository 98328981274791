import axios from 'axios'
import qs from 'qs'
import { useUserStore } from '@/stores'
import { acceptLangMap, } from '@/config/constant'
import { USER_STORE_KEY } from '@/stores/config'
import { ElMessageBox } from 'element-plus'

// 创建一个 axios 实例
const instance = axios.create({
  timeout: 200000, // 请求超时时间毫秒
  withCredentials: false, // 异步请求携带cookie
  baseURL: '/',
})
let isShowReload = false
//拦截器 请求拦截 
instance.interceptors.request.use(async config => {

  //做点什么----根据后端约定执行相关 这里是判断开发/线上环境 存储添加token
  const localValue = localStorage.getItem(USER_STORE_KEY)
  const userStore = localValue ? JSON.parse(localValue) : {}
  const token = userStore.token
  const lang = userStore.language
  const store = useUserStore()

  if (config.method && config.method.toUpperCase() === 'GET') {
    config.data && (config.url = `${config.url}?${qs.stringify(config.data)}`)
    config.data = null
  }

  //判断是否存在token，根据后端约定在header的authorization都加上token 
  if (token) {
    config.headers.Authorization = `${token}`
    if (!isShowReload && store.loginTimer && store.loginTimer != userStore.loginTimer) {
      if (store.profile.userId === userStore?.profile.userId) return
      isShowReload = true
      const data = require(`@/i18n/langs/${lang}/common.js`).default
      try {
        await ElMessageBox.confirm(data.reloadPageTips, data.tips, {
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          customClass: 'common-confirm',
          confirmButtonText: data.reload
        })
        location.reload()
        await new Promise()
      } catch (error) {
        await new Promise()
      }
    }
  }
  config.headers.lang = userStore.language
  config.headers.Currency = userStore.currency
  config.headers['Accept-Language'] = acceptLangMap[userStore.language]
  //根据后端约定执行相关 结束
  return config;
}, error => {

  return Promise.reject(error);
});

//拦截器 响应拦截
instance.interceptors.response.use(response => {

  //做点什么----根据后端约定状态判断执行 这里是判断状态移除token
  if (response.data.status) {
    //
  } else {
    return response.data
  }
  //根据后端约定状态判断执行 结束

  //可将 return response ↑放在做点什么中↑
  return response.data
}, error => {
  if (error.response.status === 401) {
    const userStore = useUserStore()
    userStore.logout()
    if (location.search.includes('redirectUrl')) return
    const redirectUrl = encodeURIComponent(`${location.pathname}${location.search}`)
    location.href = `/login?redirectUrl=${redirectUrl}`

    return
  }

  return error
});

export default instance