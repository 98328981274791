export default {
  bankTransfer: 'Bank Transfer',
  firstTitle: 'Please Select Remittance Currency',
  secondTitle: 'After Transfer',
  bankTips:
    'Get the information of the beneficiary and go to the corresponding bank workstation to fill out the money order. Please check your spelling carefully.',
  receiverTitle: 'Information of the Beneficiary',
  bankAttrEnum: {
    0: 'Account Number',
    1: 'Routing Number',
    2: 'Account Name',
    3: 'Bank Name',
    4: 'Bank Address',
    5: 'Post Code',
    6: 'Country/Region',
    7: 'Type of Account',
    8: 'Remark'
  },
  transferTips:
    'You can inform us in any of the following ways, and we will check it ASAP!',
  transferName: 'Remitter Name',
  transferBank: 'Remitter Bank',
  transferNo: 'Reference No.',
  submit: 'Submit now',
  successSubmit: 'Successful Submission',
  successSubmitTips:
    '* If any problem occurs during verification, we will contact your acbuy registration email.',
  tradeDetail: 'Transaction Detail',
  backOrder: 'Go back to Order',
  holder: {
    inputNo: 'Please provide the reference number if available.'
  },
  transferExplain: 'Bank Transfer Instructions:',
  transferExplainEnum: {
    0: 'acbuy does not charge handling fees for this payment channel. Whether the remitting bank charges fees depends on the relevant policies of the corresponding bank.',
    1: 'This payment method supports multiple currencies. Customers can choose the corresponding bank account for remittance according to your region and needs.',
    2: 'Normally, it takes 1-3 working days to arrive; under special circumstances, it takes 1-5 working days to arrive.',
    3: "acbuy will convert the amount into RMB at acbuy's exchange rate when the remittance is received and then credit it to your account.",
    4: 'The final amount of RMB in acbuy account = The amount of your remittance * acbuy platform exchange rate.'
  }
};
