export default {
  selectSize: 'Please select the full specification',
  inventory: 'Inventory',
  num: 'Quantity',
  nimNumTips: 'Minimum Purchase Quantity: {num}',
  addCart: 'Add To Cart',
  nowBuy: 'Buy Now',
  remark: 'Leaving A Message',
  goShop: 'ENTER SHOP',
  productTotalPrice: 'The total price of product',
  productDetail: 'Product Details',
  sizeParams: 'Specification Parameters',
  shopNotes: 'Shopping Agent Notes',
  saleService: 'After Sales Service',
  productInfo: 'Product Information',
  buyTips:
    'Orders Paid Between 09:00-18:00 (Beijing Time) Will Be Processed In 6 Hours (Except Sunday).',
  buyer: 'Seller',
  selfWarehouse: 'acbuy Warehouse',
  warehouse: 'acbuy Warehouse',
  yourAddress: 'Your Address',
  calcCountryFreight: 'Estimate International Shipping Fee',
  holder: {
    remarkHolder:
      'If you have any other needs, please leave a message in the remarks, agent will assist you with the inquiry.'
  },
  supportPayment: 'Available Payment Methods',
  disclaimer:
    'All Products Available For Shopping Agent Service Displayed On acbuy Are From Third-Party Shopping Platforms And Not Directly Sold By acbuy. Thus, acbuy Does Not Take Liability And Legal Responsibility For Issues Caused By Intellectual Property Rights Infringement And Goods Copyright Infringement.',
  readAgree: 'I Have Read And Agreed Above',
  selectAgree: 'Not agreed disclaimer',
  copyLink: 'Copy Link',
  copySuccess: 'Copy Success',
  disclaimer1: 'Disclaimer',
  editPrice: 'Edit Price',
  editPriceTips:
    'Please note that there is a problem with coupon redemption. We recommend the user to change the selling price of the item to the original price.',
  editPriceEnum: {
    0: 'Shipping discount',
    1: 'Web price error',
    2: 'Negotiated with seller',
    3: 'Other reason'
  }
};
