export default {
  fillInOrder: "Remplir la Commande d'Agent d'Achat",
  inputTips:
    "(les colonnes marquées d'un <span style='color: red'>*</span> sont obligatoires)",
  process: {
    first: 'Remplir la commande et soumettre',
    second: 'Payer le produit et les frais de livraison nationale',
    three: "Attendre que votre produit arrive à l'entrepôt"
  },
  productInfo: 'Informations sur le Produit',
  productInfoTips:
    "Tous les produits disponibles pour le service d'agent d'achat affichés sur acbuy sont des produits récupérés auprès de plateformes d'achat tierces et ne sont pas directement vendus par acbuy. Ainsi, en cas de problème de contrefaçon impliquant lesdits produits, toutes les responsabilités encourues seront supportées par les vendeurs correspondants sur la plateforme tierce, tandis qu'acbuy n'assumera aucune responsabilité pertinente, collatérale ou conjointe.",
  fees: 'Frais',
  feeStandard: 'Normes de Frais',
  feeStandardTips:
    "Pour la commande d'agent d'achat que vous soumettez sur cette page, si le produit provient de plateformes tierces comme Kongfuzi Used Books, Xiaohongshu et Youzan, etc., il est possible que le produit ne puisse pas être acheté. Le produit est associé à des risques d'expédition, ou des frais de service supplémentaires pourraient être facturés. La situation réelle dépend du retour de notre personnel. Pour plus de détails, veuillez consulter 'Centre d'Aide - Normes de Frais de Service d'Agent d'Achat Tiers'.",
  link: 'Lien',
  name: 'Nom',
  remark: 'Remarque',
  unitPrice: 'Prix Unitaire (CNY)',
  qty: 'Qté',
  goodsAttr: 'Spécification des marchandises',
  chinaPostage: "Frais d'expédition (CNY)",
  holder: {
    inputUrl: "Veuillez coller l'URL du produit",
    inputName:
      'Veuillez entrer le nom du produit ici (Dans la limite de 100 caractères)',
    inputAttr: 'Veuillez saisir la spécification des marchandises',
    inputRemark:
      "Si l'article a plusieurs motifs, tailles, couleurs, etc., veuillez spécifier ce dont vous avez besoin ici en fonction de la description de classification sur la page du produit. Si vous achetez le même produit mais avec des spécifications différentes, veuillez séparer la commande."
  },
  other: 'Autre (facultatif)',
  productImg: 'Images du Produit',
  fareTip:
    "Remplissez les frais d'expédition facturés par le site à l'entrepôt sélectionné."
};
