export default {
  goodsStatusEnum: {
    '-1': '待支付',
    0: '待采购',
    1: '待确认',
    2: '等待补款',
    3: '商品退货',
    4: '商品换货',
    5: '已发货',
    6: '分开发货',
    7: '延期处理',
    8: '已签收',
    9: '已到货',
    10: '已入库',
    11: '作废订单',
    12: '销毁商品',
    13: '超期订单',
    14: '信息确认',
    15: '购买确认',
    16: '退货等待中',
    17: '换货等待中',
    18: '退货处理中',
    19: '换货处理中',
    20: '退货完成',
    21: '换货完成',
    22: '已订购',
    23: '处理中',
    24: '取消订购',
    25: '异常订单',
    26: '退货等待退款',
    27: '换货等待卖家发货',
    28: '出库中',
    29: '出库打包完毕',
    30: '寄送海外',
    31: '已收到货',
    32: '拒付订单',
    33: '风控审核',
    34: '撤单列表',
    35: '冻结列表',
    36: '预定补款',
    37: '等待出库',
    38: '退款冻结',
    39: '拒签商品',
    40: '拒签完成',
    41: '异常未入库',
    42: '异常已经入库',
    43: '退货取消',
    44: '换货取消',
    45: '已签收待处理',
    46: '巴拿马待生成',
    47: '巴拿马待支付',
    48: '支付完成',
    49: '放弃商品',
    50: '强制完成',
    51: '退货等待卖家同意',
    52: '换货等待卖家同意',
    53: '退包作废',
    54: '1688待生成',
    55: '1688待支付',
    56: 'yino待生成',
    57: 'yino待支付',
    58: '商品转移中'
  },
  appendStatusEnum: {
    0: '待处理',
    1: '处理中',
    2: '已取消',
    3: '已完成',
    4: '已退单'
  }
};
