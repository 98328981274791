export default {
  agreed: '已同意',
  searchStatement: '搜索服务条款',
  noData: '没有更多数据',
  starts: '星',
  searching: '正在搜索',
  searchService: '搜索服务条款',
  filter: {
    performanceQuality: '履约质量',
    comprehensiveExperienceScore: '综合体验分',
    fourStartDown: '4星以下',
    certifiedFactory: '认证工厂',
    dayYield: '24小时揽收率',
    doubleDayYield: '48小时揽收率',
    performanceGuarantee: '履约保障',
    dayShipments: '当日发货',
    strictSelect: '严选',
    disStrictSelect: '分销严选',
    dayShip: '24小时发货',
    doubleDayShip: '48小时发货',
    sevenDayNoReason: '7天无理由',
    oneIssuing: '支持一件代发',
    onePostIssuing: '支持包邮（一件代发包邮）',
    sevenDayNew: '7天上新',
    thirtyDayNew: '30天上新',
    wordStrictSelect: '全球严选',
    isSelect: '跨境select货盘',
    priceSort: '价格排序',
    compositeSort: '综合排序',
    up: '收起',
    reset: '重置',
    moreFilters: '更多筛选条件',
    bestMatch: '综合匹配',
    saleHighToLow: '销售由高到低',
    saleLowToHigh: '销售由低到高',
    priceHighToLow: '价格由高到低',
    priceLowToHigh: '价格由低到高',
    fastToSlow: '运送时间: 由快到慢',
    slowToFast: '运送时间: 由慢到快'
  }
};
