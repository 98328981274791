export default {
  lang: 'Idioma',
  currency: 'Moneda',
  validGoodUrlFail: 'Este no es un enlace válido del producto',
  product: 'Producto',
  productHolder: 'Ingrese el nombre/enlace del producto',
  shop: 'Tienda',
  shopHolder: 'Ingrese el nombre/enlace de la tienda',
  purchaseAgent: 'Agente de compras',
  transport: 'Transporte',
  manualOrdering: 'Pedido manual',
  guideBook: 'Guía',
  help: 'Ayuda',
  tools: 'Herramientas',
  customerService: 'Servicio al cliente',
  purchaseGuide: 'Guía de compra',
  payment: 'Pago',
  delivery: 'Entrega',
  afterSaleService: 'Servicio postventa',
  insuranceCompensation: 'Seguro y compensación',
  contactInfo: 'Información de contacto',
  enterEmail: 'Ingrese su correo electrónico',
  copyRight: 'Copyright©acbuy.cn',
  aboutacbuy: 'Acerca de acbuy',
  contctTime: '9/7 (09:00-18:00, hora de Pekín)',
  contactEmail: "Servicio al cliente: Support{'@'}acbuy.com",
  contactMarket: "Marketing: Marketing{'@'}acbuy.com",
  contactCooperation: "Cooperación comercial: Business{'@'}acbuy.com",
  tutorial: 'Tutorial',
  serviceFee: 'Tarifa de servicio',
  orderStatus: 'Guía para novatos',
  returnRefunds: 'Devoluciones y reembolsos',
  topUp: 'Recargar',
  paypal: 'PayPal',
  internationalCreditCard: 'Tarjeta de crédito internacional',
  telegraphicTransfer: 'Transferencia bancaria',
  charges: 'Cargos',
  mailRestrictions: 'Restricciones de envío',
  customsTaxation: 'Aduanas e impuestos',
  receiptInformation: 'Información de recibo',
  helpCenter: 'Centro de ayuda',
  contactUs: 'Contáctenos',
  registerSuccess: 'Registro exitoso',
  registerMsg:
    'Hemos enviado un correo de verificación a su dirección. Complete la verificación rápidamente para no afectar el uso de sus puntos.',
  continueShopping: 'Continuar comprando',
  continueLogin: 'Continuar inicio de sesión',
  verifyQuestion: '¿No recibió el correo de verificación?',
  verifyResopnseOne:
    '· El correo de verificación de acbuy puede ser considerado spam, revise su carpeta de spam',
  verifyResopnseTwo:
    '· El envío puede retrasarse unos minutos, por favor sea paciente',
  verifyResopnseThird:
    '· El correo de verificación puede retrasarse. Si no lo recibe después de mucho tiempo, verifique si fue marcado como spam o reenvíe el correo de verificación',
  agentGuide: 'Guía para compradores nuevos',
  guideBookbtn: 'Manual para principiantes',
  everyonePro:
    'Todos pueden ser promotores, las comisiones por paquetes dobles han sido mejoradas',
  joinAffiliate: 'Unirse al programa de afiliados',
  joinacbuy: 'Únase a acbuy Discord para más beneficios',
  joinDiscord: 'Unirse a Discord',
  productPpurchase: 'Compra de productos',
  shippingToWarehouse: 'Envío al almacén',
  qualityAssurance: 'Garantía de calidad',
  moreCostEffective: 'Más rentable',
  productPpurchaseContent:
    'Compra de productos - Puede comprar fácilmente cualquier producto de China. Simplemente pegue el enlace del producto en el sitio acbuy y realice el pedido, acbuy comprará por usted',
  shippingToWarehouseContent:
    'Envío al almacén – Compre de diferentes vendedores a través de nuestro servicio, consolidaremos eficientemente sus pedidos. Todos los productos se enviarán al almacén acbuy, simplificaremos el proceso para su conveniencia.',
  qualityAssuranceContent:
    'Garantía de calidad - Cuando sus productos llegan al almacén acbuy, realizamos una inspección completa de calidad y le enviamos los comentarios de la inspección para una mejor experiencia',
  moreCostEffectiveContent:
    'Ahorre en envío consolidado - En su almacén, puede seleccionar libremente productos y enviarlos en un solo paquete. Use el servicio de envío mundial acbuy para enviar sus productos a cualquier lugar, disfrute de un envío mundial más simple y confiable.',
  introduction: 'Comprar como un millonario',
  reviewOn: 'Comentarios en',
  productStoragePeriod: 'Período de almacenamiento del producto',
  inspectionRules: 'Reglas de Inspección',
  afterSalesPolicy: 'Política post - venta',
  insuranceAndCompensation: 'Seguros e indemnizaciones',
  oneStopService: 'Servicio de ventanilla única',
  whatCustomersSay: '¿¿ qué dicen nuestros clientes?',
  customerSay1:
    'acbuy es el mejor agente de compras que he usado - Esta es la primera vez que uso acbuy, sus productos son más baratos que cualquier otro agente de compras, ¡ esto es genial!',
  customerSay2:
    'Después de convertirme en un promotor de acbuy, recibí un reembolso en efectivo de 300 yuanes. Después de eso, obtendré no menos del 4% de devolución en efectivo por pedido.',
  customerSay3:
    'Una experiencia de compra muy agradable - uso acbuy para comprar de china, la calidad del producto es buena, el servicio al cliente es bueno y la entrega es rápida.',
  customerSay4:
    'El mejor agente - la compra se completa el mismo día. Solo tomó 7 días entregar el paquete a mi casa. Este es el mejor agente en este momento.',
  customerFirst: 'Cliente primero, servicio de calidad',
  customerServiceText:
    'Servicio al cliente en línea 9 / 7 (09: 00 - 18: 00, hora bj)',
  faqCategory: 'Categoría de preguntas frecuentes'
};
