import cn from './cn';
import en from './en';
import fr from './fr';
import es from './es';
import pt from './pt';

export default {
  cn,
  en,
  fr,
  es,
  pt
};
