export default {
  bankTransfer: 'Transferência bancária',
  firstTitle: 'Por favor, selecione a moeda da remessa',
  secondTitle: 'Após a transferência',
  bankTips:
    'Obtenha as informações do beneficiário e vá à estação bancária correspondente para preencher a ordem de pagamento. Por favor, verifique cuidadosamente a sua ortografia.',
  receiverTitle: 'Informações do beneficiário',
  bankAttrEnum: {
    0: 'Número da conta',
    1: 'Número de roteamento',
    2: 'Nome da conta',
    3: 'Nome do banco',
    4: 'Endereço do banco',
    5: 'Código postal',
    6: 'País/Região',
    7: 'Tipo de conta',
    8: 'Observação'
  },
  transferTips:
    'Você pode nos informar de qualquer uma das seguintes maneiras, e verificaremos o mais rápido possível!',
  transferName: 'Nome do remetente',
  transferBank: 'Banco remetente',
  transferNo: 'Número de referência',
  submit: 'Enviar agora',
  successSubmit: 'Envio bem-sucedido',
  successSubmitTips:
    '* Se ocorrer algum problema durante a verificação, entraremos em contato com o seu e-mail de registro acbuy.',
  tradeDetail: 'Detalhe da transação',
  backOrder: 'Voltar ao pedido',
  holder: {
    inputNo: 'Por favor, forneça o número de referência, se disponível.'
  },
  transferExplain: 'Instruções de transferência bancária:',
  transferExplainEnum: {
    0: 'A acbuy não cobra taxas de manuseio para este canal de pagamento. Se o banco remetente cobra taxas depende das políticas relevantes do banco correspondente.',
    1: 'Este método de pagamento suporta múltiplas moedas. Os clientes podem escolher a conta bancária correspondente para remessa de acordo com sua região e necessidades.',
    2: 'Normalmente, leva de 1 a 3 dias úteis para chegar; em circunstâncias especiais, pode levar de 1 a 5 dias úteis.',
    3: 'A acbuy converterá o valor em RMB à taxa de câmbio da acbuy quando a remessa for recebida e então creditará em sua conta.',
    4: 'O valor final em RMB na conta acbuy = O valor da sua remessa * Taxa de câmbio da plataforma acbuy.'
  }
};
