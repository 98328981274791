const DistributionLayout = () => import(/* webpackChunkName: "distribution" */ '@/pages/distribution/Layout/index.vue');
const Dashboard = () => import(/* webpackChunkName: "distribution-dashboard" */ '@/pages/distribution/dashboard/index.vue');
const Invite = () => import( /* webpackChunkName: "distribution-invite" */ '@/pages/distribution/invite/index.vue');
const Bonus = () =>import( /* webpackChunkName: "distribution-bonus" */ '@/pages/distribution/bonus/index.vue');
const WithdrawRecord = () =>import(/* webpackChunkName: "withdraw-record" */ '@/pages/distribution/withdrawRecord/index.vue');
const Team = () => import(/* webpackChunkName: "team" */ '@/pages/distribution/team/index.vue');
const Profile = () =>import( /* webpackChunkName: "profile" */ '@/pages/distribution/profile/index.vue');
const WithdrawMethod = () =>import(  /* webpackChunkName: "withdraw-method" */ '@/pages/distribution/withdrawMethod/index.vue');

export default [
  {
    path: '/distribution',
    name: 'distribution',
    redirect: '/distribution/dashboard',
    component: DistributionLayout,
    children: [
      {
        path: '/distribution/dashboard',
        name: 'distribution-dashboard',
        meta: { title: 'dashboard', icon: 'dashbord' },
        component: Dashboard
      },
      {
        path: '/distribution/invite',
        name: 'distribution-invite',
        meta: { title: 'userDetail', icon: 'invite' },
        children: [
          {
            name: 'user-detail', path: '/distribution/user-detail',
            component: Invite,
            meta: { title: 'userDetail' }
          },
          {
            name: 'team-info', path: '/distribution/team-info',
            component: Team,
            meta: { title: 'teamInfo' }
          },

        ]
      },
      {
        path: '/distribution/bonus',
        name: 'distribution-bonus',
        meta: { title: 'bonus', icon: 'bouns' },
        children: [
          {
            name: 'bonus-detail',
            path: '/distribution/bonus-detail',
            meta: { title: 'bonusDetail' },
            component: Bonus
          },
          {
            name: 'withdraw-record',
            path: '/distribution/withdraw-record',
            meta: { title: 'withdrawRecord' },
            component: WithdrawRecord
          }
        ]
      },
      // {
      //   path: '/distribution/team',
      //   name: 'distribution-team',
      //   meta: { title: 'team', icon: 'team' },
      //   children: [
      //     {
      //       name: 'team-info',
      //       path: '/distribution/team-info',
      //       meta: { title: 'teamInfo' },
      //       component: Team
      //     },
      //     {
      //       name: 'team-member',
      //       path: '/distribution/team-member',
      //       meta: { title: 'teamMember' },
      //       component: TeamMember
      //     }
      //   ]
      // },
      {
        path: '/distribution/setting',
        name: 'distribution-setting',
        meta: { title: 'setting', icon: 'account' },
        children: [
          {
            name: 'setting-profile',
            path: '/distribution/profile',
            meta: { title: 'profile' },
            component: Profile
          },
          {
            name: 'withdraw-method',
            path: '/distribution/withdraw-method',
            meta: { title: 'withdrawMethod' },
            component: WithdrawMethod
          }
        ]
      }
    ]
  }
];
