export default {
  tipsOne:
    '1. Nous garderons gratuitement pour vous pendant 90 jours, plus de 90 jours ne soumettez toujours pas le border-route de colis ou ne pas faire aucun retour sera considéré comme renonciation des marchandises, nous allons unifier les marchandises pour faire le traitement de destruction.',
  tipsTwo:
    "2. Les marchandises ont été placées dans l'entrepôt, vous pouvez entrer dans mon entrepôt à tout moment et les soumettre à votre adresse de livraison à l'étranger",
  orderDetail: 'Détail de la commande',
  orderNo: 'Numéro de commande',
  orderConsult: 'Consultation de commande',
  consult: 'Enquête',
  orderConsultTips:
    "L'agent d'achat ne peut traiter que les affaires liées à cette commande. Pour d'autres demandes commerciales, veuillez contacter",
  enquiryReplyMsg:
    'Entrez ici, nous vous répondrons dans les 24 heures. (Note : Parfois, nous utiliserons un logiciel de traduction pour répondre, ce qui peut ne pas transmettre précisément le sens, nous espérons votre compréhension)',
  stepListEnum: {
    '-1': 'Sélectionner les articles',
    0: 'Soumettre le colis',
    6: "Payer les frais d'expédition",
    9: 'Envoyer le colis',
    20: 'Confirmer la réception'
  }
};
