import form from './fr/form';
import home from './fr/home';
import search from './fr/search';
import cart from './fr/cart';
import product from './fr/product';
import order from './fr/order';
import pecenter from './fr/pecenter';
import pay from './fr/pay';
import common from './fr/common';
import estimation from './fr/estimation';
import packages from './fr/package';
import orderDetail from './fr/orderDetail';
import warnTips from './fr/warnTips';
import status from './fr/status';
import tips from './fr/tips';
import storage from './fr/storage';
import uniOrder from './fr/uniOrder'
import bankTransfer from './fr/bankTransfer'
import distribution from './fr/distribution'
import site from './fr/site'
import consult from './fr/consult'

export default {
  form,
  home,
  search,
  cart,
  product,
  order,
  pecenter,
  pay,
  common,
  estimation,
  packages,
  orderDetail,
  warnTips,
  status,
  tips,
  storage,
  uniOrder,
  bankTransfer,
  distribution,
  site,
  consult
};
