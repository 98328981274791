export default {
  agreed: 'Accordé',
  searchStatement: 'Conditions générales de service de recherche',
  noData: 'Aucune autre donnée',
  starts: 'Étoile',
  searching: 'Recherche',
  searchService: 'Conditions générales de service de recherche',
  filter: {
    performanceQuality: "Qualité d'exécution",
    comprehensiveExperienceScore: 'Points d’expérience complets',
    fourStartDown: 'Moins de 4 étoiles',
    certifiedFactory: 'Usine certifiée',
    dayYield: 'Taux de prise en charge dans 24 heures',
    doubleDayYield: 'Taux de prise en charge dans 48 heures',
    performanceGuarantee: 'Garantie de performance',
    dayShipments: 'Envoi le même jour',
    strictSelect: 'Sélection stricte',
    disStrictSelect: 'Sélection de distribution stricte',
    dayShip: 'Livraison en 24 heures',
    doubleDayShip: 'Livraison en 48 heures',
    sevenDayNoReason: 'Garantie 7 jours sans raisons',
    oneIssuing: "Soutien à la livraison à l'unité",
    onePostIssuing:
      "Soutien à la livraison gratuite (livraison à l'unité gratuite)",
    sevenDayNew: 'Nouveautés dans 7 jours',
    thirtyDayNew: 'Nouveauté dans 30 jours',
    wordStrictSelect: 'Sélection stricte mondiale',
    isSelect: 'Palette de sélection transfrontalière',
    priceSort: 'Tri par prix',
    compositeSort: 'Tri par pertinence',
    up: 'Replier',
    reset: 'Réinitialiser',
    moreFilters: 'Plus de filtres',
    bestMatch: 'Meilleure correspondance',
    saleHighToLow: 'Ventes : de haut en bas',
    saleLowToHigh: 'Ventes : de bas en haut',
    priceHighToLow: 'Prix : de haut en bas',
    priceLowToHigh: 'Prix : de bas en haut',
    fastToSlow: "Temps d'expédition: rapide à lent",
    slowToFast: "Temps d'expédition: de lent à rapide"
  }
};
