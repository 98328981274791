import { defineStore } from 'pinia';

export default defineStore('orderStore', {
  state: () => {
    return {
      orderShopList: [], // 等待下单的列表
      uniOrderData: {}, // 手动下单缓存数据
    };
  },
  actions: {
    // 保存准备下单的数据
    async saveShopProductList(list) {
      this.orderShopList = list.map(item => {
        let img = '';
        switch (item.storeSource) {
          case 'alibaba':
            img = require('@/assets/images/common/1688.png');
            break;
          case 'weidian':
            img = require('@/assets/images/common/weidian.png');
            break;
          case 'taobao':
            img = require('@/assets/images/common/taobao.png');
            break;
          default:
            break;
        }
        return { ...item, shopImg: img };
      });
    },
    // 保存手动下单数据
    saveUniOrderData (data) {
      this.uniOrderData = data 
    }
  },
  persist: {
    key: 'orderStore',
    storage: sessionStorage,
    paths: ['orderShopList', 'uniOrderData']
  }
});
