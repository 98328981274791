export default {
  type: '类型',
  relevanceNo: '关联单号',
  consultContent: '咨询内容',
  submitTime: '提交时间',
  consultTypeEnum: {
    0: '订单咨询',
    1: '包裹咨询'
  },
  consultStatusEnum: {
    0: '未处理',
    1: '处理中',
    2: '已完成'
  }
}