export default {
  payChannel: '支付渠道',
  cashierDesk: '收银台',
  billAddress: '账单地址',
  paySuccess: '支付成功',
  payError: '支付失败',
  harvestAddress: '收货地址',
  orderAddress: '账单地址',
  selectAddress: '选择收货地址',
  addOrderAddress: '请先添加账单地址',
  addSelectAddress: '请选择收货地址',
  noAddress: '暂无收货地址',
  holder: {
    inputPayPwd: '请输入支付密码'
  },
  transferTitle: '银行转账汇款',
  changeAddress: '更换地址',
  editText: '编辑',
  payWithbalance: '用余额支付',
  bankTransferRemittance: '银行转账汇款',
  transferTips: 'acbuy汇款0服务费，订单金额越高，省得越多！',
  pay: '优惠充值',
  plsSetPsd: '请设置密码',
  thirdPayTips: '支付全球覆盖和多种货币'
};
