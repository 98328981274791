export default {
  orderStatus: {
    0: '您提交了订单，请等待采购员接单',
    23: '采购已接单，正在和商家沟通',
    22: '您的商品已经订购，交易单号：',
    5: '商家已经发货，发货方式：',
    10: '您的商品已经到达acbuy仓库',
    16: '您提交了退货，请等待采购员处理',
    17: '您提交了换货，请等待采购员处理',
    20: '该订单已经完成退货，',
    beenReturned: ' 查看退款 >',
    30: '您的商品已经寄送，查看所在包裹：',
    43: '您的退货申请取消',
    44: '您的换货申请取消'
  },
  expressNo: '物流单号：'
};
