import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores';
import MemberRoutes from './member.js';
import DistributionRoutes from './distribution';

const Layout = () => import(/* webpackChunkName: "layout" */ '@/pages/Layout');
const Home = () => import(/* webpackChunkName: "home" */ '@/pages/home'); // 首页
const SearchList = () => import(/* webpackChunkName: "search-list" */ '@/pages/searchList'); // 搜索列表页
const ShopDetail = () => import(/* webpackChunkName: "shop-detail" */ '@/pages/shopDetail'); // 商铺详情
const Product = () => import(/* webpackChunkName: "product" */ '@/pages/product/index'); // 商品详情

const Order = () => import(/* webpackChunkName: "order" */ '@/pages/order/index.vue'); // 订单

const PayCenter = () => import(/* webpackChunkName: "pay-center" */ '@/pages/paycenter/index.vue'); // 支付中心

const Estimation = () => import(/* webpackChunkName: "estimation" */ '@/pages/estimation/index.vue'); // 运费估算
const Carrier = () => import(/* webpackChunkName: "carrier" */ '@/pages/estimation/Carrier.vue'); // 承运商估算

const ActiveMail = () => import(/* webpackChunkName: "active-mail" */ '@/pages/activeMail'); // 激活邮箱
const Forget = () => import(/* webpackChunkName: "forget" */ '@/pages/forget'); // 重置密码
const SubmitPackage = () => import(/* webpackChunkName: "submit-package" */ '@/pages/submitPackage'); // 提交包裹
const UniOrder = () => import(/* webpackChunkName: "uni-order" */ '@/pages/uniOrder'); // 手动下单

const Login = () => import(/* webpackChunkName: "login" */ '@/pages/login/index');

const RegisterSuccess = () => import(/* webpackChunkName: "registerSuccess" */ '@/pages/registerSuccess/index') // 注册成功

const Test = () => import('@/pages/test/index');
const Faq = () => import(/* webpackChunkName: "faq" */ '@/pages/Faq/index'); // Faq
const PaymentSuccess = () => import( /* webpackChunkName: "payment-success" */ '@/pages/member/cart/paymentSuccess') // 支付成功
const OnerwaySuccess = () => import( /* webpackChunkName: "payment-success" */ '@/pages/member/cart/paymentSuccess') // onerway支付成功
const HelpCenter = () => import('@/pages/helpCenter/index'); // 帮助中心

// 路由规则
const routes = [
  // 一级路由布局容器
  ...DistributionRoutes,
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      ...MemberRoutes,
      {
        path: '/member/cart/paymentSuccess',
        name: 'payment-success',
        component: PaymentSuccess
      },
      {
        path: '/onerwaySuccess',
        name: 'onerway-success',
        component: OnerwaySuccess
      },

      { path: '/search-list', name: 'search-list', component: SearchList },
      { path: '/shop-detail', name: 'shop-detail', component: ShopDetail },
      {
        path: '/product',
        name: 'product',
        component: Product,
        meta: {
          keepAlive: true
        }
      },
      { path: '/estimation', name: 'estimation', component: Estimation },
      { path: '/estimation/carrier', name: 'carrier', component: Carrier },

      { path: '/uni-order', name: 'uni-order', component: UniOrder },
      { path: '/test', name: 'test', component: Test },
      {
        path: '/paycenter',
        name: 'paycenter',
        component: PayCenter
      },
      { path: '/order', name: 'order', component: Order },

      {
        path: '/submit-package',
        name: 'submit-package',
        component: SubmitPackage
      },
      {
        path: '/doc',
        name: 'faq',
        component: Faq
      },
      { path: '/help-center', name: 'helpCenter', component: HelpCenter }
    ]
  },
  { path: '/home', name: 'home', component: Home },
  { path: '/activeMail', name: 'active-mail', component: ActiveMail },
  { path: '/forget', name: 'forget', component: Forget },
  { path: '/login', name: 'login', component: Login },
  {
    path: '/registerSuccess',
    name: 'register-success',
    component: RegisterSuccess
  },
  {
    path: '/:pathMatch(.*)', 
    redirect: '/home', 
    meta: { hidden: true } 
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 };
  }
});

// 前置导航守卫
router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  const isLogin = userStore.isLogin;
  if (isLogin) {
    if (to.path === '/login') {
      next('/');
      return;
    }
  }
  document.title = to.name || 'acbuy';
  next();
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
    // router.replace(router.history.pending.fullPath);
  } else {
    console.log(error)
  }
});


export default router;
