export default {
  orderStatus: {
    0: 'Você enviou o pedido, aguarde o comprador aceitar o pedido',
    23: 'O comprador aceitou o pedido, agora estamos em contato com o vendedor',
    22: 'Seu produto foi encomendado, número da transação: ',
    5: 'O vendedor enviou o produto, método de envio: ',
    10: 'Seu produto chegou ao armazém acbuy',
    16: 'Você solicitou um retorno, aguarde o comprador processar',
    17: 'Você solicitou uma troca, aguarde o comprador processar',
    20: 'Este pedido de retorno foi concluído,',
    beenReturned: ' Ver reembolso >',
    30: 'Seu produto foi enviado, ver o pacote em que está: ',
    43: 'Seu pedido de devolução foi cancelado',
    44: 'Seu pedido de troca foi cancelado'
  },
  expressNo: 'Número da guia de remessa: '
};
