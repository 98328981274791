import request from '@/utils/request';
import { orderPrefix, payPrefix } from './config';

// 获取支付渠道列表
export const getPayChannelList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/channelList`, { data: params });
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 统一下单
export const createUnifyOrder = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${orderPrefix}/unifyOrder`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 创建待支付订单
export const createUnPaidOrder = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/payment/order`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 合并支付
export const mergePay = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${orderPrefix}/orderMergePay`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 提现余额(过期,申请)
export const withdrawBalance = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/withdraw/apply`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 提现余额(过期时间内,没过期)
export const withdrawBalanceExpired = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/withdraw`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取提现列表
export const getWithdrawList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/withdrawAvailable/list`, {
      data: params
    });
    if (res.code === 0 || res.code === 200) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 获取已提现列表
export const getHasWithdrawList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/refund/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 是否设置支付密码
export const checkHavePayPwd = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/wallet/checkPwd`);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取汇率
export const getRate = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/exchangeRate/rate`, {
      data: params
    });
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取汇率
export const getChannelFee = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/payment/getFee`, {
      data: params
    });
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取转汇类型配置
export const getTransferConfig = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/common/transferConfig`, {
      data: params
    });
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 提交转汇单
export const submitTransfer = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/transfer`, params);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取alipay card 回调
export const getAliPayCardCallback = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/alipayPlusCard/return`, {
      data: params
    });
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取alipay card 回调
export const getOnerwayCardCallback = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(
      `${payPrefix}/callback/onerway/returntradeNo`,
      {
        data: params
      }
    );
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取业务类型
export const getBusinessType = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/businessType/getAllList`);
    if (res.code === 0 || res.code === 200) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
