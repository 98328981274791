export default {
  payChannel: 'Medio de pago',
  cashierDesk: 'Cajero',
  billAddress: 'Dirección de facturación',
  paySuccess: 'Pago exitoso',
  payError: 'El pago falló',
  harvestAddress: 'Dirección de entrega',
  orderAddress: 'Dirección de facturación',
  selectAddress: 'Seleccionar dirección de entrega',
  addOrderAddress: 'Por favor, agregue primero la dirección de facturación',
  addSelectAddress: 'Seleccione la dirección de entrega',
  noAddress: 'No hay direcciones de entrega actualmente',
  holder: {
    inputPayPwd: 'Por favor, introduzca la contraseña de pago'
  },
  transferTitle: 'Transferencia bancaria',
  changeAddress: 'Cambiar dirección',
  editText: 'Editar',
  payWithbalance: 'Pagar con saldo de acbuy',
  bankTransferRemittance: 'Transferencia bancaria',
  transferTips:
    '0 tarifa de servicio en acbuy para transferencias. ¡Cuanto mayor sea el monto del pedido, más ahorros!',
  pay: 'Ofertas de recarga',
  plsSetPsd: 'Por favor establezca su contraseña',
  thirdPayTips: 'Los pagos se pueden realizar en varias monedas a nivel mundial'
};
