import {
  getCurrencyUnit,
  changeCurrencyUnit,
  flatRate,
  flatRateOrigin
} from './formatMoney';
import { formatTimeStr, formatTimeStrUSA } from './formatTime';
import { limitInputFormat } from './limitNumInput';

export default function useGlobalProps(app) {
  app.config.globalProperties.$currencyUnit = getCurrencyUnit();
  app.config.globalProperties.$changeCurrencyUnit = changeCurrencyUnit(app);
  app.config.globalProperties.$formatTimeStr = formatTimeStr;
  app.config.globalProperties.$formatTimeStrUSA = formatTimeStrUSA;
  app.config.globalProperties.$flatRate = flatRate;
  app.config.globalProperties.$flatRateOrigin = flatRateOrigin;
  app.config.globalProperties.$limitInputFormat = limitInputFormat;
}
